/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef, useReducer, Fragment } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import './ContentManage.scss'
import {
  Divider,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Snackbar,
  ClickAwayListener,
  MenuItem,
} from '@material-ui/core'
import 'flatpickr/dist/themes/material_green.css'
import {
  pieceDelete,
  duplicateContent,
  searchAllCollection,
  searchMyCollection,
} from '../../shared/services/api/search/search'
import PublisherArea from '../PublisherArea'
import MuiAlert from '@material-ui/lab/Alert'
import { IconButton, Pagination, Tooltip } from '@mui/material'
import ConfirmDelete from './ConfirmDelete'
import ContentManageFilters from './ContentManageFilters'
import { Clear } from '@material-ui/icons'
import { useViewStateContext } from '../../context/viewStateContext'
import { useQueryClient } from '@tanstack/react-query'
import { useGlobalContext } from '../../context/globalContext'
import isObject from 'lodash/isObject'
import { useThrottle } from '../../hooks/useThrottle'
import { contentSuggestions } from '../../shared/services/api/alerts/alerts'

/**
 * ContentManage
 */

const ContentManage = ({}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const location = useLocation()
  const history = useHistory()
  const searchRef = useRef(null)
  const [confirmDelete, setConfirmDelete] = useState(undefined)
  const [isDeleting, setDeleting] = useState(false)
  const [toastMessage, setToastMessage] = useState(
    location?.state?.message ? ['success', location.state.message] : undefined,
  )
  const [isDuplicating, setDuplicating] = useState(false)
  const moduleTypes = [
    { name: 'All', slug: 'all' },
    { name: 'My Content', slug: 'my-content' },
  ]
  const { globalContentResult, setGlobalContentResult } = useViewStateContext()
  const { globalContentFilters, setGlobalContentFilters } = useGlobalContext()
  const queryClient = useQueryClient()
  const [skipCount, setSkipCount] = useState(true)
  const [suggestedOptions, setSuggestedOptions] = useState([])
  const [suggestionOpen, setSuggestionOpen] = useState(false)

  const [_, forceUpdate] = useReducer((x) => x + 1, 0)

  const handleForcingRender = () => {
    forceUpdate()
  }

  const getGlobalContentResult = async (isSuggestion, suggestionKeyword) => {
    try {
      const token = () => localStorage.getItem('access_token')
      setGlobalContentResult({})
      const payload = {
        filters: {
          updated_data_range: globalContentFilters?.dateRange,
          management_content_type: globalContentFilters?.content_type?.map(
            (i) => i?.slug,
          ),
          title_keyword: globalContentFilters?.keyword,
          status: globalContentFilters?.status?.map((i) => i?.slug),
          my_content: globalContentFilters.currentTab === 'my-content',
        },
        sorting: globalContentFilters?.orderBy || {
          field: 'updated_at',
          direction: 'desc',
        },
        pagination: {
          page: globalContentFilters?.page || 1,
          per_page: 40,
        },
      }

      if (isSuggestion === true) {
        payload.keyword = suggestionKeyword
      } else if (globalContentFilters.keyword) {
        payload.keyword = globalContentFilters.keyword
      }
      const response = await searchAllCollection(token(), payload)
      setGlobalContentResult(response)

      return response
    } catch (err) {}
  }

  useEffect(() => {
    window.addEventListener('storage', handleForcingRender)

    return () => {
      window.removeEventListener('storage', handleForcingRender)
    }
  }, [])

  useEffect(() => {
    if (!globalContentResult.list) {
      queryClient.getQueryState({
        queryKey: ['globalContentResult'],
      })

      globalContentFilters.page ? searchResult('page') : searchResult()
    }
  }, [])

  useEffect(() => {
    if (skipCount) setSkipCount(false)

    if (!skipCount || toastMessage || location?.state?.message === '') {
      globalContentFilters.page ? searchResult('page') : searchResult()
    }
  }, [
    globalContentFilters.currentTab,
    globalContentFilters.page,
    globalContentFilters.orderBy,
  ])

  useEffect(() => {
    window.history.replaceState(null, '')
    return () => {}
  }, [])

  const handleDuplicateContent = async (type, id) => {
    setDuplicating(true)
    try {
      const contentType =
        type === 'alert' || type === 'scenario' || type === 'report'
          ? type + 's'
          : 'profiles'
      const response = await duplicateContent(token(), contentType, id)
      response && setDuplicating(false)
      history.push(
        `/app/content-create/${type}/${response.duplicated_content_id}`,
      )
    } catch (err) {}
  }

  const handleChangeTab = (newTab) => {
    const currentFilters = {
      ...globalContentFilters,
      currentTab: newTab,
    }
    delete currentFilters.page
    setGlobalContentFilters(currentFilters)
  }

  const handleDeletePiece = async (piece) => {
    setDeleting(true)
    try {
      const id = piece[0]
      const type = piece[1]
      const response = await pieceDelete(token(), type, id)
      if (response.error) {
        setToastMessage(['error', response.message])
      }

      setToastMessage(['success', response.message])
      setDeleting(false)
      setConfirmDelete(undefined)
      const ignore = searchResult()
    } catch (err) {}
  }

  const searchResult = async () => {
    try {
      if (!globalContentFilters.page) {
        handleFilters('page', '')
      }

      const response = await queryClient.fetchQuery({
        queryKey: ['globalContentResult'],
        queryFn: getGlobalContentResult,
      })

      response?.list?.data?.length === 0 &&
        setToastMessage(['warning', 'No results found'])
    } catch (err) {}
  }

  const handleFilters = async (key, value) => {
    const currentState = { ...globalContentFilters }
    if (key === 'dateRange') {
      currentState[key] = { ...currentState[key], ...value }
    } else if (key === 'orderBy') {
      currentState[key] = JSON.parse(value)
    } else {
      currentState[key] = value
    }
    if (currentState[key]?.length === 0) {
      delete currentState[key]
    }

    setGlobalContentFilters(currentState)
  }

  const getSuggestedSearchResult = async (keyword) => {
    const hasOnlySpaces =
      globalContentFilters?.keyword?.replace(/\s/g, '')?.length === 0
    if (hasOnlySpaces) {
      return false
    }
    if (
      globalContentFilters?.keyword !== keyword &&
      !globalContentFilters?.keyword?.length
    ) {
      setSuggestedOptions([])
      return false
    }
    try {
      const response = await contentSuggestions(token(), { keyword: keyword })
      setSuggestedOptions(response?.data)
    } catch (err) {
      console.log(err)
    }
  }

  const debounceLoadData = useThrottle(getSuggestedSearchResult, 250)

  useEffect(() => {
    if (globalContentFilters?.keyword?.length > 0) {
      debounceLoadData(globalContentFilters?.keyword)
    } else {
      setSuggestedOptions([])
    }
  }, [globalContentFilters?.keyword])

  return (
    <div className='content-manage'>
      <div className='content-manage__filters'>
        {toastMessage && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={3000}
            onClose={() => setToastMessage(undefined)}
            open={toastMessage && toastMessage[0] !== undefined}
          >
            <MuiAlert elevation={6} variant='filled' severity={toastMessage[0]}>
              {toastMessage[1]}
            </MuiAlert>
          </Snackbar>
        )}

        {confirmDelete !== undefined && (
          <ConfirmDelete
            isDeleting={isDeleting}
            confirmDelete={confirmDelete}
            setConfirmDelete={setConfirmDelete}
            handleDeletePiece={handleDeletePiece}
          />
        )}

        <ContentManageFilters
          handleFilters={handleFilters}
          searchResult={searchResult}
        />
      </div>

      <div className='content-manage__content'>
        <div className='keywords'>
          <form
            onSubmit={(event) => {
              event.preventDefault()
              const ignore = searchResult()
            }}
          >
            <ClickAwayListener onClickAway={() => setSuggestionOpen(false)}>
              <TextField
                inputRef={searchRef}
                fullWidth
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                value={globalContentFilters.keyword || ''}
                onChange={(event) => {
                  event.stopPropagation()
                  handleFilters('keyword', event.target.value)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <span
                        className='icon-search'
                        style={{ color: '#fff', fontSize: 36 }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: globalContentFilters.keyword && (
                    <Tooltip title='Clear' placement='left'>
                      <IconButton onClick={() => handleFilters('keyword', '')}>
                        <Clear style={{ color: '#fff' }} />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
                placeholder='Click and type to search all content'
                variant='standard'
                onFocus={() => setSuggestionOpen(true)}
                onBlur={(event) => {
                  if (
                    event?.relatedTarget?.classList?.contains(
                      'MuiListItem-root',
                    )
                  ) {
                    return false
                  }
                  setSuggestionOpen(false)
                }}
              />
            </ClickAwayListener>
          </form>

          {suggestionOpen && suggestedOptions?.length > 0 && (
            <div className='search-autocomplete'>
              {suggestedOptions?.map(({ title, content_type }) => {
                return (
                  <Fragment key={title}>
                    <MenuItem
                      onClick={() => {
                        handleFilters('keyword', `"${title}"`)
                        setTimeout(() => {
                          getGlobalContentResult(true, `"${title}"`)
                        }, 0)
                      }}
                      key={title}
                      style={{ fontSize: 18 }}
                    >
                      {title}
                      <div className='autocomplete-item'>{content_type}</div>
                    </MenuItem>
                  </Fragment>
                )
              })}
            </div>
          )}
        </div>

        <Divider style={{ margin: '0 -30px', backgroundColor: '#0000004D' }} />

        <Tabs
          value={globalContentFilters.currentTab || 'all'}
          indicatorColor='primary'
          onChange={(event, newValue) => handleChangeTab(newValue)}
        >
          {moduleTypes.map((type) => (
            <Tab key={type.name} label={type.name} value={type.slug} />
          ))}
        </Tabs>

        <Divider style={{ margin: '0 -30px', backgroundColor: '#0000004D' }} />

        <PublisherArea
          data={globalContentResult?.list?.data ?? []}
          setConfirmDelete={setConfirmDelete}
          handleDuplicateContent={handleDuplicateContent}
          isDuplicating={isDuplicating}
          isLoading={
            isObject(globalContentResult) &&
            Object.keys(globalContentResult)?.length === 0
          }
        />

        {globalContentResult && (
          <div
            style={{
              position: 'absolute',
              bottom: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: 'calc(100% - 60px)',
            }}
          >
            <Pagination
              onChange={(event, value) => handleFilters('page', value)}
              page={globalContentFilters.page || 1}
              color='primary'
              size='small'
              count={globalContentResult?.list?.last_page || 1}
              shape='rounded'
              hideNextButton
              hidePrevButton
            />

            <h5 style={{ color: '#fff' }}>
              Total results: {globalContentResult?.list?.total || ''}
            </h5>
          </div>
        )}
      </div>
    </div>
  )
}

ContentManage.displayName = 'ContentManage'

/**
 * The properties.
 * @type {Object}
 */

ContentManage.propTypes = {}

export default ContentManage
