import { deepSearch } from '../../../../shared/helpers/deepSearch'

export const handleMitreTags = (
  mitreConfig,
  technique,
  tactic,
  techniqueData,
  tagTactics,
  devTags,
  tags,
  devContent,
  internal_key,
  shouldAdd,
) => {
  let currentTagTactics = tagTactics ? { ...tagTactics } : {}
  let currentDevTags = devTags ? [...devTags] : undefined
  let currentTags = tags ? [...tags] : []
  const currentDevContent = { ...devContent }

  if (techniqueData.pivot === undefined) {
    techniqueData.pivot = {
      tactic_id: tactic,
      technique_id: techniqueData.technique_id || techniqueData.id,
    }
  }

  if (techniqueData.tag_id === undefined) {
    techniqueData.tag_id = techniqueData.id
  }

  if (tactic !== undefined) {
    if (currentTagTactics[technique] === undefined) {
      currentTagTactics[technique] = [tactic]
    } else {
      if (currentTagTactics[technique].includes(tactic)) {
        if (shouldAdd === undefined) {
          //not updating tag_tactics when adding a tag through connectors section, when the tag is already added
          currentTagTactics[technique] = currentTagTactics[technique].filter(
            (tac) => tac !== tactic,
          )
        }
      } else {
        currentTagTactics[technique] = [...currentTagTactics[technique], tactic]
      }
    }

    if (currentTagTactics[technique].length === 0) {
      delete currentTagTactics[technique]
    }
  }

  if (
    techniqueData?.internal_keys?.length === 0 &&
    techniqueData?.tag_categories?.length > 0
  ) {
    //backend changed - now internal keys are present in 'tag_categories' key
    techniqueData.internal_keys = techniqueData.tag_categories.split(',')
  }

  if (techniqueData.internal_keys) {
    //adding a tag through MITRE
    let tagInMultipleSections = [] //check if a tag is both present in one section and absent in other
    const techniqueNameWithoutInternalKey = deepSearch(
      mitreConfig,
      'tag_id',
      (k, v) => v === techniqueData?.tag_id,
    )?.name
    techniqueData.internal_keys.forEach((key) => {
      if (!currentDevContent[key]) {
        return false
      }

      if (currentDevContent[key]?.content?.connectors === undefined) {
        currentDevContent[key].content.connectors = []
      }

      const devTagExists = currentDevContent[key]?.content?.connectors?.find(
        (tag) => {
          const tagNameWithoutInternalKey = deepSearch(
            mitreConfig,
            'tag_id',
            (k, v) => v === tag?.id,
          )?.name
          return (
            tag.id === techniqueData.tag_id &&
            tagNameWithoutInternalKey?.toLowerCase() ===
              techniqueNameWithoutInternalKey?.toLowerCase()
          )
        },
      )

      const returnFiltered = (key) => {
        return currentDevContent[key]?.content?.connectors?.filter((tag) => {
          const tagNameWithoutInternalKey = deepSearch(
            mitreConfig,
            'tag_id',
            (k, v) => v === tag?.id,
          )?.name
          return (
            tagNameWithoutInternalKey?.toLowerCase() !==
            techniqueNameWithoutInternalKey?.toLowerCase()
          )
        })
      }

      if (devTagExists) {
        tagInMultipleSections = [...tagInMultipleSections, true]
        if (internal_key === 'tags') {
          currentTags = currentTags.filter((tag) =>
            tag.id
              ? tag.id !== techniqueData.tag_id
              : tag !== techniqueData.tag_id,
          )
          currentDevContent.tags.content.connectors =
            currentDevContent.tags.content.connectors.filter(
              (tag) =>
                tag?.name?.toLowerCase() !== techniqueData?.name?.toLowerCase(),
            )
        } else {
          if (currentTagTactics[techniqueData.tag_id] === undefined) {
            //when adding the same tag
            currentTags = currentTags.filter((tag) =>
              tag.id
                ? tag.id !== techniqueData.tag_id
                : tag !== techniqueData.tag_id,
            )
          }

          currentDevContent[key].content.connectors = returnFiltered(key)
        }
      } else {
        tagInMultipleSections = [...tagInMultipleSections, false]
        const newTag = {
          id: techniqueData.tag_id,
          name: techniqueData?.title || techniqueData?.name,
          tactic_id: techniqueData.pivot
            ? techniqueData.pivot.tactic_id
            : techniqueData.tactic_id,
          technique_id: techniqueData.id,
          text: techniqueData?.title || techniqueData?.name,
          title: techniqueData?.title || techniqueData?.name,
          type: 'tag',
        }

        if (currentDevContent[key]) {
          currentDevContent[key].content.connectors = [
            ...currentDevContent[key].content.connectors,
            newTag,
          ]
        }

        currentTags = [
          ...currentTags,
          { id: techniqueData.tag_id, content_section_id: key + '_connectors' },
        ]
      }
    })

    if (
      (tagInMultipleSections[0] === false &&
        tagInMultipleSections[1] === true) ||
      (tagInMultipleSections[0] === true && tagInMultipleSections[1] === false)
    ) {
      techniqueData.internal_keys.forEach((key) => {
        currentDevContent[key].content.connectors = currentDevContent[
          key
        ].content.connectors.filter((tag) => tag.id !== techniqueData.tag_id)
      })
    }

    if (currentDevContent?.ttps) {
      //operations case fix where ttps section will never be in internal keys
      currentDevContent.ttps.content.connectors =
        currentDevContent?.ttps?.content?.connectors?.filter(
          (tag) => tag.technique_id !== techniqueData.tag_id,
        )
    }
  } else {
    //adding a tag through connectors section
    const techniqueWithKeys = deepSearch(
      mitreConfig,
      'tag_id',
      (k, v) => v === techniqueData?.tag_id,
    )
    const tagCategories = techniqueWithKeys?.tag_categories?.split(',')

    if (currentDevContent[internal_key]?.content?.connectors === undefined) {
      currentDevContent[internal_key].content.connectors = []
    }

    if (tagCategories && internal_key !== 'tags' && internal_key !== 'ttps') {
      //tags section can have mitre tags with other section names
      tagCategories?.forEach((category) => {
        if (!currentDevContent[category]) return false //if no section for selected tag is present

        const devTagExists = currentDevContent[
          category
        ]?.content?.connectors?.find(
          (tag) =>
            tag?.id === techniqueData?.tag_id &&
            tag?.name?.toLowerCase() === techniqueData?.name?.toLowerCase(),
        )

        if (devTagExists) {
          currentDevContent[category].content.connectors = currentDevContent[
            category
          ]?.content?.connectors?.filter(
            (tag) =>
              tag?.name?.toLowerCase() !== techniqueData?.name?.toLowerCase() ||
              tag?.id !== techniqueData?.tag_id,
          )
          currentTags = currentTags.filter((tag) =>
            tag.id
              ? tag.id !== techniqueData.tag_id
              : tag !== techniqueData.tag_id,
          )
        } else {
          const newTag = {
            id: techniqueData.id,
            name: techniqueData?.title || techniqueData?.name,
            tactic_id: techniqueData.tactic_id,
            technique_id: techniqueData.id,
            text: techniqueData?.title || techniqueData?.name,
            title: techniqueData?.title || techniqueData?.name,
            type: 'tag',
          }

          currentDevContent[category].content.connectors = [
            ...(currentDevContent[category]?.content?.connectors || []),
            newTag,
          ]
          currentTags = [
            ...currentTags,
            {
              id: techniqueData.id,
              content_section_id: category + '_connectors',
            },
          ]
        }
      })
    } else {
      const devTagExists = currentDevContent?.[
        internal_key
      ]?.content?.connectors?.find(
        (tag) =>
          tag?.id === techniqueData?.tag_id &&
          tag?.name?.toLowerCase() === techniqueData?.name?.toLowerCase(),
      )

      if (devTagExists) {
        currentDevContent[internal_key].content.connectors = currentDevContent[
          internal_key
        ]?.content?.connectors?.filter(
          (tag) =>
            tag?.name?.toLowerCase() !== techniqueData?.name?.toLowerCase(),
        )
      } else {
        const newTag = {
          id: techniqueData.id,
          name: techniqueData?.title || techniqueData?.name,
          tactic_id: techniqueData.tactic_id,
          technique_id: techniqueData.id,
          text: techniqueData?.title || techniqueData?.name,
          title: techniqueData?.title || techniqueData?.name,
          type: 'tag',
        }

        currentDevContent[internal_key].content.connectors = [
          ...(currentDevContent[internal_key]?.content?.connectors || []),
          newTag,
        ]
        currentTags = [
          ...currentTags,
          {
            id: techniqueData.id,
            content_section_id: internal_key + '_connectors',
          },
        ]
      }
    }
  }

  if (currentDevTags) {
    currentDevTags.forEach((tag) => {
      if (!tag.tag_id) tag.tag_id = tag.id
    })

    const tagExists =
      currentDevTags?.find((tag) => tag.tag_id === techniqueData.tag_id) !==
      undefined

    if (tagExists) {
      const existsInConnector =
        internal_key &&
        currentDevContent[internal_key]?.content?.connectors?.find(
          (tag) => tag.technique_id === techniqueData.tag_id,
        )

      if (!existsInConnector) {
        currentDevTags = currentDevTags.filter(
          (tag) => tag.tag_id !== techniqueData.tag_id,
        )
      }
    } else {
      if (currentTagTactics[techniqueData.tag_id]) {
        currentDevTags = [...currentDevTags, techniqueData]
      }
    }
  }

  let newCurrentTags = [...currentTags]
  currentTags?.forEach(({ id, content_section_id }) => {
    const connectorSection = content_section_id?.split('_')[0]
    const existInSection = devContent[
      connectorSection
    ]?.content?.connectors?.find((tag) => tag?.technique_id === id)

    if (!existInSection) {
      newCurrentTags = newCurrentTags?.filter((tag) => tag?.id !== id)
      return false
    }
  })

  return [currentTagTactics, currentDevTags, newCurrentTags, currentDevContent]
}
