import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const profilesCollection = (token, data) =>
  client.post(apiUrl + `/api/search`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const profilesOptions = (token) =>
  client.get(apiUrl + '/api/profiles/refine-sidebar-options', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const singleProfile = (token, id) =>
  client.get(apiUrl + `/api/profiles/${id}/view`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
