import React, { useState, useEffect, useRef } from 'react'
import './SinglePiece.scss'
import { singleProfile } from '../../shared/services/api/profiles/profiles'
import { singleScenario } from '../../shared/services/api/scenarios/scenarios'
import {
  singleAlert,
  updateStatus,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  viewTimeLogging,
} from '../../shared/services/api/alerts/alerts'
import { singleReport } from '../../shared/services/api/reports/reports'
import { Divider } from '@material-ui/core'
import PieceTabs from './PieceContent/PieceTabs'
import ContentHeading from './PieceContent/ContentHeading'
import ContentTopBar from './PieceContent/ContentTopBar'
import debounce from 'lodash/debounce'
import Spinner from '../../shared/Spinner'
import { useGlobalContext } from '../../context/globalContext'
import { useViewStateContext } from '../../context/viewStateContext'
import { useChat } from '../../context/chatContext'
import * as workerTimers from 'worker-timers'
import { useUserContext } from '../../context/userContext'
import useNotification from '../../hooks/useNotification'

const SinglePiece = ({
  items,
  currentModule,
  filtersOpen,
  listLoading,
  setToastMessage,
  configuration,
  type,
  modifyWorkspaceName,
  changeStatus,
  handleSetPiece,
  resetPiece,
  highlightPhrase,
}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { handleGlobalState } = useGlobalContext()
  const { setWorkspaces, activePiece, setActivePiece } = useViewStateContext()
  const { successToast } = useNotification()
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [currentTab, setCurrentTab] = useState('content')
  const contentRef = useRef(null)
  const { chatOpen } = useChat()
  const { isIdle } = useUserContext()

  const handleViewingContent = async (actPiece) => {
    const profileTypes = [
      'threat-actor',
      'operation',
      'malware-tools',
      'incident',
    ]
    try {
      const loggingData = {
        content_id: actPiece,
        content_type: profileTypes.includes(currentModule?.state)
          ? 'profile'
          : currentModule?.state,
        log_view_token: data?.log_view_token,
      }
      const response = await viewTimeLogging(token(), loggingData)
      setData((current) => {
        return {
          ...current,
          log_view_token: response?.log_view_token || data?.log_view_token,
        }
      })
    } catch (err) {}
  }
  useEffect(() => {
    const isLocal = process.env.REACT_APP_ENV === 'local'
    if (!isLocal && !isIdle) {
      const interval = workerTimers.setInterval(() => {
        !document?.hidden && data?.id && handleViewingContent(activePiece)
      }, 10000)
      return () => workerTimers.clearInterval(interval)
    }
  }, [
    data?.id,
    data?.log_view_token,
    activePiece,
    currentModule?.state,
    isIdle,
  ])

  useEffect(() => {
    setCurrentTab(currentModule.currentTab)
  }, [currentModule.currentTab])

  useEffect(() => {
    let pieceExists =
      activePiece != null && items.find((item) => item.id === activePiece)

    pieceExists && modifyWorkspaceName(currentModule.id, pieceExists.title)

    if (activePiece !== null && !pieceExists) {
      !listLoading && getPieceData()
    } else {
      setData(pieceExists)
    }

    if (activePiece !== null) {
      setTimeout(() => {
        if (contentRef.current) {
          contentRef.current.scrollTop = currentModule.scrollPosition
        }
      })
    }
  }, [activePiece, listLoading])

  const getPieceData = async (shouldOverwrite) => {
    const { state } = currentModule
    let response
    setLoading(true)

    try {
      if (state === 'alert') {
        response = await singleAlert(token(), activePiece)
      } else if (type === 'profile') {
        response = await singleProfile(token(), activePiece)
      } else if (state === 'scenario') {
        response = await singleScenario(token(), activePiece)
      } else if (state === 'report') {
        response = await singleReport(token(), activePiece)
      }

      if (response.error === true) {
        setToastMessage(['error', response.message])
        setLoading(false)
        return false
      }

      if (response?.data?.ioc?.total === 0) {
        response.data.hasNoIoc = true
      }

      response.data.content = JSON.parse(response.data.content)

      if (response.data.read === false) {
        response.data.read = true
        handleSetPiece(response.data.id, true)
      }

      handleGlobalState(
        state === 'malware-tools' ? state : state + 's',
        response.data,
        shouldOverwrite,
      )

      if (
        response.data.mitre_navigator_data.layers &&
        (response.data.mitre_navigator_data.layers.length === 1 ||
          (response.data.mitre_navigator_data.layers[1] &&
            response.data.mitre_navigator_data.layers[1].mode === 2))
      ) {
        response.data.mitre_navigator_data.layers[0].$isActive = true
      }

      response.data.mitre_navigator_data.layers &&
        response.data.mitre_navigator_data.layers.forEach(
          (item) => (item.$isVisible = true),
        )
      setData(response.data)
      modifyWorkspaceName(currentModule.id, response.data.title)
      setLoading(false)

      return response.data
    } catch (err) {}
  }

  const handleMarkStatus = (nextStatus) => {
    const update = async () => {
      const { state } = currentModule

      try {
        const payload = { status: nextStatus, ids: [activePiece] }
        const currentState = { ...data }

        const update = await updateStatus(token(), type + 's', payload)

        successToast({ message: update })

        if (nextStatus === 'flagged') {
          currentState.flagged = true
        }
        if (nextStatus === 'unflagged') {
          currentState.flagged = false
        }

        changeStatus(currentState.id, nextStatus)

        if (nextStatus === 'unread') {
          handleGlobalState(
            state === 'malware-tools' ? state : state + 's',
            activePiece,
            false,
            true,
          )
          setActivePiece(null)
          setTimeout(() =>
            modifyWorkspaceName(
              currentModule.id,
              type === 'malware-tools' ? type : type + 's',
            ),
          )
        } else {
          setData({ ...currentState })
          handleGlobalState(
            state === 'malware-tools' ? state : state + 's',
            currentState,
            true,
            false,
          )
        }
      } catch (err) {
        console.log(err)
      }
    }

    update()
  }

  const handleCopyPieceLink = (pieceType) => {
    setToastMessage(['success', 'Link copied to clipboard'])
    navigator.clipboard.writeText(
      `${location.href}?type=${pieceType}&id=${activePiece}`,
    )
  }

  const schemaType = () => {
    if (type === 'alert') {
      return configuration
    } else if (type === 'profile') {
      return configuration
    } else if (type === 'scenario') {
      return (
        data &&
        configuration?.find(
          (item) => item?.name === data?.content_scenario_framework_name,
        )?.schema
      )
    } else if (type === 'report') {
      return (
        data &&
        configuration?.find((item) => item?.slug === data?.content_subtype_slug)
          ?.schema
      )
    }
  }

  const updateMitre = (mitreData) => {
    const currentState = { ...data }
    currentState.mitre_navigator_data.layers = mitreData
    setData(currentState)
  }

  const updatePosition = debounce((pos) => {
    setWorkspaces((currentState) => {
      const currentWorkspace = currentState?.find(
        (workspace) => workspace.id === currentModule.id,
      )

      currentWorkspace.scrollPosition = pos
      return currentState
    })
  }, 100)

  return (
    <div className='single-piece'>
      {isLoading && <Spinner center size={30} border={1} />}

      {!isLoading && data && (
        <>
          <div className='single-piece__header'>
            <ContentTopBar
              data={data}
              filtersOpen={filtersOpen}
              handleMarkStatus={handleMarkStatus}
              handleCopyPieceLink={handleCopyPieceLink}
              type={type}
              setToastMessage={setToastMessage}
              resetPiece={resetPiece}
            />
          </div>

          <div
            ref={contentRef}
            className='single-piece__details'
            onScroll={(e) => updatePosition(e.target.scrollTop)}
            style={{
              maxWidth: chatOpen
                ? 'unset'
                : !filtersOpen
                ? 'calc(90% - 40px)'
                : 'unset',
            }}
          >
            <ContentHeading data={data} type={type} />

            <Divider style={{ marginTop: 20 }} />

            <PieceTabs
              data={data}
              currentModule={currentModule}
              filtersOpen={filtersOpen}
              activePiece={activePiece}
              getPieceData={getPieceData}
              setCurrentTab={setCurrentTab}
              currentTab={currentTab}
              setActivePiece={setActivePiece}
              configuration={schemaType()}
              type={type}
              setData={setData}
              updateMitre={updateMitre}
              listLoading={listLoading}
              highlightPhrase={highlightPhrase}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default SinglePiece
