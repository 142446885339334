import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const getAssociatedProfiles = (token) =>
  client.get(apiUrl + '/api/related-profiles', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getAssociatedAlerts = (token) =>
  client.get(apiUrl + '/api/related-alerts', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const allowedGroups = (token, type) =>
  client.get(apiUrl + `/api/allowed/groups/${type}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const allowedGroupOptions = (token, type, id) =>
  client.get(apiUrl + `/api/allowed/groups/${type}/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const contentConfiguration = (token) =>
  client.get(apiUrl + '/api/modules/content/configuration', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const sendInitialDraft = (token, type, data) =>
  client.post(apiUrl + `/api/admin/${type}/linear-content-builder`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const editPiece = (token, type, id) =>
  client.get(apiUrl + `/api/admin/${type}/linear-content-builder/${id}/edit`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const saveDraft = (token, type, id, data) =>
  client.put(apiUrl + `/api/admin/${type}/linear-content-builder/${id}`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const publishContent = (token, type, id, data) =>
  client.put(
    apiUrl + `/api/admin/${type}/linear-content-builder/${id}/publish`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const sendForApproval = (token, type, id) =>
  client.put(
    apiUrl + `/api/admin/${type}/${id}/sendForApproval`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const denyContent = (token, type, id) =>
  client.put(
    apiUrl + `/api/admin/${type}/${id}/deny`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const tagsList = (token, slug) =>
  client.get(apiUrl + `/api/tags/${slug}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const tagsListPhrase = (token, type, slug, phrase) =>
  client.get(
    apiUrl +
      `/api/connectors?type=${type}&categorySlug=${slug}&phrase=${phrase}&no_limit=1`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const addSectionComment = (token, type, id, section, data) =>
  client.post(
    apiUrl +
      `/api/admin/${type}/linear-content-builder/${id}/sections/${section}/comments`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const preview = (token, type, id) =>
  client.get(apiUrl + `/api/admin/${type}/${id}/preview`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const unpublishContent = (token, type, id) =>
  client.put(
    apiUrl + `/api/admin/${type}/${id}/unpublish`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const downloadIoc = (token) =>
  client.get(apiUrl + `/api/ioc/export-template?access_token=${token}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const lockContent = (token, type, id) =>
  client.post(apiUrl + `/api/admin/${type}/lock`, id, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const allowedSectors = (token, type) =>
  client.get(apiUrl + `/api/allowed/sectors/${type}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
