export const getPossibleSections = (configuration, content) => {
  return configuration?.map((section) => {
    const key = section.internal_key
    const columns = section.columns
    const title =
      section?.headline?.split(' ')?.at(-1) === '(required)'
        ? section?.headline?.split(' ')?.slice(0, -1)
        : section.headline_viewer || section.headline
    let val = Object.entries(content).filter((type) => {
      const sectionName = type[0].split('_')[0]
      const value = type[1]
      if (key === sectionName && key !== 'mitre-tags' && key !== 'ioc') {
        let sectionValue
        const secName = type[0]
        Object.entries(columns).map((item) => {
          const key = item[0]
          if (
            sectionName + '_' + key === secName &&
            value &&
            typeof value === 'number'
              ? value > 0
              : value !== null && value.length > 0
          ) {
            sectionValue = value
          }
        })

        return sectionValue
      }
    })

    val.forEach((item, index) => {
      //deleting is_hidden elements from sections
      const keyName = item[0].split('_')[0]
      const columnName = item[0].split('_')[1]
      const sectionConfig = configuration?.find(
        (s) => s.internal_key === keyName,
      )
      if (sectionConfig?.columns[columnName]?.is_hidden) {
        val.splice(index, 1)
      }
    })

    if (val.length > 0 && !section.is_section_hidden) {
      return val.map((item) => {
        const type =
          key === 'dates'
            ? item[0].split('_').slice(1).join('_')
            : item[0].split('_')[1] //for capability update date
        const malwareType = type === 'malware' && item[0].split('_')[2]
        return { [title]: { [malwareType || type]: item[1] } }
      })
    }
  })
}

export const mapEntriesToSections = (sections, possibleSections) => {
  possibleSections?.forEach((item) => {
    item?.forEach((i) => {
      const sectionKey = Object.keys(i)[0]
      const sectionValue = Object.values(i)[0]
      const columnType = Object.keys(sectionValue)[0]
      const columnValue = Object.values(sectionValue)[0]
      sections[sectionKey] = {
        ...sections[sectionKey],
        [columnType]: columnValue,
      }
    })
  })
  return sections
}

export const handleMapTtp = (sections, configuration, mitreData) => {
  configuration?.forEach((sec) => {
    if (sec?.columns['text-mitre-tactics']?.tacticsIds) {
      if (
        Object.keys(
          handleTtp(mitreData, sec.columns['text-mitre-tactics'].tacticsIds),
        ).length > 0
      ) {
        sections[sec.headline] = {
          ...sections[sec.headline],
          ttp: sec.columns['text-mitre-tactics'].tacticsIds,
        }
      } else {
        delete sections[sec.headline]
      }
    }
  })

  return sections
}

export const handleAddDates = (
  sections,
  additional_dates,
  type,
  dates_date_assessment,
) => {
  if (additional_dates) {
    if (additional_dates.active_since) {
      sections['Active Since'] = { active_since: additional_dates.active_since }
    }
    if (additional_dates.discovery_date) {
      sections['Dates'] = {
        ...sections['Dates'],
        ...{ discovery_date: additional_dates.discovery_date },
      }
    }
    if (additional_dates.event_date) {
      sections['Dates'] = {
        ...sections['Dates'],
        ...{ event_date: additional_dates.event_date },
      }
    }
    if (additional_dates.start_date) {
      sections['Date'] = {
        ...sections['Date'],
        ...{ start_date: additional_dates.start_date },
      }
    }
    if (additional_dates.end_date) {
      sections['Date'] = {
        ...sections['Date'],
        ...{ end_date: additional_dates.end_date },
      }
    }
  }

  if ((type === 'alerts' || type === 'alert') && dates_date_assessment) {
    sections['Dates'] = {
      ...sections['Dates'],
      ...{ text: dates_date_assessment },
    }
  }

  if ((type === 'profile' || type === 'profiles') && dates_date_assessment) {
    sections['Active Since'] = {
      ...sections['Active Since'],
      ...{ text: dates_date_assessment },
    }
  }

  return sections
}

export const handleTtp = (mitreData, tacticsIds) => {
  let ttpsData = {}

  const getMatriceSortId = (data, matriceId) => {
    let matrice = data.matrices.filter((matrice) => matrice.id == matriceId)
    return matrice.length ? matrice[0].sort_id : 0
  }

  mitreData.techniquesNames &&
    Object.entries(mitreData.techniquesNames).forEach((tec) => {
      const techniqueId = tec[0]
      const technique = tec[1]
      let matriceId = technique.matrix_id

      let isTechniqueToBeDisplayed = false

      technique.tactics_ids.forEach((tacticId) => {
        if (tacticsIds.indexOf(tacticId) > -1) {
          isTechniqueToBeDisplayed = true
        }
      })

      if (isTechniqueToBeDisplayed) {
        if (!ttpsData[matriceId]) {
          ttpsData[matriceId] = {
            name: mitreData.matricesNames[matriceId],
            tactics: {},
            sort_id: getMatriceSortId(mitreData, matriceId),
          }
        } else {
          ttpsData[matriceId].sort_id = getMatriceSortId(mitreData, matriceId)
        }

        technique.tactics_ids.forEach((tacticId) => {
          if (!ttpsData[matriceId].tactics[tacticId]) {
            ttpsData[matriceId].tactics[tacticId] = {
              name: mitreData.tacticsNames[tacticId],
              techniques: {},
            }
          }

          ttpsData[matriceId].tactics[tacticId].techniques[techniqueId] =
            technique
        })
      }
    })
  return ttpsData
}
