import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const scenariosCollection = (token, data) =>
  client.post(apiUrl + `/api/search`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const scenariosOptions = (token) =>
  client.get(apiUrl + '/api/scenarios/refine-sidebar-options', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const singleScenario = (token, id) =>
  client.get(apiUrl + `/api/scenarios/${id}/view`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const filterSettings = (token) =>
  client.get(apiUrl + `/api/scenarios/refine-sidebar-options`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
