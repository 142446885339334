import {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'

import { logout } from '../shared/services/api/login/login'
import { cancelRequests } from '../shared/services/http/client'
import { useQueryClient } from '@tanstack/react-query'

interface CommentsAccess {
  id: number
  name: string
}

interface MenuModule {
  id: number
  name: string
  inner_name: string
  slug: string
  has_access: boolean
}

// interface Group {
//   id: number
//   name: string
// }

// interface GroupModules {
//   alert: Group[]
//   profile: Group[]
//   scenario: Group[]
//   report: Group[]
// }

interface UserData {
  accepted_terms_version: number
  comments_access_groups: {
    alert: CommentsAccess[]
    profile: CommentsAccess[]
    scenario: CommentsAccess[]
    report: CommentsAccess[]
  }
  forum_groups: {
    id: number
    name: string
    initials: string | null
  }[]
  chat_groups: {
    id: number
    name: string
    initials: string | null
    chat_settings: {
      chat_email_notify_interval: string
      chat_email_notify_mute: boolean
      total_unread_messages: number
    }
    pivot: {
      organisation_id: number
      group_id: number
    }
  }[]
  is_admin: boolean
  is_chat_enabled: boolean
  is_forum_enabled: boolean
  is_link_analysis_enabled: boolean
  is_management_mode_possible: boolean
  is_notification_system_enabled: boolean
  is_origin_organisation_notification_system_enabled: boolean
  is_pdf_upload_enabled: boolean
  is_community_admin: boolean
  is_terms_of_use_accepted: boolean
  is_tutorial_active: boolean
  is_search_tutorial_active: boolean
  menu: {
    create_mode: MenuModule[]
    view_mode: MenuModule[]
  }
  modules: {
    create_mode: MenuModule[]
    view_mode: MenuModule[]
  }
  organisation_name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user_access_groups_per_content: any
  user_info: {
    id: number
    email: string
    created_at: string
    updated_at: string
    username: string
    first_name: string
    last_name: string
    is_two_factor_enabled: boolean
    has_confirmed_scanning_two_factor_secter: boolean
    has_access_to_developers_platform: boolean
    is_comments_moderator: boolean
    is_tutorial_active: boolean
    is_terms_of_use_accepted: boolean
    accepted_terms_version: number
    terms_accepted_at: string
    terms_acceptance_ip: string
    avatar: string
    central_user_id: number
    chat_display_name: string
  }
}

interface UserContextData {
  user: UserData | null
  setUser: Dispatch<SetStateAction<UserData | null>>
  handleLogout: () => void
  updateTutorialState: (a: boolean) => void
  leftMenuVisible: boolean
  setLeftMenuVisible: Dispatch<SetStateAction<boolean>>
  isIdle: boolean
  setIdle: Dispatch<SetStateAction<boolean>>
}

interface ChildrenType {
  children: ReactElement
}

export const UserContext = createContext<UserContextData>({
  user: null,
  setUser: () => {},
  handleLogout: () => {},
  updateTutorialState: () => {},
  leftMenuVisible: !isMobile,
  setLeftMenuVisible: () => {},
  isIdle: false,
  setIdle: () => {},
})

export const UserContextProvider = ({ children }: ChildrenType) => {
  const [user, setUser] = useState<UserData | null>(null)
  const [leftMenuVisible, setLeftMenuVisible] = useState(!isMobile)
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [isIdle, setIdle] = useState(false)
  const history = useHistory()
  const queryClient = useQueryClient()

  const handleLogout = async () => {
    cancelRequests()
    try {
      const response = (await logout(token())) as unknown as Awaited<
        Promise<{
          error: boolean
          message: string
        }>
      >

      if (response.error) {
        alert(response.message)
        return false
      }

      localStorage.clear()
      queryClient.resetQueries({ queryKey: ['dashboard'], exact: true })

      history.push({
        pathname: '/app/auth/login',
        state: { message: response.message },
      })
    } catch (err) {}
  }

  const updateTutorialState = (bool: boolean) => {
    const currentUser = { ...user } as UserData
    currentUser.user_info.is_tutorial_active = bool
    setUser(currentUser)
  }

  const props = {
    user,
    setUser,
    handleLogout,
    updateTutorialState,
    leftMenuVisible,
    setLeftMenuVisible,
    isIdle,
    setIdle,
  }

  return <UserContext.Provider value={props}>{children}</UserContext.Provider>
}

export const useUserContext = () => {
  return useContext(UserContext)
}
