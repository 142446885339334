import { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from './pages/Home'
import Auth from './pages/Auth'
import './App.scss'
import './assets/style.css'
import { GlobalContextProvider } from './context/globalContext'
import { CreateManagementContextProvider } from './context/createManagementState'
import { UserContextProvider } from './context/userContext'
import { ViewContentContextProvider } from './context/viewStateContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ChatContextProvider } from './context/chatContext'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const App = () => {
  const queryClient = new QueryClient()

  const isContentAlreadyEdited = JSON.parse(
    localStorage.getItem('isContentEdited'),
  )

  const handleTabClosing = () => {
    if (!isContentAlreadyEdited) {
      localStorage.setItem('isContentEdited', null)
    }
  }

  useEffect(() => {
    window.addEventListener('unload', handleTabClosing)

    return () => window.removeEventListener('unload', handleTabClosing)
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        <Redirect exact from='/app/auth/logout' to='/app/auth/login' />

        <Redirect exact from='/' to='/app/auth/login' />

        <Redirect exact from='/app' to='/app/auth/login' />

        <Route path='/app/auth'>
          <Auth />
        </Route>

        <Route path='/app'>
          <GlobalContextProvider>
            <UserContextProvider>
              <CreateManagementContextProvider>
                <ViewContentContextProvider>
                  <ChatContextProvider>
                    <Home />

                    {/* <ReactQueryDevtools /> */}
                  </ChatContextProvider>
                </ViewContentContextProvider>
              </CreateManagementContextProvider>
            </UserContextProvider>
          </GlobalContextProvider>
        </Route>
      </Switch>
    </QueryClientProvider>
  )
}

export default App
