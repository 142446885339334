import { IconButton, TextField, Tooltip } from '@material-ui/core'
import { Fullscreen, FullscreenExit } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import DescriptionInfo from './components/DescriptionInfo'
import './MitreNavigator.scss'
import {
  addLayer,
  deleteLayer,
  getMitreNavigatorData,
} from '../../../../../shared/services/api/mitre/mitre'
import Layers from './components/Layers'
import differenceBy from 'lodash/differenceBy'
import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable'
import { SortableTab } from './components/SortableTab'
import merge from 'lodash/merge'
import {
  handleDeleteTacticIfMissing,
  handleDeleteTechniqueIfMissing,
  handleUpdateColourGradeAcrossLayers,
} from './helpers'
import ViewLayers from './components/ViewLayers'
import MitreTechnique from './components/MitreTechnique'
import Spinner from '../../../../../shared/Spinner'
import { exportMitre } from '../../../../../shared/services/api/alerts/alerts'
import { Download } from '@mui/icons-material'
import { useCreateManagementContext } from '../../../../../context/createManagementState'
import orderBy from 'lodash/orderBy'

const MitreNavigator = ({ data, view, frameworkId, pieceId, updateMitre }) => {
  const { handleCreateState } = useCreateManagementContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [tab, setTab] = useState(
    (data && data.matrices && data.matrices[0] && data.matrices[0].id) || 2,
  )
  const [descriptionInfo, setDescriptionInfo] = useState(undefined)
  const [searchText, setSearchText] = useState('')
  const [fullscreenMode, setFullscreenMode] = useState(false)
  const [mitre, setMitre] = useState(undefined)
  const [isLoading, setLoading] = useState(true)
  const [isAdding, setAdding] = useState(false)
  const colours = [
    { colour: 1 },
    { colour: 2 },
    { colour: 3 },
    { colour: 4 },
    { colour: 5 },
    { colour: 6 },
    { colour: 7 },
    { colour: 8 },
    { colour: 9 },
    { colour: 10 },
  ]
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))
  const [items, setItems] = useState([])
  const [isDragging, setIsDragging] = useState(false)
  const [selectedPlatforms, setSelectedPlatforms] = useState({})

  const handleMitreLayer = async (layerId, isActive) => {
    setAdding(true)

    try {
      const currentState = { ...data }
      let response = ''
      const isInitialLayer = !currentState.layers || !currentState.layers.length

      if (layerId === undefined) {
        response = await addLayer(token(), pieceId)
        if (isInitialLayer) {
          response.createdItem.mode = 1
          response.createdItem.$isVisible = true
          response.createdItem.$isActive = true
          response.createdItem.colour = availableColours()[0].colour
          response.createdItem.is_base = true
        } else {
          currentState.layers.forEach((item) => delete item.$isActive)
          response.createdItem.colour =
            currentState.layers[1] && currentState.layers[1].mode === 3
              ? availableColours()[0].colour
              : currentState.layers[0].colour
          response.createdItem.mode = currentState.layers[1]
            ? currentState.layers[1].mode
            : 2
          response.createdItem.$isActive = true
          response.createdItem.$isVisible = true
        }
      } else {
        response = await deleteLayer(token(), pieceId, layerId)
        if (isActive) {
          currentState.layers[0].$isActive = true
        }
      }

      if (response.error) {
        setLoading(false)
        return false
      }

      if (isInitialLayer) {
        currentState.layers = [response.createdItem]
        currentState.mapping = {
          [response.createdItem.id]: { tactics: [], techniques: {} },
        }
        currentState.matrices = [
          { id: 2, sort_id: 0 },
          { id: 3, sort_id: 1 },
          { id: 4, sort_id: 2 },
        ]
        currentState.matricesNames = {
          2: 'Enterprise',
          3: 'Mobile',
          4: 'ICS',
        }
        currentState.tacticsNames = []
        currentState.techniquesNames = []
      } else {
        if (layerId) {
          currentState.layers = currentState.layers.filter(
            (item) => item.id !== layerId,
          )
          delete currentState.mapping[layerId]
        } else {
          response.createdItem.$isVisible = true
          currentState.layers = [...currentState.layers, response.createdItem]
          currentState.mapping = {
            ...currentState.mapping,
            [response.createdItem.id]: { tactics: [], techniques: {} },
          }
          if (currentState.layers.length === 2) {
            Object.values(currentState.mapping).forEach((layer) => {
              Object.values(layer.techniques).forEach((technique) => {
                Object.values(technique).forEach((tactic) => {
                  tactic.colour_grade = 1
                })
              })
            })
          }
        }
      }

      handleCreateState('mitre_navigator', currentState, true)
      setLoading(false)
      setAdding(false)
    } catch (err) {
      console.log(err)
    }
  }

  const handleMitreNavigatorState = (key, prop, value) => {
    if (!view) {
      const currentState = { ...data }

      if (key === 'matrices') {
        currentState[key] = prop
        handleCreateState('mitre_navigator', currentState, true)
        return false
      }

      currentState[key] = value

      if (prop === 'mode') {
        if (
          currentState.layers.length > 1 &&
          currentState.layers[1].mode === 3
        ) {
          //compare mode, set colour_grade to 5 across data.mapping
          Object.values(currentState.mapping).forEach((layer) => {
            Object.values(layer.techniques).forEach((technique) => {
              Object.values(technique).forEach((tactic) => {
                tactic.colour_grade = 5
              })
            })
          })
        } else if (
          currentState.layers.length > 1 &&
          currentState[key][1].mode === 2
        ) {
          //merge mode - update colour_grade across data.mapping
          currentState.mapping =
            handleUpdateColourGradeAcrossLayers(currentState)
        }
      }

      handleCreateState('mitre_navigator', currentState, true)
    }
  }

  const loadMitreData = async () => {
    setLoading(true)
    try {
      const response = await getMitreNavigatorData(token(), frameworkId, !!view)
      let tabs = []

      if (data?.matrices?.length) {
        const allIndexIsZero = data?.matrices
          ?.map((matrix) => matrix?.sort_id)
          ?.every((n) => n === data?.matrices[0]?.sort_id)
        data?.matrices?.forEach((item, index) => {
          const singleTab = response?.categories?.find(
            (category) => category.id === item.id,
          )
          if (allIndexIsZero) {
            tabs[index] = { ...singleTab, sort_id: index }
          } else {
            tabs[index] = { ...singleTab, sort_id: item.sort_id }
          }
        })
      } else {
        response.categories.forEach((categ, index) => (categ.sort_id = index))
        tabs = response.categories
        data.matrices = response.categories
      }

      tabs = orderBy(tabs, 'sort_id', 'asc')

      setItems(tabs)

      const setInitialExpanded = () => {
        const currentMitre = { ...response }
        currentMitre.navigator.forEach((nav) => {
          if (nav.tactics && nav.tactics.length > 0) {
            nav.tactics.forEach((tactic) => {
              if (tactic.techniques && tactic.techniques.length > 0) {
                tactic.techniques.forEach((technique) => {
                  technique.sub_techniques &&
                    technique.sub_techniques.forEach((subtec) => {
                      if (
                        data.techniquesNames &&
                        data.techniquesNames[subtec.id]
                      ) {
                        technique.isExpanded = true
                      }
                    })
                })
              }
            })
          }
        })
        return currentMitre
      }

      setMitre(setInitialExpanded())

      if (
        (data && data.layers === undefined) ||
        (data && data.layers && data.layers.length === 0)
      ) {
        handleMitreLayer()
      } else {
        setLoading(false)
      }
    } catch (err) {}
  }

  useEffect(() => {
    loadMitreData()
  }, [])

  const handleExpanded = (stageId, tacticId, techniqueId) => {
    const currentState = { ...mitre }
    const currentTactic = currentState.navigator
      .filter((stage) => stage.id === stageId)[0]
      .tactics.filter((tactic) => tactic.id === tacticId)[0]
    const currentTechnique = currentTactic.techniques.filter(
      (technique) => technique.id === techniqueId,
    )[0]

    currentTechnique.isExpanded = currentTechnique.isExpanded !== true

    setMitre(currentState)
  }

  const availableColours = () => {
    return differenceBy(colours, data.layers, 'colour')
  }

  const handleAddTag = (
    techniqueId,
    tacticId,
    tacticName,
    techniqueData,
    tacticCategory,
  ) => {
    const currentState = { ...data }
    const activeLayer =
      currentState.mapping[currentState.layers.filter((i) => i.$isActive)[0].id]
    let techniques = activeLayer.techniques
    if (techniques[techniqueId] === undefined) {
      techniques[techniqueId] = { [tacticId]: { colour_grade: 0 } }
    }

    const currentTechnique = techniques[techniqueId]

    if (currentState.layers.length === 1) {
      //when a single, base layer is present
      if (currentTechnique[tacticId] === undefined) {
        currentTechnique[tacticId] = {}
        currentTechnique[tacticId].colour_grade = 1
      } else {
        if (currentTechnique[tacticId].colour_grade === 5) {
          delete currentTechnique[tacticId]
        } else {
          currentTechnique[tacticId].colour_grade = ++currentTechnique[tacticId]
            .colour_grade
        }
      }
    } else if (currentState.layers[1].mode === 2) {
      //when layer mode is set to 2 (MERGE)
      if (currentTechnique[tacticId] === undefined) {
        currentTechnique[tacticId] = {}
        currentTechnique[tacticId].colour_grade = 0
      }

      const maxLayersNumber = currentState.layers.length
      let currentShade = ''
      Object.values(currentState.mapping).some((layer) => {
        //get current colour grade
        if (
          layer.techniques[techniqueId] &&
          layer.techniques[techniqueId][tacticId]
        ) {
          if (layer.techniques[techniqueId][tacticId].colour_grade) {
            currentShade = layer.techniques[techniqueId][tacticId].colour_grade
          }
        }
      })

      if (
        currentTechnique[tacticId].colour_grade === 0 &&
        currentShade < maxLayersNumber
      ) {
        //when selecting a technique that is not present in current layer
        currentTechnique[tacticId].colour_grade === currentShade * 1 + 1 //set new colour grade to the current layer

        Object.values(currentState.mapping) &&
          Object.values(currentState.mapping).forEach((layer) => {
            if (layer.techniques[techniqueId]) {
              if (
                layer.techniques[techniqueId] &&
                layer.techniques[techniqueId][tacticId]
              ) {
                layer.techniques[techniqueId][tacticId].colour_grade =
                  currentShade * 1 + 1 //set new colour grade for the technique across layers
              }
            }
          })
      } else {
        //when de-selecting
        delete currentTechnique[tacticId]

        Object.values(currentState.mapping) &&
          Object.values(currentState.mapping).forEach((layer) => {
            if (
              layer.techniques[techniqueId] &&
              layer.techniques[techniqueId][tacticId]
            ) {
              if (layer.techniques[techniqueId][tacticId].colour_grade > 1) {
                layer.techniques[techniqueId][tacticId].colour_grade =
                  layer.techniques[techniqueId][tacticId].colour_grade - 1 //subtract technique colour grade across layers
              }
            }
          })
      }
    } else if (currentState.layers[1].mode === 3) {
      if (!currentTechnique[tacticId]) {
        currentTechnique[tacticId] = {}
        currentTechnique[tacticId].colour_grade = 5
      } else {
        if (currentTechnique[tacticId].colour_grade === 5) {
          delete currentTechnique[tacticId]
        } else {
          currentTechnique[tacticId].colour_grade = 5
        }
      }
    }

    if (Object.keys(techniques[techniqueId]).length === 0) {
      delete techniques[techniqueId]
    }

    const isTacticSelected = Object.values(activeLayer.techniques).some(
      (item) => {
        return !!item[tacticId]
      },
    )
    const isTechniqueSelected = Object.keys(activeLayer.techniques).some(
      (item) => {
        return item == techniqueId
      },
    )
    let addedTactics = activeLayer.tactics

    if (addedTactics.includes(tacticId) && !isTacticSelected) {
      activeLayer.tactics = addedTactics.filter((tactic) => tactic !== tacticId)
    } else if (!addedTactics.includes(tacticId) && isTacticSelected) {
      activeLayer.tactics = [...addedTactics, tacticId]
    }

    if (isTacticSelected) {
      currentState.tacticsNames = {
        ...currentState.tacticsNames,
        [tacticId]: tacticName,
      }
    } else {
      currentState.tacticsNames = handleDeleteTacticIfMissing(currentState)
    }

    if (isTechniqueSelected) {
      techniqueData.matrix_id = tacticCategory
      techniqueData.tactics_ids = [tacticId]
      currentState.techniquesNames = {
        ...currentState.techniquesNames,
        [techniqueId]: techniqueData,
      }
    } else {
      currentState.techniquesNames =
        handleDeleteTechniqueIfMissing(currentState)
    }

    handleCreateState('mitre_navigator', currentState, true)
  }

  const handleMapContentPiece = (pieceData, layerId) => {
    const currentState = { ...data }
    currentState.mapping[layerId].tactics = []
    currentState.mapping[layerId].techniques = {}
    currentState.tacticsNames = handleDeleteTacticIfMissing(currentState)
    currentState.techniquesNames = handleDeleteTechniqueIfMissing(currentState)
    currentState.mapping[layerId].tactics = pieceData.tacticsIds
    currentState.mapping[layerId].techniques = pieceData.techniques
    currentState.tacticsNames = merge(
      currentState.tacticsNames,
      pieceData.tacticsNames,
    )
    currentState.techniquesNames = merge(
      currentState.techniquesNames,
      pieceData.techniquesNames,
    )
    currentState.mapping = handleUpdateColourGradeAcrossLayers(currentState)
    handleCreateState('mitre_navigator', currentState, true)
  }

  const handleShowInfo = (event, description) => {
    event.stopPropagation()
    setDescriptionInfo(description)
  }

  const filteredResults = (data) => {
    return data.filter((item) => {
      let id = []
      item.sub_techniques.forEach((subitem) => {
        if (subitem?.name?.toLowerCase()?.includes(searchText)) {
          id = [subitem.parent_external_id]
        }
      })
      const includesPlatform =
        selectedPlatforms[tab] &&
        item.platforms.filter((e) => selectedPlatforms[tab].includes(e.id))
          .length > 0

      if (
        item?.external_id === id[0] ||
        item?.name?.toLowerCase()?.includes(searchText) ||
        item?.description?.toLowerCase()?.includes(searchText)
      ) {
        if (selectedPlatforms[tab] && selectedPlatforms[tab].length > 0) {
          if (includesPlatform) {
            return item
          } else {
            return false
          }
        } else {
          return item
        }
      } else return false
    })
  }

  const handleDragEnd = (event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = active.data.current.sortable.index
      const newIndex = over.data.current.sortable.index
      const newItems = arrayMove(items, oldIndex, newIndex)

      newItems.forEach((item, index) => (item.sort_id = index))
      setItems(newItems)
      handleMitreNavigatorState('matrices', newItems)
    } else {
      setIsDragging(false)
    }

    setTimeout(() => {
      setIsDragging(false)
    }, 50)
  }

  const returnShade = (techniqueId, tacticId) => {
    if (data.layers[1] && data.layers[1].mode == 2) {
      let result = []
      let visibleLayers = {}
      Object.entries(data.mapping).forEach((layer) => {
        //map visible layers
        const key = layer[0]
        const value = layer[1]
        const isVisible = data.layers.filter((item) => item.id == key)[0]
          .$isVisible
        if (isVisible) {
          visibleLayers = { ...visibleLayers, [key]: value }
        }
      })

      let maxNumberInVisibleLayer = []

      Object.values(visibleLayers).forEach((layer) => {
        //get the total number of techniques present across all layers
        if (layer.techniques[techniqueId]) {
          if (layer.techniques[techniqueId][tacticId]) {
            const currentGrade =
              layer.techniques[techniqueId][tacticId].colour_grade
            maxNumberInVisibleLayer = [...maxNumberInVisibleLayer, currentGrade]
          } else {
            return false
          }
        }
      })

      Object.values(visibleLayers).some((layer) => {
        //return colour grade based on max number of layers and techniques in them
        const activeLayer = data.layers.filter((i) => i.$isActive)[0]
        if (activeLayer && layer.techniques[techniqueId]) {
          if (layer.techniques[techniqueId][tacticId]) {
            const currentGrade =
              layer.techniques[techniqueId][tacticId].colour_grade
            const grade =
              currentGrade > maxNumberInVisibleLayer.length
                ? maxNumberInVisibleLayer.length
                : currentGrade
            result = [
              `mitre-colour--${activeLayer.colour || 1}`,
              `shade-${grade}`,
            ]
          } else {
            return ''
          }
        }
      })

      return result
    } else if (data.layers[1] && data.layers[1].mode == 3) {
      const activeAndVisible = data.layers.filter(
        (i) => i.$isActive && i.$isVisible,
      )
      let result = ''
      if (activeAndVisible[0] !== undefined) {
        const active = data.mapping[activeAndVisible[0].id]
        if (
          active &&
          active.techniques[techniqueId] &&
          active.techniques[techniqueId][tacticId]
        ) {
          const grade = active.techniques[techniqueId][tacticId].colour_grade
          const colour = activeAndVisible[0].colour
          result = [
            `mitre-colour--${colour}`,
            `shade-${grade}`,
            'mitre-compare',
          ]
        }
      } else {
        result = ['', '']
      }
      return result
    } else {
      const activeLayerId = data.layers.filter(
        (i) => i.$isActive && i.$isVisible,
      )[0]

      if (!activeLayerId) {
        return ['', '', '']
      } else {
        const activeLayer = data.mapping[activeLayerId.id]
        if (
          activeLayer &&
          activeLayer.techniques &&
          activeLayer.techniques[techniqueId] &&
          activeLayer.techniques[techniqueId][tacticId]
        ) {
          const colour = data.layers.filter((i) => i.$isActive)[0].colour
          const grade =
            activeLayer.techniques[techniqueId][tacticId].colour_grade
          return [`mitre-colour--${colour}`, `shade-${grade}`]
        } else {
          return ''
        }
      }
    }
  }

  const returnActive = (techniqueId, tacticId) => {
    if (data.layers.filter((i) => i.$isActive && i.$isVisible)[0]) {
      const activeAndVisible = data.layers.filter(
        (i) => i.$isActive && i.$isVisible,
      )[0].id
      const active = data.mapping[activeAndVisible]
      if (
        active &&
        active.techniques &&
        active.techniques[techniqueId] &&
        active.techniques[techniqueId][tacticId]
      ) {
        return active.techniques[techniqueId][tacticId].colour_grade
      }
    } else {
      return ''
    }
  }

  const returnCompareBoxes = () => {
    if (data.layers[1] && data.layers[1].mode == 3) {
      let possibleLayers = {}
      Object.entries(data.mapping).forEach((layer) => {
        //map visible layers
        const key = layer[0]
        const value = layer[1]
        const isVisible = data.layers.filter((item) => item.id == key)[0]
          .$isVisible
        const isActive = data.layers.filter((item) => item.id == key)[0]
          .$isActive
        if (isVisible && !isActive) {
          possibleLayers = { ...possibleLayers, [key]: value }
        }
      })
      return possibleLayers
    } else return false
  }

  const isInAnyLayer = (techniqueId, tacticId) => {
    let exists = false
    Object.values(data.mapping).some((i) => {
      if (i.techniques[techniqueId] && i.techniques[techniqueId][tacticId]) {
        exists = true
      }
    })

    return exists
  }

  const handleExportMitre = async () => {
    try {
      const response = await exportMitre(token(), 'scenario', pieceId)

      if (response.error) return false
      const url = `${response}?access_token=${token()}`
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    } catch {}
  }

  return isLoading ? (
    <Spinner size={30} centerHorizontally />
  ) : (
    <div
      style={{ width: view ? '100%' : 'unset' }}
      className={`mitre-navigator${fullscreenMode ? ' fullscreen' : ''}${
        view ? ' mode-view' : ''
      }`}
    >
      {!view && (
        <Layers
          data={data.layers || []}
          handleMitreNavigatorState={handleMitreNavigatorState}
          handleMitreLayer={handleMitreLayer}
          availableColours={availableColours()}
          isAdding={isAdding}
          handleMapContentPiece={handleMapContentPiece}
        />
      )}

      {view && <ViewLayers data={data} updateMitre={updateMitre} />}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={(event) => !view && handleDragEnd(event)}
            onDragStart={() => !view && setIsDragging(true)}
          >
            <SortableContext items={items} strategy={rectSortingStrategy}>
              {items.map((item) => (
                <SortableTab
                  key={item.id}
                  id={item.id}
                  label={item.name}
                  tab={tab}
                  setTab={setTab}
                  isDragging={isDragging}
                  platforms={item.platforms}
                  setSelectedPlatforms={setSelectedPlatforms}
                  selectedPlatforms={selectedPlatforms}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>

        <div
          style={{
            width: '40%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginRight: fullscreenMode ? 6 : 0,
          }}
        >
          <TextField
            variant='outlined'
            // defaultValue={data.search ? data.search : ''}
            style={{ width: 'calc(100% - 50px)' }}
            inputProps={{ style: { padding: '13px 14px' } }}
            onChange={(event) =>
              setSearchText(event.target.value.toLowerCase())
            }
            placeholder='Search'
          />

          {view && (
            <Tooltip title='Export MITRE to xls'>
              <IconButton
                onClick={() => handleExportMitre()}
                style={{ padding: 10, margin: '0 2px' }}
              >
                <Download style={{ color: '#006fff' }} />
              </IconButton>
            </Tooltip>
          )}

          {!fullscreenMode && (
            <Tooltip title='Enter fullscreen mode'>
              <IconButton
                style={{ padding: 5 }}
                onClick={() => setFullscreenMode(true)}
                color='primary'
              >
                <Fullscreen style={{ fontSize: 34 }} />
              </IconButton>
            </Tooltip>
          )}

          {fullscreenMode && (
            <Tooltip title='Exit fullscreen mode'>
              <IconButton
                style={{ padding: 5 }}
                onClick={() => setFullscreenMode(false)}
                color='primary'
                size='medium'
              >
                <FullscreenExit style={{ fontSize: 34 }} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>

      {descriptionInfo !== undefined && (
        <DescriptionInfo
          description={descriptionInfo}
          setDescriptionInfo={setDescriptionInfo}
        />
      )}

      <div
        style={{
          overflow: 'auto',
          maxHeight: fullscreenMode ? '100vh' : '50vh',
          border: '1px solid #999',
        }}
      >
        <div style={{ display: 'flex' }}>
          {mitre.navigator.map((stage) => {
            const tacticCount = stage.tactics.map(
              (tactic) => tactic.category_id === tab,
            )
            const isTrue = (item) => item === true
            if (tacticCount.some(isTrue)) {
              return (
                <div className='navigator-stage' key={stage.id}>
                  <h4 className='navigator-table__stage-label'>{stage.name}</h4>

                  {stage.tactics.length && (
                    <div
                      style={{ display: 'flex' }}
                      className='navigator-table__stage-tactics'
                    >
                      {stage.tactics.map(
                        (category) =>
                          category.category_id === tab && (
                            <div className='navigator-tactic' key={category.id}>
                              <div
                                style={{
                                  position: 'sticky',
                                  top: 44,
                                  backgroundColor: '#fff',
                                  padding: '14px 8px 8px 8px',
                                  zIndex: 9,
                                }}
                              >
                                <h5 style={{ marginBottom: 10 }}>
                                  {category.name}
                                </h5>
                              </div>

                              <div style={{ marginTop: 10 }}>
                                <MitreTechnique
                                  items={filteredResults(category.techniques)}
                                  isInAnyLayer={isInAnyLayer}
                                  returnActive={returnActive}
                                  returnShade={returnShade}
                                  layers={data.layers}
                                  category={category}
                                  returnCompareBoxes={returnCompareBoxes}
                                  handleAddTag={handleAddTag}
                                  handleShowInfo={handleShowInfo}
                                  handleExpanded={handleExpanded}
                                  stageId={stage.id}
                                  view={view}
                                />
                              </div>
                            </div>
                          ),
                      )}
                    </div>
                  )}
                </div>
              )
            }
          })}
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <span style={{ fontSize: 11 }}>
          © 2024 The MITRE Corporation. This work is reproduced and distributed
          with the permission of The MITRE Corporation.
        </span>
      </div>
    </div>
  )
}

export default MitreNavigator
