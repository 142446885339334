import { useQuery } from '@tanstack/react-query'
import { getDashboard } from '../../api/dashboard/dashboard'
import { useUserContext } from '../../../../context/userContext'
export const useQueryGetDashboardData = () => {
  const { isIdle } = useUserContext()
  return useQuery({
    queryKey: ['dashboard'],
    queryFn: ({ signal }) => getDashboard(signal),
    keepPreviousData: true,
    refetchInterval: 15 * 60 * 1000,
    enabled: !isIdle,
  })
}
