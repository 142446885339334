/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import { IconButton } from '@material-ui/core'
import React, { memo, useRef, useState } from 'react'
import { formatDate } from '../../../../../../shared/helpers/formatDate'
import MitreTags from '../../../../../CreateContent/shared/Components/MitreTags'
import {
  getPossibleSections,
  handleAddDates,
  handleMapTtp,
  mapEntriesToSections,
} from './fn'
import Lightbox from 'react-spring-lightbox'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import MitreNavigator from '../../../../../CreateContent/shared/Components/MitreNavigator/MitreNavigator'
import TtpComponent from '../../../../../CreateContent/ContentTypes/Scenario/TtpComponent'
import { apiUrl } from '../../../../../../shared/config/index'
import DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import UserLeaving from './UserLeaving'
import { uniq } from 'lodash'

const Content = ({
  data,
  configuration,
  type,
  isPreview,
  updateMitre,
  highlightPhrase,
}) => {
  const [isUserLeaving, setIsUserLeaving] = useState(undefined)
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [images, setImages] = useState([])
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [galleryOpen, setGalleryOpen] = useState(false)
  const [ttpOpen, setTtpOpen] = useState([])
  const sectionRef = useRef(null)
  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1)
  const gotoNext = () =>
    currentImageIndex + 1 < images.length &&
    setCurrentImageIndex(currentImageIndex + 1)

  setTimeout(() => {
    sectionRef.current &&
      [...sectionRef.current.getElementsByTagName('a')].forEach((a) => {
        a.addEventListener('click', (event) => {
          event.preventDefault()
          const url = event?.target?.href || event?.target?.closest('a')?.href
          setIsUserLeaving(url)
          return false
        })
      })
  })

  if (!(data?.dev_content || data?.content)) return false

  let unparsedData = JSON.stringify(data?.dev_content || data?.content)

  uniq(highlightPhrase)?.forEach((string) => {
    const wrapped =
      ' ' + string?.replace(string, "<span class='item-highlight'>$&</span>")
    unparsedData = unparsedData?.replaceAll(string, wrapped)
  })

  const highlightedContent = JSON.parse(unparsedData)

  configuration?.forEach((section) => {
    if (section?.columns?.gallery) {
      section.columns['gallery-imagesPerRow'] = {}
    }
    return section
  })

  Object.entries(data).map((section) => {
    const key = section[0].split('_')
    const value = section[1]
    const deleteDev = () => {
      const newKey = key.slice(1).join('_')
      return { [newKey]: value }
    }

    if (key[0] === 'dev') {
      data = { ...data, ...deleteDev() }
      delete data[section[0]]
    }
  })

  const SingleContentSection = memo(() => {
    const possibleSections = getPossibleSections(
      configuration,
      highlightedContent,
    )?.filter((item) => item !== undefined)
    let sections = {}
    sections = mapEntriesToSections(sections, possibleSections)
    sections = handleMapTtp(sections, configuration, data.mitre_navigator_data)
    if (data.typeSlug !== 'malware-tools' && data.typeSlug !== 'operation') {
      sections = handleAddDates(
        sections,
        data.additional_dates,
        type,
        highlightedContent?.dates_date_assessment,
      )
    }

    configuration?.forEach((item) => {
      if (item?.headline?.split(' ')?.at(-1) === '(required)') {
        item.headline = item?.headline?.split(' ')?.slice(0, -1).join(' ')
      }

      if (sections[item?.headline] && item.viewer_order) {
        sections[item?.headline].viewer_order = item.viewer_order
      }
    })

    const sectionsSorted = Object.keys(sections)
      .sort((a, b) => {
        return sections[a].viewer_order - sections[b].viewer_order
      })
      .map((key) => {
        return { [key]: sections[key] }
      })

    return sectionsSorted.map((section) => {
      const key = Object.keys(section)
      let value = Object.values(section)[0]

      return (
        <div
          key={key}
          className={`section-wrapper${value.ttp ? ' section-ttp' : ''}${
            key[0] === 'Resources & Proficiency' || key[0] === 'Malware'
              ? ' section-resources-proficiency'
              : ''
          }`}
        >
          <h3>{key}</h3>

          {value.additional ? (
            <div
              className='content'
              style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}
            >
              {parse(DOMPurify.sanitize(value.additional))}
            </div>
          ) : null}

          {value.text ? (
            <div className='content'>
              {parse(DOMPurify.sanitize(value.text))}
            </div>
          ) : null}

          {value.ttp ? (
            <TtpComponent
              mitreData={data.mitre_navigator_data}
              tacticsIds={value.ttp}
              ttpOpen={ttpOpen}
              setTtpOpen={setTtpOpen}
              index={key[0]}
            />
          ) : null}

          {value.free ? (
            <div className='section-wrapper__subsections'>
              {value.free.map((freeSection, index) => (
                <div
                  key={index}
                  className='section-wrapper__subsections-item'
                  style={{ margin: '10px 0' }}
                >
                  {freeSection.type === 'text' && (
                    <>
                      <h3
                        style={{
                          marginBottom: 10,
                          textAlign: 'left',
                          fontSize: 18,
                        }}
                      >
                        {freeSection?.element?.headline || ''}
                      </h3>

                      <div className='content'>
                        {parse(DOMPurify.sanitize(freeSection?.element?.text))}
                      </div>
                    </>
                  )}

                  {freeSection.type === 'gallery' && (
                    <>
                      <h3 style={{ marginBottom: 10, textAlign: 'left' }}>
                        {freeSection?.element?.headline || ''}
                      </h3>

                      <div className='content-gallery'>
                        {freeSection?.element?.gallery?.map((image, index) => (
                          <div
                            className={`gallery-${
                              freeSection.element['gallery-imagesPerRow'] || 1
                            }-items-per-row`}
                            key={`${image.url}-${index}`}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <img
                                onClick={() => {
                                  let gallery = []
                                  freeSection?.element?.gallery?.forEach(
                                    (item) => {
                                      const imageUrl = `${apiUrl}/storage/module/content/pages/${
                                        item.url
                                      }?access_token=${token()}`
                                      gallery = [...gallery, { src: imageUrl }]
                                    },
                                  )
                                  setCurrentImageIndex(index)
                                  setImages(gallery)
                                  setGalleryOpen(true)
                                }}
                                src={`${apiUrl}/storage/module/content/pages/${
                                  image.url
                                }?access_token=${token()}`}
                                alt={image.url}
                              />

                              <span style={{ fontStyle: 'italic' }}>
                                {image.caption}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          ) : null}

          {value.gallery && value['gallery-imagesPerRow'] ? (
            <div className='content-gallery'>
              {value.gallery.map((image, index) => (
                <div
                  className={`gallery-${
                    value['gallery-imagesPerRow'] || 1
                  }-items-per-row`}
                  key={`${image.url}-${index}`}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      onClick={() => {
                        let gallery = []
                        value?.gallery?.forEach((item) => {
                          const imageUrl = `${apiUrl}/storage/module/content/pages/${
                            item.url
                          }?access_token=${token()}`
                          gallery = [...gallery, { src: imageUrl }]
                        })
                        setCurrentImageIndex(index)
                        setImages(gallery)
                        setGalleryOpen(true)
                      }}
                      src={`${apiUrl}/storage/module/content/pages/${
                        image.url
                      }?access_token=${token()}`}
                      alt={image.url}
                    />

                    <span style={{ fontStyle: 'italic' }}>{image.caption}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : null}

          {value.gallery && !value['gallery-imagesPerRow'] && !galleryOpen ? (
            <div className='content-gallery'>
              {value.gallery.map((image, index) => (
                <div
                  key={`${image.url}-${index}`}
                  className='gallery-1-items-per-row'
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      onClick={() => {
                        let gallery = []
                        value?.gallery?.forEach((item) => {
                          const imageUrl = `${apiUrl}/storage/module/content/pages/${
                            item.url
                          }?access_token=${token()}`
                          gallery = [...gallery, { src: imageUrl }]
                        })
                        setCurrentImageIndex(index)
                        setImages(gallery)
                        setGalleryOpen(true)
                      }}
                      src={`${apiUrl}/storage/module/content/pages/${
                        image.url
                      }?access_token=${token()}`}
                      alt={image.url}
                    />

                    <span style={{ fontStyle: 'italic' }}>{image.caption}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : null}

          {value.active_since ? (
            <div className='content'>
              Active since: {formatDate(value.active_since)}
            </div>
          ) : null}

          {value.event_date ? (
            <div className='content'>
              Event date: {formatDate(value.event_date)}
            </div>
          ) : null}

          {value.discovery_date ? (
            <div className='content'>
              Discovery date: {formatDate(value.discovery_date)}
            </div>
          ) : null}

          {value.connectors ? (
            <div className='content'>
              {parse(value.connectors.map((tag) => tag.name).join(', '))}
            </div>
          ) : null}

          {value.sector ? (
            <div className='content'>
              {parse(DOMPurify.sanitize(value.sector))}
            </div>
          ) : null}

          {key[0] === 'Resources & Proficiency' && (
            <div>
              {value.proficiency ? (
                <div style={{ width: 'calc(50% - 10px)' }}>
                  <h5>Proficiency</h5>

                  <div className='content'>
                    {parse(DOMPurify.sanitize(value.proficiency))}
                  </div>
                </div>
              ) : null}

              {value.resources ? (
                <div style={{ width: 'calc(50% - 10px)' }}>
                  <h5>Resources</h5>

                  <div className='content'>
                    {parse(DOMPurify.sanitize(value.resources))}
                  </div>
                </div>
              ) : null}
            </div>
          )}

          {key[0] === 'Known period of activity' && value.date ? (
            <div style={{ marginBottom: 20 }} className='content'>
              {parse(DOMPurify.sanitize(value.date))}
            </div>
          ) : null}

          {value.start && !value.start_date ? (
            <div className='content'>Start date: {formatDate(value.start)}</div>
          ) : null}

          {value.start_date ? (
            <div className='content'>
              Start date: {formatDate(value.start_date)}
            </div>
          ) : null}

          {value.end && !value.end_date ? (
            <div className='content'>End date: {formatDate(value.end)}</div>
          ) : null}

          {value.end_date ? (
            <div className='content'>
              End date: {formatDate(value.end_date)}
            </div>
          ) : null}

          {value.priority ? (
            <div className='content'>
              Capability update date: {formatDate(value.priority)}
            </div>
          ) : null}

          {key[0] === 'Malware' && (
            <div>
              {value.family ? (
                <div style={{ width: 'calc(50% - 10px)' }}>
                  <h5>Malware family</h5>

                  <div className='content'>
                    {parse(DOMPurify.sanitize(value.family))}
                  </div>
                </div>
              ) : null}

              {value.type ? (
                <div style={{ width: 'calc(50% - 10px)' }}>
                  <h5>Malware type</h5>

                  <div className='content'>
                    {parse(DOMPurify.sanitize(value.type))}
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      )
    })
  })

  return (
    <div
      ref={sectionRef}
      className='single-piece__main'
      style={{ width: '95%', margin: '0 auto' }}
    >
      {isUserLeaving ? (
        <UserLeaving
          isUserLeaving={isUserLeaving}
          setIsUserLeaving={setIsUserLeaving}
        />
      ) : null}

      {galleryOpen ? (
        <Lightbox
          onClose={() => {}}
          isOpen={galleryOpen}
          onPrev={gotoPrevious}
          onNext={gotoNext}
          images={images}
          renderHeader={() => (
            <div className='lightbox-close'>
              <IconButton onClick={() => setGalleryOpen(false)} size='medium'>
                <span style={{ color: '#fff' }} className='icon-close' />
              </IconButton>
            </div>
          )}
          currentIndex={currentImageIndex}
          renderPrevButton={() => (
            <div
              onClick={gotoPrevious}
              style={{ color: currentImageIndex === 0 ? '#595959' : '#fff' }}
              className='image-prev'
            >
              <ArrowBackIos
                style={{ padding: 12, height: '100%', width: 34 }}
              />
            </div>
          )}
          renderNextButton={() => (
            <div
              onClick={gotoNext}
              style={{
                color:
                  currentImageIndex === images.length - 1 ? '#595959' : '#fff',
              }}
              className='image-next'
            >
              <ArrowForwardIos
                style={{ padding: 12, height: '100%', width: 34 }}
              />
            </div>
          )}
          pageTransitionConfig={{
            from: { transform: 'scale(1)', opacity: 1 },
            enter: { transform: 'scale(1)', opacity: 1 },
            leave: { transform: 'scale(1)', opacity: 1 },
            config: { mass: 1, tension: 320, friction: 32 },
          }}
          // renderHeader={() => (<CustomHeader />)}
          // renderFooter={() => (<CustomFooter />)}
        />
      ) : null}

      {data.update_summary && data.update_summary.length > 0 ? (
        <div>
          <h3>Update summary</h3>

          <div className='content'>
            {parse(DOMPurify.sanitize(data.update_summary))}
          </div>
        </div>
      ) : null}

      <SingleContentSection />

      {data.tag_tactics && Object.keys(data.tag_tactics).length > 0 ? (
        <div style={{ width: '100%' }}>
          <h3>MITRE ATT&CK</h3>

          <MitreTags
            tagTactics={data.tag_tactics || []}
            devContent={data.content}
            tags={data.tags || []}
            devTags={data.tags_dev || []}
            view
            tagsFull={data.tags || []}
            type={isPreview ? type.slice(0, -1) : type}
            contentId={data.id}
          />
        </div>
      ) : null}

      {(type === 'scenario' || type === 'scenarios') &&
      data?.mitre_navigator_data?.techniquesNames &&
      Object.keys(data.mitre_navigator_data.techniquesNames).length > 0 ? (
        <div style={{ width: '100%' }}>
          <h3>MITRE Navigator</h3>

          <MitreNavigator
            data={data.mitre_navigator_data || {}}
            frameworkId={data.content_scenario_framework_id}
            pieceId={data.id}
            updateMitre={updateMitre}
            view
          />
        </div>
      ) : null}
    </div>
  )
}

export default memo(Content)
