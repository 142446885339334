import { useEffect, useState } from 'react'
import './Dashboard.scss'
import PropTypes from 'prop-types'
import Chart from './Chart'
import { Tab, Tabs } from '@material-ui/core'
import TotalsTab from './TotalsTab'
import TagsTab from './TagsTab/'
import DistributionTab from './DistributionTab/DistributionTab'
import LatestTab from './LatestTab/LatestTab'
import Spinner from '../../shared/Spinner'
import { isMobile } from 'react-device-detect'
import { useGlobalContext } from '../../context/globalContext'
import { useQueryGetDashboardData } from '../../shared/services/queries/dashboard/useQueryGetDashboardData'

/**
 * Dashboard
 * @param {object} dashboardData - dashboard data
 * @param {object} setDashboardData - setting dashboard data
 */

const distributionTabs = [
  { name: 'All', value: 'all' },
  { name: 'Global', value: 'global' },
  { name: 'Sector', value: 'sector' },
  { name: 'Organisation', value: 'organisation' },
  { name: 'Community', value: 'community' },
]

const Dashboard = ({ configuration }) => {
  const { data: dashboardData, isLoading } = useQueryGetDashboardData()

  const { isContentBeingEdited } = useGlobalContext()

  const [tabState, setTabState] = useState({
    alertTab: 7,
    totalTab: 7,
    distributionTab: 'all',
    tagsTab: 0,
    latestTab: 'all',
  })

  useEffect(() => {
    isContentBeingEdited[1] &&
      localStorage.setItem('isContentEdited', isContentBeingEdited[1])
  }, [])

  const handleTabState = (tab, tabValue) => {
    const currentState = { ...tabState }
    currentState[tab] = tabValue
    setTabState(currentState)
  }

  const tagsData = () => {
    const { latest_tags_and_ttps } = dashboardData?.message?.isCanceled
      ? {}
      : dashboardData ?? {}
    if (tabState.tagsTab === 0) {
      return latest_tags_and_ttps
    } else if (tabState.tagsTab === 1) {
      return latest_tags_and_ttps.filter((i) => i.mitre_technique_id === null)
    } else if (tabState.tagsTab === 2) {
      return latest_tags_and_ttps.filter((i) => i.mitre_technique_id !== null)
    }
  }

  return (
    <div
      className='dashboard'
      style={{
        padding: isMobile ? 10 : 20,
        margin: isMobile ? 10 : null,
        height: isMobile ? 'calc(100% - 40px)' : null,
        width: isMobile ? 'calc(100% - 40px)' : null,
      }}
    >
      {!isMobile && (
        <div className='content-left'>
          <div className='chart-wrapper'>
            <div className='chart-container'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h4>Threat alerts</h4>

                <Tabs
                  value={tabState.alertTab}
                  onChange={(event, newValue) =>
                    handleTabState('alertTab', newValue)
                  }
                  className='alert-tabs'
                  indicatorColor='primary'
                >
                  <Tab label='7 days' value={7} />

                  <Tab label='30 days' value={30} />

                  <Tab label='All' value='all' />
                </Tabs>
              </div>

              {isLoading ? (
                <Spinner center size={30} border={1} />
              ) : (
                <Chart
                  data={
                    dashboardData?.message?.isCanceled
                      ? {}
                      : dashboardData?.threat_alerts[tabState.alertTab]
                  }
                  tab={tabState.alertTab}
                />
              )}
            </div>
          </div>

          <div className='chart-wrapper'>
            <div
              style={{ justifyContent: 'unset' }}
              className='chart-container'
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 8,
                }}
              >
                <h4>Top alerts by severity</h4>

                <Tabs
                  value={tabState.totalTab}
                  onChange={(event, newValue) =>
                    handleTabState('totalTab', newValue)
                  }
                  className='alert-tabs'
                  indicatorColor='primary'
                >
                  <Tab value={7} label='7 days' />

                  <Tab value={30} label='30 days' />
                </Tabs>
              </div>

              {isLoading ? (
                <Spinner center size={30} border={1} />
              ) : (
                <TotalsTab
                  alertTypes={configuration?.alert?.types}
                  totals={
                    dashboardData?.message?.isCanceled
                      ? {}
                      : dashboardData?.totals_by_severity[tabState.totalTab]
                  }
                  tab={tabState.totalTab}
                />
              )}
            </div>
          </div>

          <div className='chart-wrapper'>
            <div
              style={{ justifyContent: 'unset' }}
              className='chart-container'
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 20,
                }}
              >
                <h4>
                  Latest tags and TTPs{' '}
                  <span style={{ fontWeight: 400, fontSize: 12 }}>
                    (30 latest)
                  </span>
                </h4>

                <Tabs
                  value={tabState.tagsTab}
                  onChange={(_, newValue) =>
                    handleTabState('tagsTab', newValue)
                  }
                  className='alert-tabs'
                  indicatorColor='primary'
                >
                  <Tab label='All' />

                  <Tab label='Tags' />

                  <Tab label='TTPs' />
                </Tabs>
              </div>

              {isLoading ? (
                <Spinner center size={30} border={1} />
              ) : (
                <TagsTab data={tagsData()} />
              )}
            </div>
          </div>

          <div className='chart-wrapper'>
            <div
              style={{ justifyContent: 'unset' }}
              className='chart-container'
            >
              <h4 style={{ marginBottom: 5 }}>Content (30 days)</h4>

              <Tabs
                value={tabState.distributionTab}
                onChange={(event, newValue) =>
                  handleTabState('distributionTab', newValue)
                }
                className='distribution-tabs'
                indicatorColor='primary'
              >
                {distributionTabs.map((item) => (
                  <Tab key={item.value} label={item.name} value={item.value} />
                ))}
              </Tabs>

              {isLoading ? (
                <Spinner center size={30} border={1} />
              ) : (
                <DistributionTab
                  tab={tabState.distributionTab}
                  data={
                    dashboardData?.message?.isCanceled
                      ? {}
                      : dashboardData?.distribution[tabState.distributionTab] ||
                        {}
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div
        className='content-right'
        style={{
          width: isMobile ? '100%' : null,
          marginLeft: isMobile ? 0 : null,
        }}
      >
        <div className='chart-container'>
          <div
            className='chart-container__header'
            style={{ flexDirection: isMobile ? 'column' : null }}
          >
            <h4>Latest content (30 days)</h4>

            <Tabs
              value={tabState.latestTab}
              onChange={(event, newValue) =>
                handleTabState('latestTab', newValue)
              }
              className='latest-tabs'
              indicatorColor='primary'
            >
              {distributionTabs.map((item) => (
                <Tab
                  key={item.value}
                  label={item.name}
                  value={item.value}
                  disableRipple
                />
              ))}
            </Tabs>
          </div>

          {isLoading ? (
            <Spinner center size={30} border={1} />
          ) : (
            <LatestTab
              data={
                tabState.latestTab === 'all'
                  ? dashboardData?.message?.isCanceled
                    ? {}
                    : dashboardData?.latest_content
                  : dashboardData?.message?.isCanceled
                  ? {}
                  : dashboardData?.latest_content.filter(
                      (i) => i.relevance === tabState.latestTab,
                    )
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}

Dashboard.displayName = 'Dashboard'

/**
 * The properties.
 * @type {Object}
 */

Dashboard.propTypes = {
  configuration: PropTypes.instanceOf(Object).isRequired,
}

export default Dashboard
