import React, { createContext, useContext, useState } from 'react'
import { globalStateHandler } from '../pages/Home/helpers'

const initialState = {
  alerts: {
    list: [],
    readItems: [],
  },
  'threat-actors': {
    list: [],
    readItems: [],
  },
  incidents: {
    list: [],
    readItems: [],
  },
  operations: {
    list: [],
    readItems: [],
  },
  'malware-tools': {
    list: [],
    readItems: [],
  },
  scenarios: {
    list: [],
    readItems: [],
  },
  reports: {
    list: [],
    readItems: [],
  },
}

export const GlobalContext = createContext({
  globalState: initialState,
  setGlobalState: () => {},
  handleGlobalState: () => {},
  isContentBeingEdited: false,
  setIsContentBeingEdited: () => {},
  toastMessage: null,
  setToastMessage: () => {},
  confirmAutosaveModal: null,
  setConfirmAutosaveModal: () => {},
  globalSearchFilters: {},
  setGlobalSearchFilters: () => {},
  globalContentFilters: {},
  setGlobalContentFilters: () => {},
  isModalOpen: false,
  setIsModalOpen: () => {},
  filterOptions: null,
  setFilterOptions: () => {},
})

// eslint-disable-next-line react/prop-types
export const GlobalContextProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState(initialState)
  const [toastMessage, setToastMessage] = useState(null)
  const [isContentBeingEdited, setIsContentBeingEdited] = useState(false)
  const [confirmAutosaveModal, setConfirmAutosaveModal] = useState(null)
  const [globalSearchFilters, setGlobalSearchFilters] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [globalContentFilters, setGlobalContentFilters] = useState({
    currentTab: 'all',
  })
  const [filterOptions, setFilterOptions] = useState(null)

  const handleGlobalState = async (
    moduleName,
    data,
    shouldOverwrite,
    shouldDeletePiece,
  ) => {
    if (data === 'list') return false
    const currentState = { ...globalState }
    const newState = await globalStateHandler(
      currentState,
      data,
      moduleName,
      shouldOverwrite,
      shouldDeletePiece,
    )

    newState &&
      setGlobalState((current) => {
        return { ...current, [moduleName]: { readItems: newState } }
      })
  }

  const props = {
    globalState,
    setGlobalState,
    handleGlobalState,
    isContentBeingEdited,
    setIsContentBeingEdited,
    toastMessage,
    setToastMessage,
    confirmAutosaveModal,
    setConfirmAutosaveModal,
    globalSearchFilters,
    setGlobalSearchFilters,
    isModalOpen,
    setIsModalOpen,
    globalContentFilters,
    setGlobalContentFilters,
    filterOptions,
    setFilterOptions,
  }
  const Provider = GlobalContext.Provider

  return <Provider value={props}>{children}</Provider>
}

export const useGlobalContext = () => {
  return useContext(GlobalContext)
}
