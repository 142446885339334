/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react'
import HistorySearchModalItem from './HistorySearchModaltem'
import { IconButton, Tooltip } from '@material-ui/core'
import { KeyboardArrowDown, OpenInNew } from '@mui/icons-material'
import { useGlobalContext } from '../../../context/globalContext'
import { useUserContext } from '../../../context/userContext'
import { isEmpty, isNil, omitBy } from 'lodash'

const HistorySearchItemWrapper = ({
  item,
  index,
  setGlobalSearchFilters,
  setHistoryOpen,
  currentTab,
  getSearchedResult,
}: any) => {
  const { filterOptions } = useGlobalContext()
  const { user } = useUserContext()
  const [expandedRows, setExpandedRows] = useState<any>([])
  const [isOverflowed, setIsOverflow] = useState<boolean>(false)
  const [hoveredRows, setHoveredRows] = useState<any>([])
  const textElementRef = useRef<HTMLSpanElement | null>(null)
  useEffect(() => {
    if (
      textElementRef?.current?.scrollWidth &&
      textElementRef?.current?.clientWidth
    ) {
      setIsOverflow(
        textElementRef?.current?.scrollWidth >
          textElementRef?.current?.clientWidth,
      )
    }
  }, [])

  const redoSearch = () => {
    const filters: any = {
      dateRange: item?.published_data_range,
      priority: item?.priorities,
      keywords: item.keyword,
      tags_target_geography:
        filterOptions &&
        item?.tags_target_geography
          ?.map(
            (s: any) =>
              (filterOptions['profile']['target-geography'] as any)?.find(
                (e: any) => e?.id === s,
              ),
          )
          ?.filter((c: any) => c),
      tags_motivation:
        filterOptions &&
        item?.tags_motivation
          ?.map(
            (s: any) =>
              (filterOptions['profile']['motivation'] as any)?.find(
                (e: any) => e?.id === s,
              ),
          )
          ?.filter((c: any) => c),
      tags_language:
        filterOptions &&
        item?.tags_language
          ?.map(
            (s: any) =>
              (filterOptions['profile']['language'] as any)?.find(
                (e: any) => e?.id === s,
              ),
          )
          ?.filter((c: any) => c),
      tags_intended_effect:
        filterOptions &&
        item?.tags_intended_effect
          ?.map(
            (s: any) =>
              (filterOptions['profile']['intended-effect'] as any)?.find(
                (e: any) => e?.id === s,
              ),
          )
          ?.filter((c: any) => c),
      types:
        filterOptions &&
        item?.content_type &&
        item?.content_subtype
          ?.map(
            (s: any) =>
              (filterOptions[item?.content_type]['types'] as any)?.find(
                (e: any) =>
                  (item?.content_type === 'scenario' ? e?.id : e?.slug) === s,
              ),
          )
          ?.filter((c: any) => c),
      frameworks:
        filterOptions &&
        item?.scenario_framework_id
          ?.map(
            (s: any) =>
              (filterOptions['scenario']['frameworks'] as any)?.find(
                (e: any) => e?.id === s,
              ),
          )
          ?.filter((c: any) => c),
      relevance: item?.relevance,
      groups:
        user &&
        item?.group_id
          ?.map(
            (s: any) =>
              (
                user['user_access_groups_per_content'][
                  item?.content_type
                ] as any
              )?.find((e: any) => e?.id === s),
          )
          ?.filter((c: any) => c),
      sectors:
        filterOptions &&
        item?.content_type &&
        item?.sectors
          ?.map(
            (s: any) =>
              (filterOptions[item?.content_type]['sectors'] as any)?.find(
                (e: any) => e?.id === s,
              ),
          )
          ?.filter((c: any) => c),
    }

    if (item?.content_type === 'profile') {
      filters.currentTab = item?.content_subtype[0]
    } else if (!item?.content_type) {
      filters.currentTab = item?.has_ioc ? 'ioc' : 'all'
    } else {
      if (!item?.content_type) return false
      filters.currentTab = item?.content_type
    }

    if (item?.is_read) {
      filters.status = [...(filters.status || []), 'read']
    }
    if (item?.is_follow) {
      filters.status = [...(filters.status || []), 'flagged']
    }

    const final = omitBy(omitBy(filters, isNil), isEmpty)
    setGlobalSearchFilters(final)
    setHistoryOpen(false)
    const moduleType =
      item?.content_type === 'profile'
        ? item?.content_subtype[0]
        : item?.content_type
    if (currentTab === moduleType || (!moduleType && currentTab === 'all')) {
      getSearchedResult()
    }
  }

  return (
    <div
      onMouseEnter={() =>
        setHoveredRows((current: number[]) => [...current, index])
      }
      onMouseLeave={() =>
        setHoveredRows(
          (current: number[]) => current?.filter((i) => i !== index),
        )
      }
      onClick={(evt) => {
        const target = evt.target as HTMLElement
        if (target?.tagName === 'SPAN') {
          redoSearch()
        }
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: hoveredRows?.includes(index) ? 0 : 56,
      }}
    >
      <span
        ref={textElementRef}
        className='history-search-modal-item-div'
        style={{
          borderBottom: '1px solid #303030',
          display: 'block',
          whiteSpace: expandedRows?.includes(index) ? undefined : 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          cursor: 'pointer',
          padding: '6px 0 3px 0',
        }}
      >
        {Object.entries(item).map((i, ind) => (
          <HistorySearchModalItem
            key={ind}
            data={i}
            type={item?.content_type}
            isExpanded={expandedRows?.includes(index)}
          />
        ))}
      </span>
      {hoveredRows?.includes(index) ? (
        <>
          {isOverflowed ? (
            <Tooltip
              arrow
              title={
                expandedRows?.includes(index) ? 'Collapse line' : 'Expand line'
              }
            >
              <IconButton
                onClick={() =>
                  setExpandedRows((current: number[]) =>
                    expandedRows?.includes(index)
                      ? current?.filter((i) => i !== index)
                      : [...current, index],
                  )
                }
                style={{ padding: 3 }}
              >
                <KeyboardArrowDown
                  style={{
                    height: 20,
                    width: 22,
                    transform: expandedRows?.includes(index)
                      ? 'rotate(180deg)'
                      : 'rotate(0deg)',
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip arrow title='Redo search'>
            <IconButton onClick={() => redoSearch()} style={{ padding: 3 }}>
              <OpenInNew style={{ height: 20, width: 22 }} />
            </IconButton>
          </Tooltip>
        </>
      ) : null}
    </div>
  )
}

export default HistorySearchItemWrapper
