/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import { Fragment, useRef } from 'react'
import './Regraph.scss'
import { Chart, TimeBar, FontLoader } from 'regraph'
import {
  CircularProgress,
  TextField,
  Backdrop,
  IconButton,
  Snackbar,
  Typography,
  Button,
  InputAdornment,
  styled,
  Input,
  MenuItem,
} from '@material-ui/core'
import { Pagination, LinearProgress, useAutocomplete } from '@mui/material'
import Autocomplete from '@material-ui/lab/Autocomplete'
import SearchIcon from '@material-ui/icons/Search'
import NestedMenu from './components/NestedMenu'
import MuiAlert from '@material-ui/lab/Alert'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import NodeDetails from './components/NodeDetails/NodeDetails'
import Navigation from './components/Navigation'

import { layoutsList } from './layouts'
import './assets/style.css'
import BaseButton from '../../shared/components/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PublicSharpIcon from '@mui/icons-material/PublicSharp'
import ConfirmDelete from '../ContentManage/ConfirmDelete'
import { Map } from '@material-ui/icons'
import TimelineIcon from '@mui/icons-material/Timeline'
import Modal from './components/Modal'
import { useViewStateContext } from '../../context/viewStateContext'
import useRegraph from './useRegraph'
import { useChat } from '../../context/chatContext'
import SelectAuto from './components/SelectAuto/SelectAuto'

const COLUMNS = [
  { name: 'Name', width: '80%' },
  { name: 'Options', width: '20%' },
]

const Regraph = ({ view }) => {
  const {
    analysisList,
    analysisName,
    animationTime,
    apiMessage,
    centerCanvas,
    chartRef,
    changeGroupNameInput,
    combineSelection,
    combos,
    combineNodesHandler,
    contextMenuEvents,
    doRedo,
    doUndo,
    detailsShown,
    deleteModal,
    fetchList,
    generateImage,
    groupNamePositions,
    groupInputRef,
    handMode,
    handleAddNode,
    handleBetweenness,
    handleBlur,
    handleClick,
    handleChartChange,
    handleDegrees,
    handleDeleteDraft,
    handleDoubleClick,
    handleDragEnd,
    handleDragOver,
    handleDragStart,
    handleSnackbarClose,
    handleTimedOut,
    handlePageChange,
    handleResetFilter,
    isComboClosed,
    isDeleting,
    isFetching,
    isLinkAnalysisEqual,
    isModalOpen,
    isLoading,
    isTimedOut,
    isView,
    menu,
    page,
    saveGroupName,
    searchOpen,
    searchPhrase,
    selection,
    setAnalysisName,
    setAnimationTime,
    setDeleteModal,
    setDetailsShown,
    setLayout,
    setLoading,
    setIsView,
    setIsModalOpen,
    setSearchOpen,
    setSearchPhrase,
    setTimebarOpen,
    setTimeRange,
    suppressBrowserContextMenu,
    styledItems,
    nodesToAutocomplete,
    setNodesToAutocomplete,
    timebarOpen,
    timeoutMessage,
    timebarRef,
    toggleHandMode,
    openContextMenu,
    uncombineSelection,
    zoom,
    mapViewItems,
  } = useRegraph(view)
  const {
    setWorkspaces,
    workspaces,
    linkAnalysisState,
    setLinkAnalysisState,
    linkAnalysisOpen,
    setLinkAnalysisOpen,
    publishRegraph,
  } = useViewStateContext()
  const { chatOpen } = useChat()

  const coordinates = []

  Object.values(linkAnalysisState.currentChart.items).forEach((item) => {
    Array.isArray(item.coordinates) && coordinates.push(...item.coordinates)
  })

  const hasCoordinates = Object.values(
    linkAnalysisState?.currentChart?.items,
  )?.some(({ coordinates }) => coordinates?.length > 0)

  function openGoogleMapsWithAllMarkersVisible(locations) {
    if (locations.length === 0) {
      console.error('At least one location is required')
      return
    }

    // Start building the URL
    let url = 'https://www.google.com/maps/dir/'

    coordinates.forEach((coord, index) => {
      if (index > 0 && index !== coordinates.length - 1) url += '/'
      if (index === coordinates.length - 1) url += '//'
      url += `${coord.lat},${coord.lng}`
    })

    window.open(url, '_blank')
  }

  return (
    <div className='regraph'>
      <div className='regraph__main mode-light'>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={apiMessage.length > 0}
          onClose={handleSnackbarClose}
          autoHideDuration={2000}
        >
          <MuiAlert elevation={6} variant='filled' severity='success'>
            {apiMessage}
          </MuiAlert>
        </Snackbar>

        <Backdrop className='backdrop' open={isLoading}>
          <CircularProgress color='inherit' />

          {timeoutMessage && (
            <span style={{ color: '#fff' }}>{timeoutMessage}</span>
          )}

          {isTimedOut && (
            <Button
              onClick={handleTimedOut}
              style={{
                backgroundColor: '#ffffff',
                color: '#00000',
                marginTop: '10px',
                padding: '6px 20px',
                borderRadius: '2px',
              }}
            >
              Retry
            </Button>
          )}
        </Backdrop>

        <div
          className='regraph-container'
          style={{ width: '100%', position: 'relative', overflow: 'hidden' }}
          onContextMenu={suppressBrowserContextMenu}
          // onKeyDown={handleKeyDown}
          onClick={handleClick}
        >
          {searchOpen && (
            <BaseButton
              className='back-button'
              type='button'
              background='navy'
              onClick={() => {
                setIsView(true)
                setSearchOpen(false)
              }}
            >
              <ArrowBackIcon />

              <span>Back</span>
            </BaseButton>
          )}

          {!linkAnalysisOpen && isView && (
            <div style={{ width: '100%', height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginBottom: '20px',
                  borderBottom: '1px solid #0000004D',
                }}
              >
                <div
                  style={{
                    width: '30%',
                    maxWidth: '500px',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    borderRight: '1px solid #0000004D',
                    padding: '30px',
                  }}
                >
                  <h2>Link Analysis</h2>

                  <BaseButton
                    onClick={() => {
                      setIsView(false)
                      setSearchOpen(true)
                    }}
                  >
                    <span
                      className='icon-edit'
                      style={{ fontSize: '20px', marginRight: '4px' }}
                    />

                    <span>Create new analysis</span>
                  </BaseButton>
                </div>

                <form
                  style={{
                    width: '75%',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '30px',
                  }}
                  onSubmit={(event) => event.preventDefault()}
                >
                  <TextField
                    autoFocus
                    value={searchPhrase}
                    onChange={(event) => setSearchPhrase(event.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <span
                            className='icon-search'
                            style={{ color: '#283143', fontSize: 36 }}
                          />
                        </InputAdornment>
                      ),
                      // endAdornment: (
                      //   <Tooltip title='Clear' placement='left'>
                      //     <IconButton onClick={() => handleFilters('keywords', '')}>
                      //       <Clear style={{ color: '#fff' }} />
                      //     </IconButton>
                      //   </Tooltip>
                      // )
                    }}
                    placeholder='Search your saved analysis'
                    variant='standard'
                    fullWidth
                  />
                </form>
              </div>

              {deleteModal !== undefined && (
                <ConfirmDelete
                  confirmDelete={deleteModal}
                  setConfirmDelete={setDeleteModal}
                  handleDeletePiece={handleDeleteDraft}
                  isDeleting={isDeleting}
                />
              )}

              <table className='regraph-container__content-right-table'>
                <thead>
                  <tr>
                    {COLUMNS.map((item) => (
                      <th style={{ width: item.width }} key={item.name}>
                        {item.name}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {analysisList.isLoading && (
                    <tr>
                      <td colSpan={3}>
                        <LinearProgress />
                      </td>
                    </tr>
                  )}

                  {analysisList?.data?.list?.data?.map(
                    ({ id, title, statusName }) => (
                      <tr key={id}>
                        <td style={{ width: '80%' }}>{title}</td>

                        <td style={{ width: '20%' }}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: 20,
                            }}
                          >
                            <BaseButton
                              onClick={() => {
                                const currentWorkspace = workspaces.find(
                                  (workspace) => workspace.state === 'analysis',
                                )

                                currentWorkspace.analysisId = id
                                setWorkspaces(workspaces)
                                localStorage.setItem(
                                  'workspaces',
                                  JSON.stringify(workspaces),
                                )
                                setLinkAnalysisOpen(true)
                                setAnalysisName(title)
                              }}
                            >
                              <span
                                className='icon-edit'
                                style={{
                                  fontSize: '20px',
                                  marginRight: '3px',
                                }}
                              />

                              <span>Edit</span>
                            </BaseButton>

                            <BaseButton
                              background='red'
                              onClick={() => setDeleteModal(id)}
                            >
                              <span>Delete</span>
                            </BaseButton>
                          </div>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>

              <div
                style={{
                  position: 'absolute',
                  bottom: 24,
                  left: 150,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: 'calc(100% - 60px)',
                }}
              >
                <Pagination
                  onChange={(_, value) => handlePageChange(value)}
                  page={page}
                  color='primary'
                  size='small'
                  count={analysisList?.data?.list?.last_page || 0}
                  shape='rounded'
                  hideNextButton
                  hidePrevButton
                />

                <h5 style={{ color: '#fff' }}>Total results: </h5>
              </div>
            </div>
          )}

          {searchOpen && !linkAnalysisOpen && (
            <div
              className='input-container'
              style={{
                top: linkAnalysisOpen ? '150px' : '50%',
                left: detailsShown ? '40%' : '50%',
              }}
            >
              <Typography variant='h4' gutterBottom>
                Search content to start link analysis
              </Typography>

              <div className='autocomplete-container'>
                <div>
                  <SelectAuto
                    nodesToAutocomplete={nodesToAutocomplete}
                    fetchList={fetchList}
                    handleAddNode={handleAddNode}
                    isFetching={isFetching}
                    setNodesToAutocomplete={setNodesToAutocomplete}
                  />
                </div>

                <span style={{ fontSize: '10px', color: '#767676' }}>
                  Please select a piece of content
                </span>
              </div>
            </div>
          )}

          {linkAnalysisOpen && (
            <Fragment>
              {linkAnalysisState.isMapActive ? null : (
                <div className='navigation-container'>
                  <Navigation
                    layoutsList={layoutsList}
                    setLayout={setLayout}
                    setAnimationTime={setAnimationTime}
                    generateImage={generateImage}
                    handMode={handMode}
                    toggleHandMode={toggleHandMode}
                    handleDegrees={handleDegrees}
                    handleBetweenness={handleBetweenness}
                    zoom={zoom}
                    combineSelection={combineSelection}
                    uncombineSelection={uncombineSelection}
                    handleResetFilter={handleResetFilter}
                    doUndo={doUndo}
                    doRedo={doRedo}
                    state={linkAnalysisState}
                    setIsModalOpen={setIsModalOpen}
                    isLinkAnalysisEqual={isLinkAnalysisEqual()}
                    setAnalysisName={setAnalysisName}
                    setIsView={setIsView}
                  />

                  <div
                    className='search-container--canvas'
                    style={{
                      visibility: searchOpen ? 'visible' : 'hidden',
                      backgroundColor: searchOpen ? null : 'transparent',
                    }}
                  >
                    {searchOpen && (
                      <div className='input-container--canvas'>
                        <div className='autocomplete-container'>
                          <SelectAuto
                            nodesToAutocomplete={nodesToAutocomplete}
                            setNodesToAutocomplete={setNodesToAutocomplete}
                            fetchList={fetchList}
                            handleAddNode={handleAddNode}
                            isFetching={isFetching}
                            inside
                          />
                        </div>
                      </div>
                    )}

                    <IconButton
                      disableRipple
                      color='primary'
                      style={{
                        visibility: 'visible',
                        transform: searchOpen ? 'rotate(135deg)' : '',
                      }}
                      onClick={() => setSearchOpen(!searchOpen)}
                    >
                      <AddCircleIcon
                        fontSize='large'
                        style={{ color: '#006fff' }}
                      />
                    </IconButton>
                  </div>

                  {!linkAnalysisState.isMapActive &&
                    !chatOpen &&
                    hasCoordinates && (
                      <BaseButton
                        className='map-button'
                        onClick={() =>
                          setLinkAnalysisState((current) => ({
                            ...current,
                            isMapActive: true,
                          }))
                        }
                      >
                        <Map />

                        <span style={{ marginLeft: 8 }}>Show Map</span>
                      </BaseButton>
                    )}
                </div>
              )}

              <div
                className='regraph-wrapper'
                style={{
                  height: linkAnalysisState.isMapActive ? '100%' : null,
                  position: 'relative',
                }}
              >
                {linkAnalysisState.isMapActive && (
                  <div className='map-buttons'>
                    <BaseButton
                      className='close-map-button'
                      onClick={() => {
                        setLinkAnalysisState((current) => ({
                          ...current,
                          isMapActive: false,
                        }))
                        centerCanvas()
                      }}
                    >
                      <Map />

                      <span style={{ marginLeft: 8 }}>Hide Map</span>
                    </BaseButton>
                    <BaseButton
                      onClick={() =>
                        openGoogleMapsWithAllMarkersVisible(coordinates)
                      }
                      style={{
                        backgroundColor: '#ffffff',
                        color: '#00000',
                        marginTop: '10px',
                        padding: '6px 10px',
                        borderRadius: '2px',
                      }}
                    >
                      <PublicSharpIcon />
                      <span style={{ marginLeft: 8 }}>Google</span>
                    </BaseButton>

                    {/* <BaseButton
                      onClick={() => {}}
                      style={{
                        backgroundColor: '#ffffff',
                        color: '#00000',
                        marginTop: '10px',
                        padding: '6px 10px',
                        borderRadius: '2px',
                      }}
                    >
                      <FileDownload />
                      <span style={{ marginLeft: 8 }}>KML</span>
                    </BaseButton> */}
                  </div>
                )}

                {isModalOpen && (
                  <Modal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    publishRegraph={publishRegraph}
                    analysisName={analysisName}
                    setAnalysisName={setAnalysisName}
                  />
                )}

                <FontLoader config={{ custom: { families: ['icomoon'] } }}>
                  <div
                    style={{ width: '100%', height: '100%' }}
                    onContextMenu={suppressBrowserContextMenu}
                    // onKeyDown={handleKeyDown}
                    onClick={handleClick}
                  >
                    <Chart
                      ref={chartRef}
                      map={linkAnalysisState.isMapActive}
                      items={
                        linkAnalysisState?.isMapActive
                          ? mapViewItems(styledItems())
                          : styledItems()
                      }
                      options={{
                        iconFontFamily: 'icomoon',
                        fit: isComboClosed ? {} : 'none',
                        handMode,
                        backgroundColor: '#ffffff',
                        links: {
                          arrowSize: 'small',
                          avoidLabels: true,
                          endSpacing: 'loose',
                        },
                        labels: {
                          maxLength: 25,
                          fontFamily: 'Roboto',
                        },
                        // navigation: false,
                        map: {
                          leaflet: {
                            minZoom: 4,
                            maxZoom: 20,
                          },
                        },
                      }}
                      positions={linkAnalysisState?.currentChart?.positions}
                      layout={linkAnalysisState?.currentChart?.layout}
                      style={{
                        width: '100%',
                        height: timebarOpen ? '100%' : 'calc(100% - 100px)',
                      }}
                      selection={selection}
                      className='regraph-chart'
                      onClick={handleClick}
                      onChange={handleChartChange}
                      onContextMenu={openContextMenu}
                      onDoubleClick={handleDoubleClick}
                      onCombineNodes={combineNodesHandler}
                      combine={combos?.combine}
                      onDragEnd={handleDragEnd}
                      onDragOver={handleDragOver}
                      onDragStart={handleDragStart}
                      animation={{ time: animationTime }}
                    />

                    <NestedMenu
                      selection={selection}
                      contextMenuEvents={contextMenuEvents}
                      menu={menu}
                    />
                  </div>
                </FontLoader>

                {changeGroupNameInput && (
                  <div
                    style={{
                      position: 'absolute',
                      left: groupNamePositions.left,
                      top: groupNamePositions.top,
                      zIndex: '99',
                      display: 'flex',
                    }}
                  >
                    <TextField
                      inputRef={groupInputRef}
                      className='change-name'
                      label='Change group name'
                      variant='filled'
                      size='small'
                    />

                    <Button
                      style={{ color: '#fff', backgroundColor: '#3f51b5' }}
                      onClick={saveGroupName}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {/* <NestedMenu
                  selection={selection}
                  contextMenuEvents={contextMenuEvents}
                  menu={menu}
                /> */}
              </div>

              <div>
                {!timebarOpen && (
                  <Button
                    style={{
                      fontWeight: '400',
                      borderRadius: 12,
                      border: '3px solid #ebebeb',
                      padding: '10px 16px',
                      position: 'absolute',
                      left: 0,
                      bottom: 40,
                    }}
                    onClick={() => setTimebarOpen(true)}
                  >
                    <TimelineIcon />

                    <span style={{ marginLeft: 8 }}>Show Timebar</span>
                  </Button>
                )}

                {timebarOpen && (
                  <div
                    className='regraph-timebar'
                    style={{
                      height: timebarOpen ? '100px' : '40px',
                      borderTop: timebarOpen ? '1px solid #d2d2d2' : 'none',
                      position: 'relative',
                    }}
                  >
                    <Button
                      style={{
                        fontWeight: '400',
                        border: '3px solid #ebebeb',
                        borderRadius: '12px',
                        padding: '10px 16px',
                        position: 'absolute',
                        left: '10px',
                        top: '10px',
                      }}
                      onClick={() => setTimebarOpen(false)}
                    >
                      <TimelineIcon />

                      <span style={{ marginLeft: 8 }}>Hide Timebar</span>
                    </Button>

                    <TimeBar
                      ref={timebarRef}
                      items={linkAnalysisState?.currentChart?.items}
                      style={{
                        width: '100%',
                        height: timebarOpen ? '100px' : '40px',
                      }}
                      onChange={setTimeRange}
                      options={{
                        style: { color: '#2dcda8', hoverColor: '#8cedd0' },
                        backgroundColor: '#f8f8f8',
                        zoom: {
                          max: {
                            unit: 'day',
                          },
                          min: {
                            unit: 'year',
                          },
                        },
                      }}
                    />
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </div>

        {detailsShown && (
          <div className='node-details--wrapper'>
            <NodeDetails
              setDetailsShown={setDetailsShown}
              // apiUrl={apiUrl}
              isLoading={isLoading}
              setLoading={setLoading}
              selection={
                Object.keys(selection).length === 1
                  ? selection
                  : Object.keys(selection).length
              }
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Regraph
