/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef, Fragment } from 'react'
import './GlobalSearch.scss'
import {
  Divider,
  TextField,
  Button,
  InputAdornment,
  Tabs,
  Tab,
  Snackbar,
  MenuItem,
  ClickAwayListener,
} from '@material-ui/core'
import { globalSearchAllContent } from '../../shared/services/api/search/search'
import GlobalFilters from './GlobalFilters'
import GlobalListing from './GlobalListing'
import MuiAlert from '@material-ui/lab/Alert'
import { IconButton, Pagination, Tooltip } from '@mui/material'
import Spinner from '../../shared/Spinner'
import { Clear } from '@material-ui/icons'
import { useGlobalContext } from '../../context/globalContext'
import { isMobile } from 'react-device-detect'
import { useUserContext } from '../../context/userContext'
import { useQueryClient } from '@tanstack/react-query'
import { useViewStateContext } from '../../context/viewStateContext'
import { useThrottle } from '../../hooks/useThrottle'
import { contentSuggestions } from '../../shared/services/api/alerts/alerts'
import { History } from '@mui/icons-material'
import HistorySearchModal from './HistorySearchModal/HistorySearchModal'

const modifiedNames = {
  alert: 'alert',
  scenario: 'scenario',
  'threat-actor': { subtype: 'threat-actor', type: 'profile' },
  incident: { subtype: 'incident', type: 'profile' },
  operation: { subtype: 'operation', type: 'profile' },
  'malware-tools': { subtype: 'malware-tools', type: 'profile' },
  report: 'report',
  ioc: 'ioc',
  forum: 'forum',
}

const profileTypes = ['threat-actor', 'incident', 'operation', 'malware-tools']

const GlobalSearch = ({}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const { globalSearchFilters, setGlobalSearchFilters, filterOptions } =
    useGlobalContext()
  const {
    user: {
      modules: { view_mode },
    },
    leftMenuVisible,
  } = useUserContext()
  const searchRef = useRef(null)
  const [toastMessage, setToastMessage] = useState(undefined)
  const [orderBy, setOrderBy] = useState('default')
  const [searchFilterOpen, setSearchFilterOpen] = useState(!isMobile)
  const moduleTypes = [
    { name: 'All', slug: 'all' },
    ...view_mode?.filter(
      (i) =>
        i.has_access === true && i.slug !== 'chat' && i.slug !== 'analysis',
    ),
    { name: 'IOCs', slug: 'ioc' },
  ]
  const queryClient = useQueryClient()
  const { globalSearchResult, setGlobalSearchResult } = useViewStateContext()
  const [skipCount, setSkipCount] = useState(true)
  const [suggestedOptions, setSuggestedOptions] = useState([])
  const [suggestionOpen, setSuggestionOpen] = useState(false)
  const [historyOpen, setHistoryOpen] = useState(false)

  useEffect(() => {
    if (!globalSearchResult?.data) {
      getSearchedResult()
    }
  }, [])

  useEffect(() => {
    if (skipCount) setSkipCount(false)
    if (!skipCount || globalSearchFilters?.fromDashboard) {
      delete globalSearchFilters.fromDashboard
      setGlobalSearchFilters(globalSearchFilters)
    }
  }, [])

  useEffect(() => {
    if (globalSearchFilters?.page) {
      getSearchedResult()
    }
  }, [globalSearchFilters?.page])

  useEffect(() => {
    if (globalSearchFilters?.currentTab || globalSearchFilters?.orderBy) {
      getSearchedResult()
    }
  }, [globalSearchFilters?.currentTab, globalSearchFilters?.orderBy])

  const getSearchedResult = async (isSuggestion, suggestionKeyword) => {
    const token = () => localStorage.getItem('access_token')
    setGlobalSearchResult({})

    const tags = [
      'tags_target_geography',
      'tags_motivation',
      'tags_intended_effect',
      'tags_language',
    ]

    const currentModule = globalSearchFilters?.currentTab
    const payload = {
      filters: {
        published_data_range: globalSearchFilters?.dateRange,
        content_type:
          modifiedNames[currentModule]?.type || modifiedNames[currentModule],
        content_subtype: modifiedNames[currentModule]?.subtype
          ? [modifiedNames[currentModule]?.subtype]
          : globalSearchFilters?.types?.map((i) => i?.slug || i?.id),
        priorities: globalSearchFilters?.priority,
        sectors: globalSearchFilters?.sectors?.map((i) => i?.id),
        is_follow: globalSearchFilters?.status?.includes('flagged'),
        relevance: globalSearchFilters?.relevance,
        group_id: globalSearchFilters?.groups?.map(({ id }) => id),
        scenario_framework_id: globalSearchFilters?.frameworks?.map(
          ({ id }) => id,
        ),
      },
      global: true,
      sorting: globalSearchFilters?.orderBy ?? {
        field: 'relevance',
        direction: 'desc',
      },
      pagination: {
        page: globalSearchFilters?.page || 1,
        per_page: 40,
      },
    }

    if (
      globalSearchFilters?.status?.includes('read') &&
      !globalSearchFilters?.status?.includes('unread')
    ) {
      payload.filters.is_read = true
    } else if (
      !globalSearchFilters?.status?.includes('read') &&
      globalSearchFilters?.status?.includes('unread')
    ) {
      payload.filters.is_read = false
    } else if (
      globalSearchFilters?.status?.includes('read') &&
      globalSearchFilters?.status?.includes('unread')
    ) {
      delete payload.filters.is_read
    }

    tags.forEach((tag) => {
      if (globalSearchFilters[tag]) {
        let tags = []
        globalSearchFilters[tag]?.forEach(({ id }) => {
          tags = [...tags, id]
        })
        payload.filters[tag] = tags
      }
    })

    if (isSuggestion === true) {
      payload.filters.keyword = suggestionKeyword
    } else if (globalSearchFilters?.keywords) {
      payload.filters.keyword = globalSearchFilters?.keywords
    }

    if (currentModule === 'ioc') {
      delete payload.filters.content_type
      delete payload.filters.content_subtype
      payload.filters.has_ioc = true
      if (globalSearchFilters?.keywords) {
        payload.filters.ioc_keyword = globalSearchFilters?.keywords
        delete payload.filters.keyword
      }
    }

    const response = await globalSearchAllContent(token(), payload)
    response?.data?.length === 0 &&
      setToastMessage(['warning', 'No results found'])
    setGlobalSearchResult(response)
    return response
  }

  const searchResult = async (isPage) => {
    try {
      const response = await queryClient.fetchQuery({
        queryKey: ['globalSearchResult'],
        queryFn: getSearchedResult,
      })

      response?.data?.length === 0 &&
        setToastMessage(['warning', 'No results found'])
    } catch (err) {}
  }

  const handleChangeTab = (newValue) => {
    let currentFilters = { ...globalSearchFilters }
    const keywords = currentFilters.keywords
    currentFilters = {}
    if (keywords) {
      currentFilters.keywords = keywords
    }

    currentFilters.currentTab = newValue
    setGlobalSearchResult({})

    setGlobalSearchFilters(currentFilters)
    setOrderBy('default')
    // setFilterOptions({})
  }

  const orderChange = async (value) => {
    setOrderBy(value)
    handleFilters('orderBy', value)
  }

  const handleResetFilters = () => {
    const tab = globalSearchFilters.currentTab
    setGlobalSearchFilters({ currentTab: tab || 'all' })
    setOrderBy('default')
  }

  useEffect(() => {
    window.history.replaceState(null, '')
  }, [])

  const handleFilters = (key, value) => {
    const currentState = { ...globalSearchFilters }
    const multipickSections = [
      'tags_target_geography',
      'tags_language',
      'tags_motivation',
      'tags_intended_effect',
    ]

    if (multipickSections.includes(key)) {
      currentState[key] = value
      if (currentState[key]?.length === 0) {
        delete currentState[key]
      }
      setGlobalSearchFilters(currentState)
      return false
    }
    if (
      key === 'keywords' ||
      key === 'dateRange' ||
      key === 'orderBy' ||
      key === 'types' ||
      key === 'sectors' ||
      key === 'groups' ||
      key === 'frameworks' ||
      key === 'page'
    ) {
      currentState[key] = value
    } else {
      if (!currentState[key]) {
        currentState[key] = [value]
      } else {
        if (currentState[key].includes(value)) {
          currentState[key] = currentState[key].filter((item) => item !== value)
        } else {
          currentState[key] = [...currentState[key], value]
        }
      }
    }

    if (currentState.orderBy === 'default') {
      delete currentState.orderBy
    }

    if (currentState[key]?.length === 0) {
      delete currentState[key]
    }

    setGlobalSearchFilters(currentState)
  }

  const getSuggestedSearchResult = async (keyword) => {
    const hasOnlySpaces =
      globalSearchFilters?.keywords?.replace(/\s/g, '')?.length === 0
    if (hasOnlySpaces) {
      return false
    }
    if (
      globalSearchFilters?.keywords !== keyword &&
      !globalSearchFilters?.keywords?.length
    ) {
      setSuggestedOptions([])
      return false
    }
    try {
      const payload = {}
      payload.keyword = keyword
      if (modifiedNames[globalSearchFilters?.currentTab]?.type) {
        payload.content_type = 'profile'
        payload.content_subtype =
          modifiedNames[globalSearchFilters?.currentTab]?.subtype
      } else {
        payload.content_type = modifiedNames[globalSearchFilters?.currentTab]
      }
      const response = await contentSuggestions(token(), payload)
      setSuggestedOptions(response?.data)
    } catch (err) {}
  }

  const debounceLoadData = useThrottle(getSuggestedSearchResult, 250)

  useEffect(() => {
    if (globalSearchFilters?.keywords?.length > 0) {
      debounceLoadData(globalSearchFilters?.keywords)
    } else {
      setSuggestedOptions([])
    }
  }, [globalSearchFilters?.keywords])

  return (
    <div
      className='global-search'
      style={{ width: leftMenuVisible ? null : '100%' }}
    >
      {toastMessage && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={8000}
          onClose={() => setToastMessage(false)}
          open={toastMessage !== undefined}
        >
          <MuiAlert elevation={6} variant='filled' severity={toastMessage[0]}>
            {toastMessage[1]}
          </MuiAlert>
        </Snackbar>
      )}

      {searchFilterOpen && (
        <div className='global-search__filters'>
          <GlobalFilters
            filters={globalSearchFilters || { currentTab: 'all' }}
            handleFilters={handleFilters}
            handleResetFilters={handleResetFilters}
            filterOptions={
              (globalSearchFilters?.currentTab &&
                filterOptions[
                  profileTypes.includes(globalSearchFilters?.currentTab)
                    ? 'profile'
                    : globalSearchFilters?.currentTab
                ]) ||
              []
            }
            orderBy={orderBy}
            orderChange={orderChange}
            searchFilterOpen={searchFilterOpen}
            setSearchFilterOpen={setSearchFilterOpen}
          />

          <Button
            color='primary'
            style={{
              color: '#fff',
              backgroundColor: '#006fff',
              marginTop: '20px',
              width: '100%',
              textTransform: 'capitalize',
              fontWeight: 700,
            }}
            onClick={() => {
              isMobile && searchFilterOpen && setSearchFilterOpen(false)

              searchResult()
            }}
            type='submit'
          >
            Filter results
          </Button>
        </div>
      )}

      <div className='global-search__content'>
        <div className='keywords'>
          {isMobile && (
            <Button
              onClick={() => setSearchFilterOpen(!searchFilterOpen)}
              className='mob-show-filters'
              endIcon={
                <span
                  style={{ transform: 'rotate(90deg)' }}
                  className='icon-expand-content'
                />
              }
            >
              Open filters
            </Button>
          )}

          <form
            onSubmit={(event) => {
              event.preventDefault()
              searchResult()
              searchRef?.current?.blur()
            }}
          >
            <ClickAwayListener onClickAway={() => setSuggestionOpen(false)}>
              <TextField
                inputRef={searchRef}
                fullWidth
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                value={globalSearchFilters?.keywords || ''}
                onChange={(event) => {
                  const currentState = { ...globalSearchFilters }
                  if (event.target.value === '') {
                    delete currentState.keywords
                  } else {
                    currentState.keywords = event.target.value
                  }
                  setGlobalSearchFilters(currentState)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <span
                        className='icon-search'
                        style={{ color: '#fff', fontSize: 36 }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: globalSearchFilters?.keywords && (
                    <Tooltip title='Clear' placement='left'>
                      <IconButton onClick={() => handleFilters('keywords', '')}>
                        <Clear style={{ color: '#fff' }} />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
                placeholder='Click and type to search all content'
                variant='standard'
                onFocus={() => setSuggestionOpen(true)}
                onBlur={(event) => {
                  if (
                    event?.relatedTarget?.classList?.contains(
                      'MuiListItem-root',
                    )
                  ) {
                    return false
                  }
                  setSuggestionOpen(false)
                }}
              />
            </ClickAwayListener>
          </form>
          <Tooltip placement='top' arrow title='Open Search History'>
            <IconButton
              onClick={() => setHistoryOpen(!historyOpen)}
              style={{ padding: 10 }}
            >
              <History style={{ color: '#fff', height: 40, width: 40 }} />
            </IconButton>
          </Tooltip>

          {historyOpen ? (
            <HistorySearchModal
              setGlobalSearchFilters={setGlobalSearchFilters}
              setHistoryOpen={setHistoryOpen}
              currentTab={globalSearchFilters?.currentTab}
              getSearchedResult={searchResult}
            />
          ) : null}

          {searchRef?.current?.value?.length > 0 &&
            suggestionOpen &&
            suggestedOptions?.length > 0 && (
              <div className='search-autocomplete'>
                {suggestedOptions?.map(({ title, content_type }) => {
                  return (
                    <Fragment key={title}>
                      <MenuItem
                        onClick={() => {
                          handleResetFilters()
                          setGlobalSearchFilters({
                            currentTab: globalSearchFilters?.currentTab,
                            keywords: `"${title}"`,
                          })
                          setTimeout(() => {
                            getSearchedResult(true, `"${title}"`)
                          }, 0)
                        }}
                        key={title}
                        style={{ fontSize: 18 }}
                      >
                        {title}
                        <div className='autocomplete-item'>{content_type}</div>
                      </MenuItem>
                    </Fragment>
                  )
                })}
              </div>
            )}
        </div>

        <Divider style={{ margin: '0 -30px', backgroundColor: '#0000004D' }} />

        <Tabs
          value={globalSearchFilters.currentTab || 'all'}
          indicatorColor='primary'
          onChange={(event, newValue) =>
            newValue !== globalSearchFilters.currentTab &&
            handleChangeTab(newValue)
          }
        >
          {moduleTypes.map((type) => (
            <Tab key={type.name} label={type.name} value={type.slug} />
          ))}
        </Tabs>

        <Divider style={{ margin: '0 -30px', backgroundColor: '#0000004D' }} />

        {!globalSearchResult?.data ? (
          <Spinner center size={30} border={1} />
        ) : (
          <GlobalListing
            data={globalSearchResult?.data ?? []}
            filters={globalSearchFilters || { currentTab: 'all' }}
          />
        )}

        {globalSearchResult?.data && (
          <div
            style={{
              position: 'absolute',
              bottom: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: 'calc(100% - 60px)',
            }}
          >
            <Pagination
              onChange={(event, value) => handleFilters('page', value)}
              page={globalSearchFilters.page || 1}
              color='primary'
              size='small'
              count={globalSearchResult?.last_page || 0}
              shape='rounded'
              hideNextButton
              hidePrevButton
            />

            <h5 style={{ color: '#fff' }}>
              Total results: {globalSearchResult?.total || ''}
            </h5>
          </div>
        )}
      </div>
    </div>
  )
}

export default GlobalSearch
