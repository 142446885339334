import { Avatar } from '@material-ui/core'
import { ReactComponent as GlobalIcon } from '../../../../assets/icon-global.svg'
import { ReactComponent as OrganisationIcon } from '../../../../assets/icon-organisation.svg'
import { ReactComponent as SectorIcon } from '../../../../assets/icon-sector.svg'
import { ReactComponent as CommunityIcon } from '../../../../assets/icon-community.svg'
import { formatDate } from '../../../../shared/helpers/formatDate'
import { typeNames } from '../../../../shared/helpers/typeNames'

const ContentHeading = ({ data, type }) => {
  return (
    <div className='single-piece__details-heading' style={{ width: '95%' }}>
      {data.tlp && (
        <div
          style={{
            backgroundColor:
              data.tlp.colour === 'clear' ? '#fff' : data.tlp.colour_code,
            color: data.tlp.colour === 'clear' ? '#000' : null,
          }}
          className='tlp'
        >
          <span style={{ fontWeight: 700 }}>
            {`
            ${data.tlp.label} 
            ${
              data.tlp.classification_title.length > 0
                ? ` - ${data.tlp.classification_title}`
                : ''
            }
            ${data.tlp.caveat ? ` - ${data.tlp.caveat}` : ''}
            `}
          </span>

          <span>
            {`
              ${data.tlp.description}
              ${
                data.tlp.classification_description.length > 0
                  ? ` - ${data.tlp.classification_description}`
                  : ''
              }
            `}
          </span>
        </div>
      )}

      <div className='single-piece__details-title'>
        <div className='title'>{data.title}</div>

        <div className='avatars'>
          {type !== 'profile' && (
            <div className='priority'>
              <Avatar style={{ color: '#666666' }} className='type'>
                <span style={{ fontSize: 30 }} className={`icon-${type}`} />
              </Avatar>

              <span style={{ fontSize: '14px', fontWeight: 600 }}>
                {typeNames[type]?.name.slice(0, -1)}
              </span>
            </div>
          )}

          {data.typeName && (
            <div className='priority'>
              <Avatar style={{ color: '#666666' }} className='type'>
                <span
                  style={{ fontSize: 30 }}
                  className={`icon-${data.typeSlug}`}
                />
              </Avatar>

              <span style={{ fontSize: '14px', fontWeight: 600 }}>
                {data.typeName}
              </span>
            </div>
          )}

          {data.type && (
            <div className='priority'>
              <Avatar style={{ color: '#666666' }} className='type'>
                {data.type.acronym}
              </Avatar>

              <span style={{ fontSize: '14px', fontWeight: 600 }}>
                {data.type.name}
              </span>
            </div>
          )}

          {data.priorityName && (
            <div
              className='priority'
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginBottom: '-17.5px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar className={`level-${data.priority}`}>
                  {data.priority}
                </Avatar>

                <span style={{ fontSize: '14px', fontWeight: 600 }}>
                  {data.priorityName}
                </span>
              </div>

              <span
                className={`level-${data.priority}`}
                style={{ fontWeight: 500, fontSize: 14 }}
              >
                Global
              </span>
            </div>
          )}

          {data?.prioritySectorName && (
            <div
              className='priority'
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginBottom: '-17.5px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar className={`level-${data.priority_sector_id}`}>
                  {data.priority_sector_id}
                </Avatar>

                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 600,
                    textAlign: 'left',
                  }}
                >
                  {data.prioritySectorName}
                </span>
              </div>

              <span
                className={`level-${data.priority_sector_id}`}
                style={{ fontWeight: 500, fontSize: 14 }}
              >
                Sector
              </span>
            </div>
          )}

          {data.priority > 0 && data.capabilityName && (
            <div className='priority'>
              <Avatar className={`level-${data.priority}`}>
                {data.priority}
              </Avatar>

              <span
                style={{ fontSize: '14px', fontWeight: 600, textAlign: 'left' }}
              >
                {data.capabilityName}
              </span>
            </div>
          )}

          {data.relevanceName && !data?.prioritySectorName && (
            <div className='relevance-name'>
              <Avatar>
                {data.relevanceName === 'organisation' && <OrganisationIcon />}

                {data.relevanceName === 'global' && <GlobalIcon />}

                {data.relevanceName === 'sector' && <SectorIcon />}

                {data.relevanceName === 'community' && <CommunityIcon />}
              </Avatar>

              <span style={{ fontSize: '14px', fontWeight: 600 }}>
                {data.relevanceName}
              </span>
            </div>
          )}
        </div>
      </div>

      {data?.text_relevance?.length > 0 && (
        <div className='relevance'>
          <b>Relevance: </b>

          {data.text_relevance}
        </div>
      )}

      {data.known_as && (
        <span
          style={{
            fontWeight: 100,
            fontSize: 14,
            marginBottom: 5,
            textAlign: 'left',
          }}
        >
          <b>Aliases: </b>

          {data.known_as}
        </span>
      )}

      <div className='publish-data'>
        {formatDate(data.published_at) == formatDate(data.updated_at) ? (
          <span>
            <b>Published: </b>

            {formatDate(data.published_at)}
          </span>
        ) : (
          <>
            <span>
              <b>Published: </b>

              {formatDate(data.published_at)}
            </span>

            <span>
              <b>Updated: </b>

              {formatDate(data.updated_at)}
            </span>
          </>
        )}

        <span>
          <b>Author: </b>

          {data.author}
        </span>
      </div>
    </div>
  )
}

export default ContentHeading
