/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Checkbox, Divider, TextField, Button } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { statuses } from './data'
import OrderBy from '../Profiles/Filters/OrderBy'
import { useGlobalContext } from '../../context/globalContext'
import DateRangePickerComponent from '../../shared/DateRangePicker/DateRangePicker'
import useNotification from '../../hooks/useNotification'
import { useUserContext } from '../../context/userContext'

/**
 * ContentManageFilters
 * @param {object} filters - is in create mode
 * @param {function} setFilters - setting filters directly
 * @param {function} handleFilters - handling filters
 * @param {function} searchResult - searches for results
 */

const ContentManageFilters = ({ handleFilters, searchResult }) => {
  const history = useHistory()
  const {
    isContentBeingEdited,
    setToastMessage,
    globalContentFilters,
    setGlobalContentFilters,
  } = useGlobalContext()
  const isContentEdited = JSON.parse(localStorage.getItem('isContentEdited'))
  const { errorToast } = useNotification()
  const {
    user: {
      modules: { create_mode },
    },
  } = useUserContext()

  const handleResetFilters = () => {
    setGlobalContentFilters((current) => {
      return { currentTab: current.currentTab }
    })
  }

  return (
    <>
      <div className='content-manage__header'>
        <h3>Content Management</h3>

        <Button
          variant='outlined'
          onClick={() => {
            if (isContentEdited) {
              errorToast({
                message:
                  'You may have one content editor open per user session.',
              })
              return
            }

            if (isContentBeingEdited) {
              setToastMessage(['warning', 'Content is currently being edited.'])

              setTimeout(() => setToastMessage(null), 3000)
            } else {
              history.push('/app/content-create')
            }
          }}
          style={{
            textTransform: 'capitalize',
            borderRadius: 12,
            backgroundColor: '#006fff',
            color: '#fff',
            fontSize: 13,
          }}
          startIcon={<span className='icon-edit' />}
        >
          Create content
        </Button>
      </div>

      <Divider style={{ margin: '0 -30px', backgroundColor: '#0000004D' }} />

      <div
        style={{
          margin: '12px 0px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 31,
        }}
      >
        <span
          onClick={() => handleResetFilters({})}
          onKeyDown={() => handleResetFilters({})}
          style={{
            color:
              Object.keys(globalContentFilters).length > 1 ? '#fff' : '#8d9197',
            cursor: 'pointer',
          }}
        >
          Reset filters
        </span>

        <OrderBy
          handleFilters={handleFilters}
          orderBy={globalContentFilters?.orderBy || 'default'}
          noRelevance
        />
      </div>

      <Divider style={{ margin: '0 -30px', backgroundColor: '#0000004D' }} />

      <div className='sector'>
        <h4>Status</h4>

        <Autocomplete
          multiple
          value={globalContentFilters.status || []}
          options={statuses}
          getOptionSelected={(option, value) => option.slug === value.slug}
          disableCloseOnSelect
          onChange={(e, newValue) => handleFilters('status', newValue)}
          getOptionLabel={(option) => option.name}
          renderOption={(option, props) => {
            return (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  minHeight: 30,
                }}
                className='sector-checkbox'
              >
                <div>
                  <Checkbox
                    style={{ padding: 0, marginRight: 10 }}
                    checked={props.selected}
                    icon={<span className='icon-checkbox-empty' />}
                    checkedIcon={<span className='icon-checkbox-checked' />}
                  />

                  <span style={{ textTransform: 'capitalize', fontSize: 13 }}>
                    {option.name}
                  </span>
                </div>
              </div>
            )
          }}
          renderInput={(params) => {
            const number = params.InputProps.startAdornment
            params.InputProps.startAdornment = params.InputProps.startAdornment
              ? `${
                  number && number.length === 1 ? 'Status' : 'Statuses'
                } selected: ${number && number.length}`
              : ''
            return (
              <TextField
                {...params}
                style={{ color: '#fff', textTransform: 'capitalize' }}
                variant='outlined'
                placeholder='Select status'
              />
            )
          }}
        />
      </div>

      <div className='sector'>
        <h4>Content Type</h4>

        <Autocomplete
          multiple
          value={globalContentFilters.content_type || []}
          options={create_mode?.filter((i) => i.slug !== 'country')}
          getOptionSelected={(option, value) => option.slug === value.slug}
          disableCloseOnSelect
          onChange={(e, newValue) => handleFilters('content_type', newValue)}
          getOptionLabel={(option) => option.name}
          renderOption={(option, props) => {
            return (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  minHeight: 30,
                }}
                className='sector-checkbox'
              >
                <div>
                  <Checkbox
                    style={{ padding: 0, marginRight: 10 }}
                    checked={props.selected}
                    icon={<span className='icon-checkbox-empty' />}
                    checkedIcon={<span className='icon-checkbox-checked' />}
                  />

                  <span style={{ textTransform: 'capitalize', fontSize: 13 }}>
                    {option.name}
                  </span>
                </div>
              </div>
            )
          }}
          renderInput={(params) => {
            const number = params.InputProps.startAdornment
            params.InputProps.startAdornment = params.InputProps.startAdornment
              ? `${
                  number && number.length === 1 ? 'Type' : 'Types'
                } selected: ${number && number.length}`
              : ''
            return (
              <TextField
                {...params}
                style={{ color: '#fff', textTransform: 'capitalize' }}
                variant='outlined'
                placeholder='Select types'
              />
            )
          }}
        />
      </div>

      <div>
        <DateRangePickerComponent
          selectedDateRange={globalContentFilters?.dateRange || {}}
          handleFilters={handleFilters}
        />
      </div>

      <Button
        color='primary'
        style={{
          color: '#fff',
          backgroundColor: '#6180a8',
          marginTop: '20px',
          width: '100%',
          textTransform: 'capitalize',
          fontWeight: 700,
        }}
        onClick={() => searchResult()}
        type='submit'
      >
        Filter results
      </Button>
    </>
  )
}

ContentManageFilters.displayName = 'ContentManageFilters'

/**
 * The properties.
 * @type {Object}
 */

ContentManageFilters.defaultProps = {
  selected: false,
}

ContentManageFilters.propTypes = {
  searchResult: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  selected: PropTypes.bool,
}

export default ContentManageFilters
