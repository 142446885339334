/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Avatar, Button, Divider, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import './ModuleListing.scss'
import Flag from '@material-ui/icons/Flag'
import { formatDate } from '../../shared/helpers/formatDate'
import { workspacesNumber } from '../../shared/helpers/workspacesNumber'
import MuiAlert from '@material-ui/lab/Alert'
import { Snackbar } from '@mui/material'
import { OpenInNew } from '@material-ui/icons'
import { useViewStateContext } from '../../context/viewStateContext'

const ModuleListing = ({
  items,
  activeItemRef,
  handleSetPiece,
  associated,
  isSidebar,
  type,
  filters,
}) => {
  const {
    workspaces,
    addWorkspace,
    setWorkspaces,
    activePiece,
    setActivePiece,
    isViewFetching,
  } = useViewStateContext()
  const [toastMessage, setToastMessage] = useState(undefined)
  const [hoveredItem, setHoveredItem] = useState(null)

  const handleClick = (item, disableOpenInNew) => {
    const workpaceWithChosenContent = workspaces?.find(
      (space) => space.currentPiece === item?.id,
    )

    if (isSidebar) {
      return false
    }

    if (workpaceWithChosenContent) {
      const currentState = [...workspaces]

      currentState.forEach((w) => (w.active = false))
      currentState.find(
        (w) => w.id === workpaceWithChosenContent.id,
      ).active = true
      setWorkspaces(currentState)
    } else {
      const { content_subtype_slug, id, title } = item
      if (content_subtype_slug !== 'country') {
        associated
          ? handleOpenAssociatedContent(
              content_subtype_slug,
              id,
              title,
              disableOpenInNew,
            )
          : handleSetPiece(item)
      }
    }
  }

  useEffect(() => {
    return () => {}
  }, [])

  const handleOpenAssociatedContent = (
    contentType,
    id,
    title,
    disableOpenInNew,
  ) => {
    if (workspacesNumber > 15) {
      setToastMessage('You cannot open more workspaces')
    } else {
      !disableOpenInNew && setActivePiece(null)
      const typeName =
        contentType === 'threat-actor' ||
        contentType === 'incident' ||
        contentType === 'country' ||
        contentType === 'operation' ||
        contentType === 'malware-tools'
          ? contentType
          : 'alert'
      !isSidebar &&
        addWorkspace(typeName, id, title, undefined, disableOpenInNew)
    }
  }

  return items
    ?.filter((i) => !i?.isExtra)
    ?.map((item) => {
      const {
        priority,
        priorityName,
        title,
        author,
        publishing_date,
        typeSlug,
        content_type,
        content_subtype_slug,
        flagged,
        id,
        read,
        content_label,
        published_updated_at,
        author_name,
      } = item

      return (
        <div
          style={{ cursor: isViewFetching && 'wait' }}
          ref={activePiece === id ? activeItemRef : undefined}
          onMouseEnter={() => !isSidebar && setHoveredItem(id)}
          onMouseLeave={() => setHoveredItem(null)}
          onClick={() =>
            !isViewFetching && activePiece !== id && handleClick(item)
          }
          className={`${isSidebar ? 'preview ' : ''}button list-item${
            !read ? ' unread' : ''
          }${activePiece === id ? ' active' : ''}`}
          key={id}
        >
          <div className='list-item__left'>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={3500}
              onClose={() => setToastMessage(undefined)}
              open={toastMessage !== undefined}
            >
              <MuiAlert variant='filled' severity='warning'>
                <span>{toastMessage}</span>
              </MuiAlert>
            </Snackbar>

            <div className='priority'>
              <Avatar>
                <span
                  style={{
                    color: '#666666',
                    fontSize: 28,
                  }}
                  className={`icon-${
                    associated === 'alerts'
                      ? 'alert'
                      : typeSlug
                      ? typeSlug
                      : type === 'profile'
                      ? content_subtype_slug
                      : type
                  }`}
                />
              </Avatar>
            </div>

            {priority > 0 && (
              <div className='priority'>
                <Tooltip arrow placement='top' title={priorityName || ''}>
                  <Avatar className={`level-${priority}`}>{priority}</Avatar>
                </Tooltip>
              </div>
            )}
          </div>

          <div className='list-item__right'>
            <div className='title-container'>
              <span style={{ width: '100%' }} className='title'>
                {title}
              </span>

              {hoveredItem === id && (id !== activePiece || flagged) && (
                <div
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    right: '-6px',
                    top: '-6px',
                    padding: '4px 5px',
                    backgroundColor: '#fff',
                    boxShadow: 'rgb(0 0 0 / 15%) 0px 1px 2px 0px',
                    border: '1px solid #ccc',
                    borderRadius: 8,
                    gap: 6,
                  }}
                >
                  {flagged && (
                    <Flag style={{ color: '#3f51b5', fontSize: 22 }} />
                  )}

                  {id !== activePiece && (
                    <Tooltip
                      arrow
                      placement='top'
                      title='Open in new workspace'
                    >
                      <Button
                        className='open-new-tab'
                        style={{
                          display: hoveredItem === id ? 'flex' : 'none',
                        }}
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          if (associated) {
                            handleClick(item, true)
                          } else {
                            addWorkspace(
                              content_type === 'profile'
                                ? content_subtype_slug
                                : content_type,
                              id,
                              title,
                              filters,
                              true,
                            )
                          }
                        }}
                        startIcon={<OpenInNew />}
                      />
                    </Tooltip>
                  )}
                </div>
              )}
            </div>

            <Divider />

            <span className='updated-at'>
              Updated on: {formatDate(published_updated_at || publishing_date)}
            </span>

            <span className='author'>
              Author: {author_name || author || '-'}
            </span>

            <div className='labels'>
              {content_label && (
                <span className='label-status'>{content_label}</span>
              )}

              {read === false && <span className='label-read'>Unread</span>}
            </div>
          </div>
        </div>
      )
    })
}

export default ModuleListing
