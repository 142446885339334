import { Chip, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useGlobalContext } from '../../../context/globalContext'

const ContentMultiselect = ({ title, category, data, slug, handleFilters }) => {
  const { filterOptions } = useGlobalContext()

  return (
    <>
      <h4>{title}</h4>
      <Autocomplete
        multiple
        blurOnSelect
        filterSelectedOptions
        disableCloseOnSelect
        getOptionSelected={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => option?.name}
        options={filterOptions?.profile[category] || []}
        value={[]}
        onChange={(event, value) => {
          const { key } = event

          if (key === 'Backspace' || key === 'Delete') {
            return false
          }

          const newValue = value[value?.length - 1]
          if (data?.find((i) => i.id === newValue.id)) {
            handleFilters(
              slug,
              data?.filter((i) => i.id !== newValue.id),
            )
          } else {
            handleFilters(slug, [...data, newValue])
          }
        }}
        renderTags={() => {
          return false
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Search tags'
            variant='outlined'
            className='tag-input'
            size='small'
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />

      <div style={{ margin: '10px 0' }}>
        {data?.map((piece) => (
          <Chip
            style={{
              backgroundColor: '#FFF4C9',
              color: '#000',
              margin: 2,
              fontSize: 10,
            }}
            onDelete={() => {
              handleFilters(
                slug,
                data?.filter((i) => i.id !== piece.id),
              )
            }}
            label={piece.name}
            key={piece.id + '_' + piece.name}
            deleteIcon={
              <span
                className='icon-close'
                style={{
                  width: 'unset',
                  height: 'unset',
                  fontSize: 14,
                  padding: '4px 4px 4px 0',
                  color: '#000',
                }}
              />
            }
          />
        ))}
      </div>
    </>
  )
}

export default ContentMultiselect
