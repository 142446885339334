/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Avatar, Divider, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import { formatDate } from '../../shared/helpers/formatDate'
import './GlobalSearch'
import PropTypes from 'prop-types'
import { OpenInNew } from '@material-ui/icons'
import { IconButton } from '@mui/material'
import { useViewStateContext } from '../../context/viewStateContext'
import { useHistory } from 'react-router-dom'
import { ForumIcon } from '../../assets/icon-forum'
// import parse from 'html-react-parser'
// import { uniq, uniqBy } from 'lodash'

/**
 * Chart
 * @param {object} data - global listing data
 * @param {object} filters - current applied filters
 */

const GlobalListing = ({ data, filters }) => {
  const { addWorkspace } = useViewStateContext()
  const [hoveredItem, setHoveredItem] = useState(null)
  const history = useHistory()

  const handleOpenPiece = (id, type, profileType, title, disableOpenInNew) => {
    addWorkspace(
      type === 'profile' ? profileType : type,
      id,
      title,
      filters,
      disableOpenInNew,
    )
    return false
  }

  return (
    <div className='global-search__listing'>
      <div className='global-search__listing-inner'>
        {data?.map(
          ({
            id,
            typeSlug,
            priority,
            title,
            priority_name,
            content_type,
            content_subtype_slug,
            known_as,
            content_label,
            publishing_date,
            author,
            read,
            content,
            thread_title,
            forum_category_id,
            thread_id,
            group_access_id,
          }) => (
            <div
              className='listing-item'
              onMouseEnter={() => setHoveredItem(id)}
              onMouseLeave={() => setHoveredItem(null)}
              onClick={() => {
                if (content_type === 'forum') {
                  history.push(
                    `/app/forum/${group_access_id}/${forum_category_id}/${thread_id}`,
                  )
                } else {
                  typeSlug !== 'country' &&
                    handleOpenPiece(
                      id,
                      content_type,
                      content_subtype_slug,
                      title,
                    )
                }
              }}
              key={content_type + id}
            >
              <div className='listing-item__left'>
                <div className='priority'>
                  <Avatar
                    style={{
                      backgroundColor: 'transparent',
                      border: '2px solid #767a82',
                    }}
                  >
                    {content_type === 'forum' ? (
                      <ForumIcon />
                    ) : (
                      <span
                        style={{ fontSize: 24 }}
                        className={`icon-${
                          content_type === 'profile'
                            ? content_subtype_slug
                            : content_type
                        }`}
                      />
                    )}
                  </Avatar>
                </div>

                {priority > 0 && (
                  <div className='priority'>
                    <Tooltip arrow placement='top' title={priority_name || ''}>
                      <Avatar className={`level-${priority}`}>
                        {priority}
                      </Avatar>
                    </Tooltip>
                  </div>
                )}
              </div>

              <div className='listing-item__right'>
                {content_type === 'forum' ? (
                  <h4 style={{ color: '#fff' }}>
                    {content?.replace(/<[^>]*>?/gm, '')}
                  </h4>
                ) : (
                  <h4 style={{ color: '#fff' }}>{`${title}${
                    known_as ? ' - ' + known_as : ''
                  }`}</h4>
                )}

                <Divider
                  style={{
                    backgroundColor: '#767A82',
                    width: '100%',
                    margin: '15px 0',
                  }}
                />

                {content_type === 'forum' ? (
                  <span style={{ color: '#ffffff80', fontSize: 12 }}>
                    Thread title: {thread_title}
                  </span>
                ) : null}

                <span style={{ color: '#ffffff80', fontSize: 12 }}>
                  Updated on: {formatDate(publishing_date)}
                </span>

                <span style={{ color: '#ffffff80', fontSize: 12 }}>
                  Author: {author}
                </span>

                {content_label || read === 'false' ? (
                  <div className='labels'>
                    {content_label && (
                      <span className='label-status'>{content_label}</span>
                    )}

                    {read === false && (
                      <span className='label-read'>Unread</span>
                    )}
                  </div>
                ) : null}
              </div>

              {content_type !== 'forum' && hoveredItem === id && (
                <Tooltip arrow placement='top' title='Open in new workspace'>
                  <IconButton
                    className='open-new-tab'
                    style={{
                      position: 'absolute',
                      right: 20,
                      top: 6,
                      padding: 9,
                    }}
                    onClick={(event) => {
                      const disableOpenInNew = true
                      event.preventDefault()
                      event.stopPropagation()
                      handleOpenPiece(
                        id,
                        content_type,
                        content_subtype_slug,
                        title,
                        disableOpenInNew,
                      )
                    }}
                  >
                    <OpenInNew style={{ color: '#fff', fontSize: 24 }} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          ),
        )}
      </div>
    </div>
  )
}

GlobalListing.displayName = 'GlobalListing'

/**
 * The properties.
 * @type {Object}
 */

GlobalListing.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  filters: PropTypes.instanceOf(Object).isRequired,
}

export default GlobalListing
