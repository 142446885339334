import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const editIoc = (token, id, data) =>
  client.put(apiUrl + `/api/ioc/admin/${id}`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const addIoc = (token, data) =>
  client.post(apiUrl + `/api/ioc/admin`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const deleteIoc = (token, id) =>
  client.remove(apiUrl + `/api/ioc/admin/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const uploadIoc = (token, data) =>
  client.post(apiUrl + '/api/files/ioc/upload', data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const iocCollection = (token, type, id, data) =>
  client.get(
    apiUrl + `/api/ioc/admin/${type}/${id}${data.length > 0 ? '?' + data : ''}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const iocViewCollection = (token, data) =>
  client.post(apiUrl + `/api/ioc/search`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
