/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Dialog } from '@material-ui/core'
import React, { Fragment } from 'react'
import { tutorial } from '../../../shared/services/api/account/account'
import video1 from '../../../assets/videos/tm_search_video_1.mp4'
import video2 from '../../../assets/videos/tm_search_video_2.mp4'
import video3 from '../../../assets/videos/tm_search_video_3.mp4'
import { isMobile } from 'react-device-detect'
import { useUserContext } from '../../../context/userContext'

/**
 * Tutorial
 * @param {number|null} tutorialStep - current tutorial step
 * @param {function} setTutorialStep - handling tutorial steps
 */

const SearchTutorial = ({ searchTutorialStep, setSearchTutorialStep }) => {
  const { updateTutorialState } = useUserContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const slides = [
    {
      step: 1,
      headline: 'New filters',
      text: 'We have enabled new filters in our profiles so that you can find exactly what you are looking for.',
      video: video1,
    },
    {
      step: 2,
      headline: 'Logical search operators',
      text: 'Perform complex searches using the AND, NOT and OR operator.',
      video: video2,
    },
    {
      step: 3,
      headline: 'Highlighting',
      text: 'ThreatMatch will highlight the matched text on the searched term.',
      video: video3,
    },
  ]

  const dotPos = () => {
    if (searchTutorialStep === 1) {
      return 14
    } else if (searchTutorialStep === 2) {
      return 0
    } else if (searchTutorialStep === 3) {
      return '-14'
    }
  }

  const handleTutorialPerm = async () => {
    try {
      const data = {
        is_tutorial_active: false,
        is_search_tutorial_active: false,
      }
      await tutorial(token(), data)
      updateTutorialState(false)
    } catch (err) {}
  }

  const handleDisableTutorial = async () => {
    await handleTutorialPerm()
    setSearchTutorialStep(null)
  }

  return (
    <Dialog
      style={{ zIndex: 9999 }}
      fullWidth
      maxWidth='md'
      open={searchTutorialStep !== null}
      PaperProps={{ style: { overflowY: 'unset', maxHeight: '80vh' } }}
    >
      <div style={{ padding: '35px 40px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h4 style={{ color: '#006FFF' }}>NEW FEATURES</h4>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          {slides.map(
            (slide) =>
              slide.step === searchTutorialStep && (
                <Fragment key={slide.step}>
                  <h3 style={{ marginBottom: 10 }}>{slide.headline}</h3>

                  <span>{slide.text}</span>

                  <div
                    style={{
                      height: isMobile
                        ? 'calc(30vh - 53px)'
                        : 'calc(50vh - 53px)',
                      margin: '20px 0',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <video
                      style={{ borderRadius: 20, width: '100%' }}
                      autoPlay
                      src={slide.video}
                      loop
                      muted
                    />
                  </div>
                </Fragment>
              ),
          )}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => setSearchTutorialStep(searchTutorialStep - 1)}
            disabled={searchTutorialStep <= 1}
            style={{
              backgroundColor: '#F2F2F2',
              padding: '8px 24px',
              color: searchTutorialStep <= 1 ? '#bababa' : '#000',
              borderRadius: 12,
              minWidth: 88,
              textTransform: 'capitalize',
            }}
          >
            Back
          </Button>

          {!isMobile && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 14,
                width: 70,
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  transform: `translateX(${dotPos()}px)`,
                  transition: 'all .2s ease',
                }}
              >
                {slides.map((dot) => (
                  <div
                    key={dot.step}
                    style={{
                      minWidth: 12,
                      minHeight: 12,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '0 1px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setSearchTutorialStep(dot.step)}
                  >
                    <span
                      style={{
                        width: searchTutorialStep === dot.step ? 12 : 9,
                        height: searchTutorialStep === dot.step ? 12 : 9,
                        borderRadius: 20,
                        backgroundColor:
                          searchTutorialStep === dot.step
                            ? '#006FFF'
                            : '#c7c7c7',
                        transition: 'all .3s ease',
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          <Button
            onClick={() =>
              searchTutorialStep === 3
                ? handleDisableTutorial()
                : setSearchTutorialStep(searchTutorialStep + 1)
            }
            style={{
              backgroundColor: '#006FFF',
              padding: '8px 24px',
              color: '#fff',
              fontWeight: 600,
              borderRadius: 12,
              minWidth: 88,
              textTransform: 'capitalize',
            }}
          >
            {searchTutorialStep >= 3 ? 'Finish' : 'Next'}
          </Button>
        </div>
      </div>

      <div
        style={{
          position: 'absolute',
          bottom: '-50px',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Button
          onClick={() => handleDisableTutorial()}
          style={{
            color: '#fff',
            fontWeight: 600,
            padding: '6px 22px',
            borderRadius: 12,
            fontSize: 16,
            textDecoration: 'underline',
            textTransform: 'capitalize',
          }}
        >
          Skip
        </Button>
      </div>
    </Dialog>
  )
}

export default SearchTutorial
