/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, useEffect, useState } from 'react'
import './Filters.scss'
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Button,
  MenuItem,
  ClickAwayListener,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useViewStateContext } from '../../../context/viewStateContext'
import { useThrottle } from '../../../hooks/useThrottle'
import { contentSuggestions } from '../../../shared/services/api/alerts/alerts'
import { useGlobalContext } from '../../../context/globalContext'
import { useUserContext } from '../../../context/userContext'
import GlobalAutocomplete from '../../GlobalSearch/GlobalAutocomplete'
import DateRangePickerComponent from '../../../shared/DateRangePicker/DateRangePicker'

const Filters = ({
  keywordsInput,
  handleFilters,
  handleSubmit,
  setOrderBy,
  setFiltersOpen,
}) => {
  const statusCheckboxes = [
    { name: 'Read', slug: 'read' },
    { name: 'Unread', slug: 'unread' },
    { name: 'Followed', slug: 'flagged' },
  ]
  const { user } = useUserContext()
  const { filterOptions } = useGlobalContext()
  const relevanceCheckboxes = ['organisation', 'sector', 'global']
  const { setWorkspaces, workspaces } = useViewStateContext()
  const currentModule = workspaces?.find((w) => w?.active === true)
  const filters = currentModule?.filters && Object.keys(currentModule.filters)
  const hasOnlyPage = filters?.length === 1 && filters[0] === 'page'
  const [suggestedOptions, setSuggestedOptions] = useState([])
  const [suggestionOpen, setSuggestionOpen] = useState(false)
  const isFiltersResetable = () =>
    filters?.length > 0 &&
    !hasOnlyPage &&
    currentModule?.filters?.orderBy !== 'default'

  const token = () => {
    return localStorage.getItem('access_token')
  }
  useEffect(() => {
    currentModule?.filters?.frameworks &&
      handleFilters('frameworks', currentModule.filters.frameworks)
    currentModule?.filters?.types &&
      handleFilters('types', currentModule.filters.types)
    currentModule?.filters?.keywords &&
      handleFilters('keywords', currentModule.filters.keywords)
  }, [currentModule.id])

  const handleResetFilters = () => {
    setOrderBy('default')
    const currentState = [...workspaces]
    const currentWorkspace = currentState.find(
      (workspace) => workspace.id === currentModule.id,
    )

    currentWorkspace.filters = { page: currentModule?.filters?.page || 1 }
    setWorkspaces(currentState)
  }

  const getSuggestedSearchResult = async (keyword) => {
    const hasOnlySpaces = keyword?.replace(/\s/g, '')?.length === 0
    if (hasOnlySpaces) {
      return false
    }
    if (
      currentModule?.filters?.keywords !== keyword &&
      !currentModule?.filters?.keywords?.length
    ) {
      setSuggestedOptions([])
      return false
    }
    try {
      const response = await contentSuggestions(token(), {
        content_type: 'scenario',
        keyword: keyword,
      })
      setSuggestedOptions(response?.data)
    } catch (err) {}
  }

  const debounceLoadData = useThrottle(getSuggestedSearchResult, 250)

  useEffect(() => {
    if (currentModule?.filters?.keywords?.length > 0) {
      debounceLoadData(currentModule?.filters?.keywords)
    } else {
      setSuggestedOptions([])
    }
  }, [currentModule?.filters?.keywords])

  return (
    <div className='filters-main'>
      <div className='filters-main__reset'>
        <span style={{ color: '#fff' }}>Search Scenarios</span>

        <span
          onClick={handleResetFilters}
          onKeyDown={handleResetFilters}
          style={{
            color: isFiltersResetable() ? '#fff' : '#8d9197',
            cursor: 'pointer',
          }}
        >
          Reset filters
        </span>
      </div>

      <Divider style={{ margin: '20px 0' }} />

      <div className='keywords'>
        <h4>Keywords</h4>

        <ClickAwayListener onClickAway={() => setSuggestionOpen(false)}>
          <TextField
            inputRef={keywordsInput}
            value={currentModule?.filters?.keywords || ''}
            onChange={(event) => handleFilters('keywords', event.target.value)}
            onKeyPress={(event) => event.key === 'Enter' && handleSubmit()}
            placeholder='Keywords'
            variant='outlined'
            type='search'
            onFocus={() => setSuggestionOpen(true)}
            onBlur={(event) => {
              if (
                event?.relatedTarget?.classList?.contains('MuiListItem-root')
              ) {
                return false
              }
              setSuggestionOpen(false)
            }}
          />
        </ClickAwayListener>

        {suggestionOpen && suggestedOptions?.length > 0 && (
          <div className='search-autocomplete'>
            {suggestedOptions?.map(({ title }) => {
              return (
                <Fragment key={title}>
                  <MenuItem
                    onClick={() => {
                      handleResetFilters()
                      handleFilters('keywords', `"${title}"`)
                      handleSubmit()
                    }}
                    key={title}
                  >
                    {title}
                  </MenuItem>
                </Fragment>
              )
            })}
          </div>
        )}
      </div>

      <div className='status'>
        <h4>Status</h4>

        <FormGroup row className='status-checkboxes'>
          {statusCheckboxes.map((item) => (
            <FormControlLabel
              key={item.slug}
              control={
                <Checkbox
                  checked={
                    currentModule?.filters?.status?.includes(item.slug) || false
                  }
                  onChange={() => handleFilters('status', item.slug)}
                  icon={<span className='icon-checkbox-empty' />}
                  checkedIcon={<span className='icon-checkbox-checked' />}
                />
              }
              label={item.name}
            />
          ))}
        </FormGroup>
      </div>

      <div className='relevance'>
        <h4>Relevance</h4>

        <FormGroup row className='relevance-checkboxes'>
          {relevanceCheckboxes.map((item) => (
            <FormControlLabel
              key={item}
              onChange={(event) =>
                handleFilters('relevance', event.target.name)
              }
              control={
                <Checkbox
                  checked={
                    currentModule?.filters?.relevance?.includes(item) || false
                  }
                  name={item}
                  icon={<span className='icon-checkbox-empty' />}
                  checkedIcon={<span className='icon-checkbox-checked' />}
                />
              }
              label={item}
            />
          ))}
        </FormGroup>
      </div>

      <div className='alert-types'>
        <h4>Types</h4>

        <Autocomplete
          multiple
          value={currentModule?.filters?.types || []}
          options={filterOptions?.scenario?.types || []}
          getOptionSelected={(option, value) => option.id === value.id}
          disableCloseOnSelect
          onChange={(e, newValue) => handleFilters('types', newValue)}
          getOptionLabel={(option) => option.name}
          renderOption={(option, props) => {
            return (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  minHeight: 30,
                }}
                className='sector-checkbox'
              >
                <div>
                  <Checkbox
                    style={{ padding: 0, marginRight: 10 }}
                    checked={props.selected}
                    icon={<span className='icon-checkbox-empty' />}
                    checkedIcon={<span className='icon-checkbox-checked' />}
                  />

                  <span>{option.name}</span>
                </div>
              </div>
            )
          }}
          renderInput={(params) => {
            params.InputProps.startAdornment = params.InputProps.startAdornment
              ? `Types Selected: ${params.InputProps.startAdornment.length}`
              : ''
            return (
              <TextField
                {...params}
                style={{ color: '#fff' }}
                variant='outlined'
                placeholder='Search types'
              />
            )
          }}
        />
      </div>

      <div className='scenario-frameworks'>
        <h4>Frameworks</h4>

        <Autocomplete
          multiple
          value={currentModule?.filters?.frameworks || []}
          options={filterOptions?.scenario?.frameworks || []}
          getOptionSelected={(option, value) => option.id === value.id}
          disableCloseOnSelect
          onChange={(e, newValue) => handleFilters('frameworks', newValue)}
          getOptionLabel={(option) => option.name}
          renderOption={(option, props) => {
            return (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  minHeight: 30,
                }}
                className='sector-checkbox'
              >
                <div>
                  <Checkbox
                    style={{ padding: 0, marginRight: 10 }}
                    checked={props.selected}
                    icon={<span className='icon-checkbox-empty' />}
                    checkedIcon={<span className='icon-checkbox-checked' />}
                  />

                  <span>{option.name}</span>
                </div>
              </div>
            )
          }}
          renderInput={(params) => {
            params.InputProps.startAdornment = params.InputProps.startAdornment
              ? `Frameworks selected: ${params.InputProps.startAdornment.length}`
              : ''
            return (
              <TextField
                {...params}
                style={{ color: '#fff' }}
                variant='outlined'
                placeholder='Search frameworks'
              />
            )
          }}
        />
      </div>

      <div>
        <DateRangePickerComponent
          selectedDateRange={currentModule?.filters?.dateRange || {}}
          handleFilters={handleFilters}
        />
      </div>

      <div className='sector'>
        <h4>Groups</h4>

        <GlobalAutocomplete
          filters={currentModule?.filters}
          handleFilters={handleFilters}
          filterOptions={
            {
              groups: user?.user_access_groups_per_content['scenario'],
            } || []
          }
          type='groups'
        />
      </div>

      <div className='sector'>
        <h4>Sectors</h4>

        <Autocomplete
          multiple
          value={currentModule?.filters?.sectors || []}
          options={filterOptions?.scenario?.sectors || []}
          getOptionSelected={(option, value) => option.id === value.id}
          disableCloseOnSelect
          onChange={(e, newValue) => handleFilters('sectors', newValue)}
          getOptionLabel={(option) => option.name}
          renderOption={(option, props) => {
            return (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  minHeight: 30,
                }}
                className='sector-checkbox'
              >
                <div>
                  <Checkbox
                    style={{ padding: 0, marginRight: 10 }}
                    checked={props.selected}
                    icon={<span className='icon-checkbox-empty' />}
                    checkedIcon={<span className='icon-checkbox-checked' />}
                  />

                  <span>{option.name}</span>
                </div>
              </div>
            )
          }}
          renderInput={(params) => {
            params.InputProps.startAdornment = params.InputProps.startAdornment
              ? `Sectors Selected: ${params.InputProps.startAdornment.length}`
              : ''
            return (
              <TextField
                {...params}
                style={{ color: '#fff' }}
                variant='outlined'
                placeholder='Search Sectors'
              />
            )
          }}
        />
      </div>

      <Button
        color='primary'
        style={{
          color: '#fff',
          backgroundColor: '#006fff',
          marginTop: '20px',
          width: '100%',
          textTransform: 'capitalize',
          fontWeight: 700,
        }}
        onClick={() => {
          handleSubmit()
          const { innerWidth: width } = window

          width < 1200 && setFiltersOpen(false)
        }}
        type='submit'
      >
        Filter results
      </Button>
    </div>
  )
}

export default Filters
