import {
  Backdrop,
  CircularProgress,
  Divider,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import React, { useState, Fragment, useRef } from 'react'
import ContentDatepicker from '../../shared/Components/ContentDatepicker'
import Gallery from '../../shared/Components/Gallery'
import CreateContentHeader from '../../shared/Components/microcomponents/CreateContentHeader'
import AssociatedProfiles from '../../shared/Components/AssociatedProfiles'
import AssociatedAlerts from '../../shared/Components/AssociatedAlerts'
import ContentDistribution from '../../shared/Components/ContentDistribution'
import SectorRelevance from '../../shared/Components/SectorRelevance'
import PdfSettings from '../../shared/Components/PdfSettings'
import Tlp from '../../shared/Components/Tlp'
import AuthorSettings from '../../shared/Components/AuthorSettings'
import Connectors from '../../shared/Components/Connectors'
import Capability from '../../shared/Components/Capability'
import ConnectorsWithModal from '../../shared/Components/ConnectorsWithModal'
import Ioc from '../../shared/Components/Ioc'
import MitreTags from '../../shared/Components/MitreTags'
import { useGlobalContext } from '../../../../context/globalContext'
import LatLong from '../../shared/Components/LatLong/LatLong'
import { useCreateManagementContext } from '../../../../context/createManagementState'
import FroalaEditorComponent from '../../../../shared/FroalaEditorComponent/FroalaEditorComponent'

const DateComponent = ({ fields, data }) => {
  return (
    <div>
      <h5>Dates</h5>

      <div className='datepicker'>
        {fields.map((field) => (
          <ContentDatepicker
            key={field.name}
            data={data}
            name={field.name}
            label={field.label}
          />
        ))}
      </div>

      <Divider style={{ margin: '20px 0' }} />
    </div>
  )
}

const ContentSection = ({
  configuration,
  handleCompleteSections,
  handleExpandedSections,
  handleSectionStatus,
  setToastMessage,
  handleAutosave,
  isSaving,
}) => {
  const { isContentBeingEdited } = useGlobalContext()
  const { createContentState, handleCreateState } = useCreateManagementContext()
  configuration.forEach((section) => {
    const hasRequiredText =
      section?.headline?.split(' ')?.slice(-1)[0] === '(required)'
    if (hasRequiredText) {
      section.headline = section?.headline?.split(' ')?.slice(0, -1).join(' ')
    }
  })

  const [isLoading, setLoading] = useState(false)
  const knownAsInput = useRef(null)

  const returnDataPerSection = (section) => {
    if (isSaving) return true
    if (section === 'overview') {
      return (
        Object.keys(createContentState?.dev_content?.overview?.content)
          .length === 0
      )
    } else if (section === 'assessment') {
      return !createContentState.dev_content[section]?.content?.text
    } else if (
      section === 'type-tags' ||
      section === 'country-of-origin' ||
      section === 'language'
    ) {
      return !createContentState.dev_content[section]?.content?.connectors
        ?.length
    } else if (section === 'capability-score') {
      return !createContentState.dev_priority
    } else if (section === 'tags') {
      return (
        Object.keys(createContentState.dev_content?.tags?.content).length === 0
      )
    } else if (section === 'category') {
      return (
        Object.keys(createContentState.dev_content?.category?.content)
          .length === 0
      )
    } else if (section === 'dates') {
      if (createContentState.dev_additional_dates) {
        return Object.keys(createContentState.dev_additional_dates).length === 0
      } else return true
    } else if (section === 'content-distribution') {
      const isOriginOrg =
        isContentBeingEdited[1] &&
        createContentState.dev_content[section]?.content?.organisations &&
        Object.values(
          createContentState.dev_content[section]?.content?.organisations,
        ).find((org) => org.is_origin)
      return !isOriginOrg
    } else if (section === 'tlp') {
      return (
        !createContentState.dev_tlp ||
        !createContentState.dev_tlp?.colour?.length
      )
    } else if (section === 'targets' || section === 'target-geography') {
      return (
        createContentState.dev_content[section]?.content &&
        !Object.keys(createContentState.dev_content[section]?.content).length
      )
    } else if (section === 'mitre-tags') {
      return (
        createContentState.tag_tactics &&
        !Object.keys(createContentState?.tag_tactics)?.length
      )
    } else if (section === 'ioc') {
      return !createContentState.ioc?.data?.length
    } else if (section === 'associated-profiles') {
      return !createContentState.related?.length
    } else if (section === 'associated-alerts') {
      return !createContentState.related_alerts?.length
    } else if (section === 'sector-relevance') {
      return false //!createContentState.relevance?.sectors?.length
    } else if (section === 'malware') {
      return (
        Object.keys(createContentState.dev_content.malware?.content).length ===
          0 ||
        (!createContentState.dev_content.malware?.content?.malware_family
          ?.length &&
          !createContentState.dev_content.malware.content?.malware_type
            ?.length &&
          !createContentState.dev_content.malware.content?.gallery?.length &&
          !createContentState.dev_content.malware.content?.connectors?.length)
      )
    } else if (
      section === 'malware-and-tools' ||
      section === 'reconnaissance' ||
      section === 'weaponisation' ||
      section === 'delivery' ||
      section === 'exploitation' ||
      section === 'installation' ||
      section === 'command-and-control' ||
      section === 'actions-on-objectives'
    ) {
      return (
        Object.keys(createContentState.dev_content[section]?.content).length ===
          0 ||
        (!createContentState.dev_content[section].content?.text?.length &&
          !createContentState.dev_content[section].content?.gallery?.length &&
          !createContentState.dev_content[section]?.content?.connectors?.length)
      )
    } else if (
      section === 'availability' ||
      section === 'operations' ||
      section === 'incidents' ||
      section === 'preparation' ||
      section === 'anatomy-of-attack'
    ) {
      return !createContentState.dev_content[section].content.text
    } else if (section === 'resources-and-proficiency') {
      return (
        Object.keys(createContentState.dev_content[section].content).length ===
          0 ||
        (!createContentState.dev_content[section].content?.resources?.length &&
          !createContentState.dev_content[section].content?.family?.length)
      )
    } else if (
      section === 'ttps' ||
      section === 'additional-technical-details' ||
      section === 'protective-mechanisms' ||
      section === 'persistence-techniques' ||
      section === 'network-behaviour' ||
      section === 'motivation' ||
      section === 'intended-effect'
    ) {
      return (
        !createContentState.dev_content[section]?.content?.text &&
        !createContentState.dev_content[section].content?.connectors?.length
      )
    }
  }

  const shouldRender = ({ internal_key, is_sidebar_section }) => {
    if (
      internal_key === 'update-summary' &&
      createContentState?.published !== 1
    ) {
      return false
    }
    return (
      createContentState?.dev_content[internal_key] &&
      !is_sidebar_section &&
      internal_key !== 'title'
    )
  }

  return (
    <div className='create-alert__content-container'>
      {isLoading && (
        <Backdrop open={isLoading} style={{ zIndex: 9 }}>
          <CircularProgress />
        </Backdrop>
      )}

      <div className='create-alert__left'>
        <div className='content-title'>
          <TextField
            value={createContentState?.dev_title || ''}
            onChange={(event) =>
              handleCreateState('dev_title', event.target.value, true)
            }
            onBlur={async (event) =>
              !isSaving &&
              (await handleAutosave('titleChange', event.target.value))
            }
            placeholder='Enter content title here'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span className='label-required'>Required</span>
                </InputAdornment>
              ),
            }}
            variant='outlined'
          />

          {configuration?.filter(
            (section) =>
              section.internal_key === 'title' && section.columns.dev_known_as,
          )[0] && (
            <TextField
              inputRef={knownAsInput}
              defaultValue={createContentState.dev_known_as || ''}
              onBlur={() =>
                handleCreateState(
                  'dev_known_as',
                  knownAsInput.current.value,
                  true,
                )
              }
              placeholder='Aliases'
              style={{ marginTop: 15 }}
              inputProps={{ style: { padding: 14, fontSize: 14 } }}
              variant='outlined'
            />
          )}
        </div>

        {configuration.map(
          (section) =>
            shouldRender(section) && (
              <div key={section.internal_key} className='content-section'>
                <CreateContentHeader
                  handleCompleteSections={handleCompleteSections}
                  handleExpandedSections={handleExpandedSections}
                  sectionData={
                    createContentState.dev_content[section.internal_key]
                  }
                  sectionSettings={section}
                  shouldBeDisabled={returnDataPerSection(section.internal_key)}
                  status={createContentState.status_id}
                  isManager={createContentState.can_publish_or_deny}
                  handleSectionStatus={handleSectionStatus}
                />

                {createContentState.dev_content[section.internal_key]
                  .is_expanded && (
                  <div
                    className={`content-section__content${
                      section.internal_key === 'resources-and-proficiency'
                        ? ' two-editors'
                        : ''
                    }`}
                  >
                    {Object.entries(section.columns).map((component) => {
                      const type = component[1].type

                      return (
                        <Fragment key={component[0]}>
                          {type === 'tinymce' && (
                            <div className='editor-wrapper'>
                              <FroalaEditorComponent
                                label={component[1].label}
                                internal_key={section.internal_key}
                                textKey={component[0]}
                              />
                            </div>
                          )}

                          {type === 'gallery' && (
                            <Gallery
                              data={
                                createContentState.dev_content[
                                  section.internal_key
                                ]
                              }
                              setLoading={setLoading}
                              isLoading={isLoading}
                              setToastMessage={setToastMessage}
                              internal_key={section.internal_key}
                            />
                          )}

                          {type === 'datepicker' && (
                            <DateComponent
                              fields={component[1].fields}
                              data={createContentState.dev_additional_dates}
                            />
                          )}

                          {type === 'ioc' && (
                            <Ioc
                              data={createContentState.ioc || []}
                              id={createContentState.id}
                              type='profile'
                            />
                          )}

                          {type ===
                            'mitre-navigator-tags-selection-summary' && (
                            <MitreTags
                              tagTactics={createContentState.tag_tactics || []}
                              devContent={createContentState.dev_content}
                              tags={createContentState.tags || []}
                              devTags={createContentState.tags_dev || []}
                              internal_key={section.internal_key}
                              type='profile'
                            />
                          )}

                          {type === 'connectors' &&
                            component[1].tags_search_popup && (
                              <ConnectorsWithModal
                                data={
                                  createContentState.dev_content[
                                    section.internal_key
                                  ]
                                }
                                devContent={createContentState.dev_content}
                                internal_key={section.internal_key}
                                categories={
                                  component[1].tags_search_popup_tag_categories
                                }
                              />
                            )}

                          {type === 'connectors' &&
                            !component[1].tags_search_popup && (
                              <Connectors
                                data={
                                  createContentState.dev_content[
                                    section.internal_key
                                  ]
                                }
                                internal_key={section.internal_key}
                                name={section.headline}
                                categorySlug={component[1].categorySlug}
                                tagTactics={
                                  createContentState.tag_tactics || []
                                }
                                devContent={createContentState.dev_content}
                                tags={createContentState.tags || []}
                                devTags={createContentState.tags_dev || []}
                                popularTags={component[1].hint_tags || []}
                              />
                            )}

                          {type === 'associated-profiles' && (
                            <AssociatedProfiles
                              isExpanded={
                                createContentState.dev_content[
                                  section.internal_key
                                ].is_expanded
                              }
                            />
                          )}

                          {type === 'associated-alerts' && (
                            <AssociatedAlerts
                              isExpanded={
                                createContentState.dev_content[
                                  section.internal_key
                                ].is_expanded
                              }
                            />
                          )}

                          {type === 'content-distribution' &&
                            isContentBeingEdited && (
                              <ContentDistribution
                                data={
                                  createContentState.dev_content[
                                    section.internal_key
                                  ]
                                }
                                internal_key={section.internal_key}
                                type='profile'
                              />
                            )}

                          {type === 'tinymce-table-column-name-binded' &&
                            createContentState.published === 1 && (
                              <FroalaEditorComponent
                                label='Update summary'
                                internal_key={section.internal_key}
                                textKey='text'
                              />
                            )}
                        </Fragment>
                      )
                    })}
                  </div>
                )}
              </div>
            ),
        )}
      </div>

      <div className='create-alert__right'>
        {configuration.map(
          (section) =>
            createContentState.dev_content[section.internal_key] &&
            section.is_sidebar_section && (
              <div key={section.internal_key} className='content-section'>
                <CreateContentHeader
                  handleCompleteSections={handleCompleteSections}
                  handleExpandedSections={handleExpandedSections}
                  sectionData={
                    createContentState.dev_content[section.internal_key]
                  }
                  sectionSettings={section}
                  shouldBeDisabled={returnDataPerSection(section.internal_key)}
                  status={createContentState.status_id}
                  isManager={createContentState.can_publish_or_deny}
                  handleSectionStatus={handleSectionStatus}
                />

                {((createContentState.can_publish_or_deny &&
                  !createContentState.dev_content[section.internal_key]
                    .is_rejected &&
                  !createContentState.dev_content[section.internal_key]
                    .is_accepted) ||
                  (!createContentState.can_publish_or_deny &&
                    !createContentState.dev_content[section.internal_key]
                      .is_complete)) &&
                  Object.values(section.columns).map((component) => (
                    <Fragment key={component.type}>
                      {component.type === 'capability' && (
                        <Capability
                          data={createContentState.dev_priority || ''}
                          internal_key={section.internal_key}
                        />
                      )}

                      {component.type === 'sector-relevance' && (
                        <SectorRelevance
                          data={{
                            sectors: createContentState.relevance
                              ? createContentState.relevance.sectors
                              : [],
                            sector_priorities: createContentState.relevance
                              ? createContentState.relevance.sector_priorities
                              : [],
                          }}
                          internal_key={section.internal_key}
                          type='profile'
                        />
                      )}

                      {component.type === 'pdf-settings' && (
                        <PdfSettings
                          data={{
                            dev_are_numbered_headings_enabled:
                              createContentState.dev_are_numbered_headings_enabled,
                            dev_is_table_included_in_pdf:
                              createContentState.dev_is_table_included_in_pdf,
                            dev_pdf_cover_enabled:
                              createContentState.dev_pdf_cover_enabled,
                          }}
                          internal_key={section.internal_key}
                        />
                      )}

                      {component.type === 'tlp' && isContentBeingEdited && (
                        <Tlp internal_key={section.internal_key} />
                      )}

                      {component.type === 'author-settings' &&
                        isContentBeingEdited && (
                          <AuthorSettings
                            data={{
                              dev_hide_all_authors_data:
                                createContentState.dev_hide_all_authors_data,
                              dev_show_authors_data:
                                createContentState.dev_show_authors_data,
                              dont_show_authors:
                                createContentState.dont_show_authors,
                              author_organisation:
                                createContentState.author_organisation,
                              author_name: createContentState.author_name,
                            }}
                            internal_key={section.internal_key}
                          />
                        )}

                      {component.type === 'lat-long' && <LatLong />}
                    </Fragment>
                  ))}
              </div>
            ),
        )}
      </div>
    </div>
  )
}

export default ContentSection
