/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */

import { useEffect, useRef, useState } from 'react'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import { neighbors, degrees, betweenness } from 'regraph/analysis'
import mapValues from 'lodash/mapValues'
import isEmpty from 'lodash/isEmpty'
import { debounce, cloneDeep, isEqual, uniqBy, unionBy } from 'lodash'
import has from 'lodash/has'

import useNotification from '../../hooks/useNotification'
import { useViewStateContext } from '../../context/viewStateContext'
import {
  contentList,
  expandAllAssociations,
  expandOther,
  expandProfileOrTag,
  fetchInitialPiece,
  nodeContent,
  nodeDetails,
  fetchAnalysis,
  deleteAnalysis,
} from '../../shared/services/api/linkAnalysis/linkAnalysis'
import { layoutsList } from './layouts'
import { useQueryGetListAnalysis } from '../../shared/services/queries/link-analysis/useQueryGetListAnalysis'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

const isLink = (item) => has(item, 'id1')
const isSummaryLink = (id) => id?.startsWith('_combolink_')
const isCombo = (id) => id && id.startsWith('_combonode_')

const useRegraph = (view) => {
  const {
    addWorkspace,
    setWorkspaces,
    workspaces,
    linkAnalysisState,
    setLinkAnalysisState,
    linkAnalysisOpen,
    setLinkAnalysisOpen,
    dirtyCreateAnalysisState,
    setDirtyCreateAnalysisState,
  } = useViewStateContext()
  const chartRef = useRef(null)
  const timebarRef = useRef(null)
  const [selection, setSelection] = useState(
    linkAnalysisOpen ? linkAnalysisState?.extras?.selection : {},
  )
  const [isLoading, setLoading] = useState(false)
  const [nodesToAutocomplete, setNodesToAutocomplete] = useState([])
  const [menu, setMenu] = useState({ visible: false, x: 0, y: 0 })
  const [isFetching, setFetching] = useState(false)
  const [detailsShown, setDetailsShown] = useState(
    linkAnalysisOpen && linkAnalysisState?.extras?.detailsShown,
  )
  const [apiMessage, setApiMessage] = useState('')
  const [currentLayout, setCurrentLayout] = useState(undefined)
  const [foreground, setForeground] = useState({})
  const [animationTime, setAnimationTime] = useState(0)
  const [handMode, setHandMode] = useState(true)
  const [searchOpen, setSearchOpen] = useState(false)
  const [combos, setCombos] = useState(
    linkAnalysisOpen
      ? linkAnalysisState?.extras?.combos
      : { openCombos: {}, combine: { level: 0, properties: [] } },
  )
  const [scores, setScores] = useState({ items: {}, betweenness: null })
  const [dropTarget, setDropTarget] = useState({
    current: null,
    previous: null,
  }) // Previous tracked to remove highlighting
  const [timebarOpen, setTimebarOpen] = useState(false)
  const [timeoutMessage, setTimeoutMessage] = useState(undefined)
  const [isTimedOut, setIsTimedOut] = useState(null)
  const [changeGroupNameInput, setChangeGroupNameInput] = useState(false)
  const [groupNamePositions, setGroupNamePositions] = useState({
    left: 0,
    top: 0,
  })
  const [isLoadedAnalysis, setIsLoadedAnalysis] = useState(false)
  const groupInputRef = useRef(null)
  const [lookup, setLookup] = useState({})
  const getAccessToken = () => {
    return localStorage.getItem('access_token')
  }
  let comboLookup = useRef(
    linkAnalysisOpen ? linkAnalysisState?.extras?.comboLookup?.current : {},
  )
  let nextComboId = useRef(0)
  const [isView, setIsView] = useState(view)
  const [searchPhrase, setSearchPhrase] = useState('')
  const [isLoadingList, setIsLoadingList] = useState(false)
  const { warningToast, successToast, infoToast } = useNotification()
  const [page, setPage] = useState(1)
  const [analysisName, setAnalysisName] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  // const id = menu?.id?.replace(/[^0-9]/g, '')
  // const contentType = menu?.id?.replace(/[^a-zA-Z]+/g, '')
  const queryClient = useQueryClient()

  const analysisList = useQueryGetListAnalysis(page, searchPhrase)

  // const allExpandedAssociations = useQuery({
  //   queryKey: ['all-associations', { id, contentType }],
  //   queryFn: () => expandAllAssociations(getAccessToken(), id, contentType),
  //   staleTime: Infinity,
  //   keepPreviousData: false,
  //   enabled: !!id,
  // })

  if (
    isView &&
    !searchOpen &&
    searchPhrase &&
    analysisList?.data?.list?.data?.length === 0
  ) {
    warningToast({
      message: 'No analysis found',
    })
  }

  const { mutate: deleteSpecificAnalysis } = useMutation({
    mutationFn: deleteAnalysis,
    onMutate: () => {
      setDeleting(true)
    },
    onSuccess: () => {
      setDeleteModal(undefined)
      setDeleting(false)
      successToast({
        message: 'Analysis deleted successfully',
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(['link-analysis', { page: 1 }])
    },
  })

  const mapViewItems = (state) => {
    let currentState = cloneDeep(state)
    Object.entries(currentState)?.forEach((item) => {
      const key = item[0]
      const value = item[1]
      if (!value?.coordinates?.length) return false
      if (value?.coordinates?.length === 1) {
        value.coordinates = value?.coordinates[0]
      }
      if (value?.coordinates?.length >= 2) {
        value?.coordinates.forEach((coordinate, index) => {
          if (index === 0) {
            value.coordinates = coordinate
          } else {
            const newKey = key + '_' + index
            currentState = {
              ...currentState,
              [newKey]: {
                ...value,
                coordinates: coordinate,
                isDuplicate: true,
              },
            }
          }
        })
      }
    })
    Object.entries(currentState)?.forEach((item) => {
      const key = item[0]
      const value = item[1]
      if (!value.isDuplicate) return false
      const links = cloneDeep(value?.links)
      const newLinks = links?.map((link) => (link = { ...link, id1: key }))
      value.links = newLinks
      value?.links?.forEach((link) => {
        const linkName = link?.id1 + '_' + link?.id2
        currentState[linkName] = link
      })
    })
    return currentState
  }

  const isLinkAnalysisEqual = () => {
    return isEqual(
      cloneDeep({
        items: linkAnalysisState.currentChart.items,
        positions: linkAnalysisState.currentChart.positions,
      }),
      cloneDeep(dirtyCreateAnalysisState),
    )
  }
  // const getDropTargetId = (dropTargetId) => {
  //   // If dropped onto a node/dummy node convert to parent combo id
  //   return !isCombo(dropTargetId) && dropTargetId;
  // };

  const handleDragStart = ({ setDragOptions, type: dragType, id }) => {
    // setState((current) => {
    //   return { ...current, animation: { animate: false } };
    //}); // Prevents highlighting cancelling drag
    if (dragType !== 'node' || isCombo(id)) {
      setDropTarget(null)
      return // Default drag behaviour except when dragging one of the nodes
    }
    // setDropTarget({ current: getParentComboId(state.currentChart.items[id]), previous: 'initial' });

    // Prevents combos moving with their nodes
    setDragOptions({ includeCombos: false })
  }

  const handleDragOver = ({ id }) => {
    if (id === undefined) return false
    setAnimationTime(500)
    if (id !== null && id.startsWith('_combonode')) {
      setDropTarget({ current: id, previous: null })
    } else setDropTarget({ current: null, previous: null })
  }

  const handleDragEnd = ({ draggedItems }) => {
    // if (dropTarget === null || dropTarget.current === null) return false
    setAnimationTime(500)
    const draggedIds = Object.keys(draggedItems)
    if (draggedIds.length === 0) {
      return
    }

    const id = draggedIds[0]
    if (id === null || isCombo(id)) {
      return // If nothing or a dummy node is being dragged, do nothing.
    }

    if (dropTarget === null) {
      return false
    }

    const { current: dropTargetId } = dropTarget

    const newItems = cloneDeep({ ...linkAnalysisState.currentChart.items })

    if (dropTarget.current === null) {
      Object.keys(newItems[id]?.data)?.forEach((key) => {
        if (key.startsWith('level')) {
          delete newItems[id].data[key]
        }
      })
    }

    // If dropped onto a combo update data to include the relevant properties
    if (isCombo(dropTargetId)) {
      const comboGroups = dropTargetId.split('_').slice(2)
      // Multiple properties need to be updated to add to a sub combo
      for (let i = 0; i < comboGroups.length; i += 1) {
        newItems[id].data[`level${i + 1}`] = comboGroups[i]
      }
    }

    setDropTarget({ current: null, previous: null }) // reset drop target

    if (
      isEqual(
        newItems[id]?.data,
        linkAnalysisState?.currentChart?.items[id]?.data,
      )
    )
      return false
    const newChart = {
      items: newItems,
      positions: { ...linkAnalysisState?.currentChart?.positions },
    }
    add(newChart)
    setLinkAnalysisState((current) => ({
      ...current,
      count: current?.count + Object.keys(newItems)?.length - 1,
      extras: {
        ...current?.extras,
        analysisName: analysisName,
        comboLookup: comboLookup,
        combos: combos,
        currentLayout: currentLayout,
        selection: selection,
      },
    }))
  }

  const lowestCombineLevelOnCombo = (combine, combo) => {
    for (let i = 0; i < combine?.properties?.length; i += 1) {
      const level = combine?.properties[i]
      if (combo[level]) {
        return level
      }
    }
    return undefined
  }

  const underlyingSelectedNodes = () => {
    let underlying = {}
    Object.keys(selection)?.forEach((id) => {
      const nodes = comboLookup?.current[id]?.nodes
      if (nodes) {
        underlying = { ...underlying, ...nodes }
      } else if (!isLink(id) && !isSummaryLink(id)) {
        underlying[id] = selection[id]
      }
    })
    return underlying
  }

  const combineNodesHandler = ({ setStyle, id, nodes, combo }) => {
    const { openCombos, combine } = combos

    if (changeGroupNameInput) {
      const selected = Object.keys(selection)[0]

      comboLookup.current[selected].name = groupInputRef?.current?.value

      setStyle({
        label: {
          text:
            comboLookup.current[id].name === undefined
              ? 'Group'
              : comboLookup.current[id].name,
          center: false,
          backgroundColor: 'transparent',
          color: '#000',
        },
        open: !!openCombos[id],
        size: 1.75,
        color: '#eefcf8',
        border: { width: 1, color: '#8cedd0' },
        arrange: 'concentric',
        closedStyle: {
          color: '#2dcda8',
          border: {
            width: 4,
            color: '#326153',
          },
        },
      })

      setChangeGroupNameInput(false)
      let currentLocalStorage = JSON.parse(
        localStorage.getItem('ls.nodesPositions'),
      )
      currentLocalStorage.comboLookup = comboLookup
    } else {
      // the lookup property needs to be the lowest level in the combo list

      if (isLoadedAnalysis) {
        comboLookup.current[id] = {
          nodes,
          combo,
          property: lowestCombineLevelOnCombo(combine, combo),
          name: lookup?.current[id]?.name || 'Group',
        }
      } else {
        comboLookup.current[id] = {
          nodes,
          combo,
          property: lowestCombineLevelOnCombo(combine, combo),
          name: comboLookup?.current[id]?.name || 'Group',
        }
      }

      setStyle({
        label: {
          text: comboLookup?.current[id]?.name,
          center: false,
          backgroundColor: 'transparent',
          color: '#000',
        },
        open: !!openCombos[id],
        size: 1.75,
        color: '#eefcf8',
        border: { width: 1, color: '#8cedd0' },
        arrange: 'concentric',
        closedStyle: {
          color: '#2dcda8',
          border: {
            width: 4,
            color: '#326153',
          },
        },
      })
    }
  }

  const combineSelection = () => {
    if (Object.keys(selection)?.length === 0) return false
    setAnimationTime(750)

    // Calculate the new level of grouping.
    // If the selection contains combos, the new level needs to be higher than the
    // existing highest in the selection.
    const depths = Object.keys(selection)
      .filter((key) => comboLookup?.current[key])
      .map((key) => Object.keys(selection[key])?.length)

    const newLevel = depths.length > 0 ? Math.max(...depths) + 1 : 1

    // adding the same property to the nodes will cause them to combine
    const nodesToChange = underlyingSelectedNodes()

    nextComboId.current = Object.keys(comboLookup.current).length + 1

    const levelName = `level${newLevel}`
    const dataProp = { [levelName]: `combo${nextComboId.current}` }
    const withProperties = mapValues(nodesToChange, (node) =>
      merge({}, node, { data: dataProp }),
    )

    // 'higher' tells us if we have added a level. If we have then we'll
    // need to update the combine property too in the setState call.
    const higher = newLevel > combos?.combine?.level

    // combo.combine.level = higher ? newLevel : combo.combine.level
    // combo.combine.properties = higher ? combo.combine.properties.concat(levelName) : combo.combine.properties

    let newItems = {
      ...linkAnalysisState?.currentChart?.items,
      ...withProperties,
    }
    const newChart = { items: newItems, positions: {} }

    add(newChart)
    setLinkAnalysisState((current) => ({
      ...current,
      count: current?.count + Object.keys(newItems)?.length - 1,
      extras: {
        ...current?.extras,
        analysisName: analysisName,
        comboLookup: comboLookup,
        combos: combos,
        currentLayout: currentLayout,
        selection: selection,
      },
    }))

    setCombos((current) => {
      return {
        ...current,
        combine: {
          level: higher ? newLevel : combos?.combine?.level,
          properties: higher
            ? combos?.combine?.properties?.concat(levelName)
            : combos?.combine?.properties,
        },
      }
    })
  }

  const uncombineSelection = () => {
    setAnimationTime(750)
    let uncombined = {}

    if (Object.keys(selection)?.length !== 1) return false
    if (!Object.keys(selection)[0]?.startsWith('_combonode')) return false

    Object.keys(selection)?.forEach((id) => {
      if (comboLookup?.current[id]) {
        const { nodes, property } = comboLookup.current[id]
        const newNodes = mapValues(nodes, (node) =>
          omit(node, `data.${property}`),
        )
        uncombined = { ...uncombined, ...newNodes }
      }
    })

    let newItems = { ...linkAnalysisState?.currentChart?.items, ...uncombined }
    const newChart = { items: newItems, positions: {} }

    add(newChart)
    setLinkAnalysisState((current) => ({
      ...current,
      items: newItems,
      count: current?.count + Object.keys(newItems)?.length - 1,
      extras: {
        ...current?.extras,
        analysisName: analysisName,
        comboLookup: comboLookup,
        combos: combos,
        currentLayout: currentLayout,
        selection: selection,
      },
    }))
  }
  const fetchList = debounce(async (value, page, shouldMergeResults) => {
    if (!shouldMergeResults) {
      setNodesToAutocomplete([])
    }
    if (value?.length > 1) {
      setFetching(true)
      try {
        const response = await contentList(getAccessToken(), {
          filters: { keyword: value },
          pagination: {
            per_page: 20,
            page: page,
          },
        })

        if (shouldMergeResults && response?.data?.length === 0) {
          infoToast({
            message: 'No more results.',
          })
        }

        if (shouldMergeResults) {
          const newData = unionBy(
            nodesToAutocomplete,
            uniqBy(response.data, 'id'),
            'id',
          )
          setNodesToAutocomplete(newData)
        } else {
          setNodesToAutocomplete(response?.data)
        }
        setFetching(false)
        return 'finished'
      } catch (error) {
        console.log(error)
      }
    }
  }, 600)

  const handleBlur = () => {
    setNodesToAutocomplete([])
  }

  const setLayout = (id) => {
    setAnimationTime(750)
    setCurrentLayout(id)
    setLinkAnalysisState({
      ...linkAnalysisState,
      currentChart: {
        items: { ...linkAnalysisState.currentChart.items },
        layout: id,
        positions: {},
      },
      extras: {
        ...linkAnalysisState.extras,
        analysisName: analysisName,
        comboLookup: comboLookup,
        combos: combos,
        currentLayout: currentLayout,
        selection: selection,
      },
    })
    const newChart = {
      items: { ...linkAnalysisState.currentChart.items },
      layout: id,
      positions: {},
    }
    add(newChart)
    setTimeout(() => chartRef.current.fit('all'), 200)
  }

  const handleSnackbarClose = () => {
    setApiMessage('')
  }

  const loadInitialPiece = async ({ module, id }) => {
    setLoading(true)
    setAnimationTime(750)

    try {
      const response = await fetchInitialPiece(getAccessToken(), module, id)
      const nodeName = module + '_' + id

      response[nodeName]?.links && addSingleNode(response)
      setLinkAnalysisOpen(true)
      setSearchOpen(false)
      setLoading(false)
    } catch {}
  }

  const loadAnalysis = async (id) => {
    setLoading(true)
    setAnimationTime(750)

    try {
      const response = await fetchAnalysis(getAccessToken(), id)
      const linkAnalysisSource = JSON.parse(response?.data?.source)
      const analysisNodesPositions = JSON.parse(response?.data?.node_positions)
      const analysisExtras = JSON.parse(response?.data?.extras)

      if (response.error) {
        setLoading(false)
      }

      const clone = cloneDeep(linkAnalysisSource)
      let lay

      if (analysisNodesPositions) {
        lay = analysisNodesPositions.layout
        // setLayout(analysisNodesPositions.layout);
      }
      let pos = analysisNodesPositions?.positions || {}
      const newChart = { items: clone, positions: pos }

      setDirtyCreateAnalysisState(newChart)
      add(newChart)
      setLinkAnalysisState((current) => {
        return {
          ...current,
          layout: lay,
          extras: {
            ...analysisExtras,
            analysisName: response?.data?.title,
          },
        }
      })

      if (analysisExtras?.comboLookup) {
        comboLookup = analysisExtras.comboLookup
        setLookup(analysisExtras.comboLookup)
      }

      analysisExtras?.groups && setCombos(analysisExtras.groups)

      setIsLoadedAnalysis(true)
      setLinkAnalysisOpen(true)
      setSearchOpen(false)
      setLoading(false)

      setTimeout(() => chartRef.current.fit('all'), 500)
      setLoading(false)
      const currentWorkspace = workspaces.find(
        (workspace) => workspace.state === 'analysis',
      )

      currentWorkspace.edit = true
      setWorkspaces(workspaces)
      localStorage.setItem('workspaces', JSON.stringify(workspaces))
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    const currentWorkspace = workspaces.find(
      (workspace) => workspace.state === 'analysis',
    )
    const currentInitialPiece = currentWorkspace?.currentPiece || null
    const currentAnalysisId = currentWorkspace?.analysisId || null
    const isEditing = currentWorkspace?.edit

    if (currentAnalysisId && !isEditing) {
      const ignore = loadAnalysis(currentAnalysisId)
    } else if (currentInitialPiece) {
      const ignore = loadInitialPiece(currentInitialPiece || {})
    }
    if (!linkAnalysisOpen) {
      !view && setSearchOpen(true)
    }
  }, [linkAnalysisOpen])

  const openContextMenu = (props) => {
    event.preventDefault()
    const { x, y } = props
    const id = Object.keys(selection)[0]
    setMenu({ id, x: x, y: y, visible: true })
  }

  const suppressBrowserContextMenu = (evt) => {
    evt.preventDefault()
  }

  const handleClick = async () => {
    setMenu({ visible: false, x: 0, y: 0 })
    setChangeGroupNameInput(false)
  }

  const styledItems = () => {
    return mapValues(linkAnalysisState?.currentChart?.items, (item, id) => ({
      ...item,
      fade:
        (!timebarOpen && !isEmpty(foreground) && !foreground[id]) ||
        (timebarOpen && item.isInRange !== true),
      color: item?.fontIcon?.outline || '#607d8b',
      label: {
        ...item?.label,
        backgroundColor: 'transparent',
        color: '#000',
      },
      size:
        (scores?.reset && item?.type === 'tag' && 1) ||
        (scores?.reset && item?.type !== 'tag' && 1.5) ||
        (!scores.reset && scores?.items && scores?.items[id] * 1),
    }))
  }

  const add = (newChart) => {
    const { undo, currentChart, count } = linkAnalysisState
    setLinkAnalysisState({
      undo: [currentChart, ...undo],
      redo: [],
      count,
      currentChart: newChart,
      extras: {
        analysisName: analysisName,
        comboLookup: comboLookup,
        combos: combos,
        currentLayout: currentLayout,
        selection: selection,
        detailsShown: detailsShown,
      },
    })
  }

  const doUndo = () => {
    setAnimationTime(550)
    const { undo, redo, currentChart } = linkAnalysisState
    if (undo.length > 0) {
      const [first, ...rest] = undo
      setLinkAnalysisState({
        undo: rest,
        redo: [currentChart, ...redo],
        currentChart: first,
      })
    }
  }

  const doRedo = () => {
    setAnimationTime(550)
    const { undo, redo, currentChart } = linkAnalysisState
    if (redo.length > 0) {
      const [first, ...rest] = redo
      setLinkAnalysisState({
        undo: [currentChart, ...undo],
        redo: rest,
        currentChart: first,
      })
    }
  }

  useEffect(() => {
    setTimeout(() => chartRef?.current?.fit('all'), 200)
  }, [])

  const centerCanvas = () =>
    setTimeout(() => chartRef?.current?.fit('all'), 200)

  useEffect(async () => {
    try {
      const neighbours = await neighbors(
        { ...linkAnalysisState?.currentChart?.items },
        selection,
      )

      setForeground({ ...neighbours, ...selection })
    } catch {}
  }, [selection, isLoading])

  const handleChartChange = async (change) => {
    setMenu({ visible: false, x: 0, y: 0 })
    if (change?.selection) {
      setSelection({ ...change.selection })
      setLinkAnalysisState((current) => {
        return {
          ...current,
          extras: {
            ...current.extras,
            selection: change.selection,
          },
        }
      })
    }
    const { positions, why } = change
    const { currentChart } = linkAnalysisState
    const newChart = { ...currentChart, ...change }
    setAnimationTime(0)

    if (positions) {
      if (why === 'user') {
        add(newChart)
      } else {
        setLinkAnalysisState((current) => {
          return {
            ...current,
            currentChart: newChart,
            extras: {
              analysisName: analysisName,
              comboLookup: comboLookup,
              combos: combos,
              currentLayout: currentLayout,
              selection: selection,
              detailsShown: detailsShown,
            },
          }
        })
      }
    }

    setIsLoadedAnalysis(false)
  }

  const deleteFromRegraph = (data, action) => {
    const clone = cloneDeep(linkAnalysisState?.currentChart?.items)
    setAnimationTime(750)
    if (data === undefined) return false

    if (action === 'closeAll') {
      const newChart = { items: {}, positions: {} }

      add(newChart)
      setLinkAnalysisState((current) => ({
        ...current,
        items: {},
        positions: {},
        count: current.count + Object.keys(clone).length - 1,
        extras: {
          analysisName: analysisName,
          comboLookup: comboLookup,
          combos: combos,
          currentLayout: currentLayout,
          selection: selection,
          detailsShown: detailsShown,
        },
      }))
    }

    if (action === 'closeSingle') {
      let item = Object.keys(data)[0]
      delete clone[item]

      const newChart = { items: clone, positions: {} }

      add(newChart)
      setLinkAnalysisState((current) => ({
        ...current,
        positions: {},
        count: current.count + Object.keys(clone).length - 1,
        extras: {
          ...current.extras,
          analysisName: analysisName,
          comboLookup: comboLookup,
          combos: combos,
          currentLayout: currentLayout,
          selection: selection,
        },
      }))
    }

    if (action === 'closeSingle' || action === 'closeAll') return false

    data?.forEach((link) => {
      const thisElement = link.id1
      const linkedElement = link.id2
      const linkName = link.id1 + '_' + link.id2
      const reversedLinkName = link.id2 + '_' + link.id1
      const linkExists =
        clone?.hasOwnProperty(linkName) ||
        clone?.hasOwnProperty(reversedLinkName)

      linkExists && action === 'closeLinked' && delete clone[linkedElement]

      if (linkExists && action === 'closeThisAndLinked') {
        delete clone[thisElement]
        delete clone[linkedElement]
      }
    })

    if (isEqual(clone, linkAnalysisState.currentChart.items)) return false

    const newChart = { items: clone, positions: {} }

    add(newChart)
    setLinkAnalysisState((current) => ({
      ...current,
      positions: {},
      count: current.count + Object.keys(clone).length - 1,
      extras: {
        ...current.extras,
        analysisName: analysisName,
        comboLookup: comboLookup,
        combos: combos,
        currentLayout: currentLayout,
        selection: selection,
      },
    }))
  }

  const handleCloseEvents = (data, typeOfEvent) => {
    if (data.length === 0) {
      delete linkAnalysisState?.currentChart?.items[menu?.id]
      return false
    }

    typeOfEvent === 'closeSingle' && deleteFromRegraph(data, 'closeSingle')
    typeOfEvent === 'closeAll' && deleteFromRegraph(data, 'closeAll')

    if (typeOfEvent === 'closeSingle' || typeOfEvent === 'closeAll')
      return false

    const linkedEntities = data.filter(
      (item) => item.id2.replace(/[^a-zA-Z]+/g, '') !== 'tag',
    )
    const linkedTags = data.filter(
      (item) => item.id2.replace(/[^a-zA-Z]+/g, '') === 'tag',
    )

    typeOfEvent === 'thisAndLinkedEntities' &&
      deleteFromRegraph(linkedEntities, 'closeThisAndLinked')
    typeOfEvent === 'thisAndLinkedTags' &&
      deleteFromRegraph(linkedTags, 'closeThisAndLinked')
    typeOfEvent === 'linkedEntities' &&
      deleteFromRegraph(linkedEntities, 'closeLinked')
    typeOfEvent === 'linkedTags' && deleteFromRegraph(linkedTags, 'closeLinked')
  }

  const addSingleNode = (data) => {
    if (data === undefined) return false
    setAnimationTime(750)
    let itemsToAdd = {}
    let nodeName = Object.keys(data)[0]
    let nodeData = Object.values(data)[0]
    itemsToAdd[nodeName] = nodeData
    let linkedContent = data[nodeName]?.links

    const nodeType = nodeName?.split('_')[0]

    if (nodeType === 'profile') {
      itemsToAdd[nodeName].fontIcon.text =
        'icon-' + itemsToAdd[nodeName].subtype
    }

    linkedContent?.forEach((link) => {
      const linkName = link.id1 + '_' + link.id2
      const reversedLinkName = link.id2 + '_' + link.id1
      const linkExists =
        linkAnalysisState?.currentChart?.items?.hasOwnProperty(linkName) ||
        linkAnalysisState?.currentChart?.items?.hasOwnProperty(
          reversedLinkName,
        ) ||
        itemsToAdd.hasOwnProperty(reversedLinkName) ||
        itemsToAdd.hasOwnProperty(reversedLinkName)

      if (!linkExists) {
        itemsToAdd[linkName] = link
      }

      // let type1 = link.id1.split('_')[0];
      // let type2 = link.id2.split('_')[0];

      link.width = 1

      delete link.label
    })

    const newChart = {
      items: { ...linkAnalysisState?.currentChart?.items, ...itemsToAdd },
      layout: layoutsList[0],
      positions: {},
    }

    add(newChart)
    setLinkAnalysisState((current) => ({
      ...current,
      count: current.count + Object.keys(itemsToAdd).length - 1,
      extras: {
        ...current.extras,
        analysisName: analysisName,
        comboLookup: comboLookup,
        combos: combos,
        currentLayout: currentLayout,
        selection: selection,
      },
    }))

    Object.keys(linkAnalysisState.currentChart.items).length > 1 &&
      setSelection(itemsToAdd)

    setTimeout(() => {
      timebarOpen && timebarRef.current.fit('all', 200)
    }, 50)
  }

  const addMultipleNodes = (data) => {
    if (data === undefined) return false
    setAnimationTime(750)
    let itemsToAdd = {}

    Object.entries(data)?.forEach((newNode) => {
      let nodeName = newNode[0]
      let nodeData = newNode[1]
      itemsToAdd[nodeName] = nodeData

      let linkedContent = itemsToAdd[nodeName]?.links

      const nodeType = nodeName?.split('_')[0]

      if (nodeType === 'profile') {
        itemsToAdd[nodeName].fontIcon.text =
          'icon-' + itemsToAdd[nodeName]?.subtype
      }

      linkedContent?.forEach((link) => {
        const linkName = link.id1 + '_' + link.id2
        const reversedLinkName = link.id2 + '_' + link.id1
        const linkExists =
          linkAnalysisState.currentChart.items.hasOwnProperty(linkName) ||
          linkAnalysisState.currentChart.items.hasOwnProperty(
            reversedLinkName,
          ) ||
          itemsToAdd.hasOwnProperty(reversedLinkName) ||
          itemsToAdd.hasOwnProperty(reversedLinkName)

        if (!linkExists) {
          itemsToAdd[linkName] = link
        }

        // let type1 = link.id1.split('_')[0];
        // let type2 = link.id2.split('_')[0];

        link.width = 1

        delete link.label
      })
    })

    const newChart = {
      items: { ...linkAnalysisState.currentChart.items, ...itemsToAdd },
      positions: {},
    }
    add(newChart)
    setLinkAnalysisState((current) => ({
      ...current,
      count: current.count + Object.keys(itemsToAdd).length - 1,
      extras: {
        ...current.extras,
        analysisName: analysisName,
        comboLookup: comboLookup,
        combos: combos,
        currentLayout: currentLayout,
        selection: selection,
      },
    }))

    setLoading(false)
  }

  const handleAddNode = (event, newValue, reason) => {
    setAnimationTime(750)
    const loadNodeDetails = async (id, type) => {
      setLoading(true)
      try {
        const response = await nodeDetails(getAccessToken(), type, id)

        addSingleNode(response)
        timebarOpen && setSelection({ ...response })
        setTimeoutMessage('Loading data. Please wait.')
        setLoading(false)
      } catch (error) {
        console.log(error)
        if (error?.response?.status === 524) {
          setTimeoutMessage(
            'Things are taking longer than usual. Please retry.',
          )
          setIsTimedOut(true)
        }
      }

      setLinkAnalysisOpen(true)
      setSearchOpen(false)
    }
    reason === 'clear'
      ? setNodesToAutocomplete([])
      : loadNodeDetails(newValue.id, newValue.content_type)
  }

  const handleAddEvents = async (linkedType, linkedSubtype) => {
    setLoading(true)
    setAnimationTime(750)
    const id = menu?.id?.replace(/[^0-9]/g, '')
    const contentType = menu?.id?.replace(/[^a-zA-Z]+/g, '')
    const isExpandAll = linkedType === undefined && linkedSubtype === undefined
    const isProfileOrTag =
      linkedType !== undefined && linkedSubtype !== undefined
    const isOther = linkedType !== undefined && linkedSubtype === undefined
    let response

    try {
      if (isExpandAll) {
        response = await expandAllAssociations(
          getAccessToken(),
          id,
          contentType,
        )
      } else if (isProfileOrTag) {
        response = await expandProfileOrTag(
          getAccessToken(),
          id,
          contentType,
          linkedType,
          linkedSubtype,
        )
      } else if (isOther) {
        response = await expandOther(
          getAccessToken(),
          id,
          contentType,
          linkedType,
        )
      }

      const resultNumber = Object.keys(response)?.length

      if (resultNumber === 0) {
        setApiMessage('No data found')
        setAnimationTime(0)
      } else {
        addMultipleNodes(response)
      }

      setTimeoutMessage('Loading data. Please wait.')
      setLoading(false)
    } catch (error) {
      if (error?.response?.status === 524) {
        setTimeoutMessage('Things are taking longer than usual. Please retry.')
        setIsTimedOut(true)
      }
    }
  }

  const contextMenuEvents = {
    selectAll: () => {
      setSelection({ ...linkAnalysisState.currentChart.items })
      setMenu({ visible: false, x: 0, y: 0 })
    },
    selectNone: () => {
      setSelection({})
      setMenu({ visible: false, x: 0, y: 0 })
    },
    close: () => {
      setMenu({ visible: false, x: 0, y: 0 })
      handleCloseEvents(selection, 'closeSingle')
    },
    closeAll: () => {
      setMenu({ visible: false, x: 0, y: 0 })
      handleCloseEvents(linkAnalysisState.currentChart.items, 'closeAll')
    },
    showDetails: () => {
      setDetailsShown(true)
      setLinkAnalysisState((current) => ({
        ...current,
        extras: {
          ...current.extras,
          detailsShown: true,
          selection: selection,
        },
      }))
      setMenu({ visible: false, x: 0, y: 0 })
    },
    takeMeThere: () => {
      const currentNode = selection[menu.id]
      const id = menu.id.split('_')[1]
      addWorkspace(
        currentNode.type === 'profile' ? currentNode.subtype : currentNode.type,
        id,
        currentNode?.data?.fullName,
        {},
      )
      setMenu({ visible: false, x: 0, y: 0 })
    },
    expandAll: async () => {
      setMenu({ visible: false, x: 0, y: 0 })
      handleAddEvents()
    },
    expandThreatActors: async () => {
      setMenu({ visible: false, x: 0, y: 0 })
      handleAddEvents('profile', 'threat-actor')
    },
    expandMalware: async () => {
      setMenu({ visible: false, x: 0, y: 0 })
      handleAddEvents('profile', 'malware-tools')
    },
    expandOperations: async () => {
      setMenu({ visible: false, x: 0, y: 0 })
      handleAddEvents('profile', 'operation')
    },
    expandIncidents: async () => {
      setMenu({ visible: false, x: 0, y: 0 })
      handleAddEvents('profile', 'incident')
    },
    expandAlerts: async () => {
      setMenu({ visible: false, x: 0, y: 0 })
      handleAddEvents('alert')
    },
    expandScenarios: async () => {
      setMenu({ visible: false, x: 0, y: 0 })
      handleAddEvents('scenario')
    },
    expandReports: async () => {
      setMenu({ visible: false, x: 0, y: 0 })
      handleAddEvents('report')
    },
    expandTags: async () => {
      setMenu({ visible: false, x: 0, y: 0 })
      handleAddEvents('tag', 'tag')
    },
    expandTtpTags: async () => {
      setMenu({ visible: false, x: 0, y: 0 })
      handleAddEvents('tag', 'ttp_tag')
    },
    closeThisAndLinked: () => {
      const links = Object.values(selection)[0].links

      setMenu({ visible: false, x: 0, y: 0 })
      handleCloseEvents(links, 'thisAndLinkedEntities')
    },
    closeThisAndTags: () => {
      const links = Object.values(selection)[0].links

      setMenu({ visible: false, x: 0, y: 0 })
      handleCloseEvents(links, 'thisAndLinkedTags')
    },
    closeLinked: () => {
      const links = Object.values(selection)[0].links

      setMenu({ visible: false, x: 0, y: 0 })
      handleCloseEvents(links, 'linkedEntities')
    },
    closeLinkedTags: () => {
      const links = Object.values(selection)[0].links

      setMenu({ visible: false, x: 0, y: 0 })
      handleCloseEvents(links, 'linkedTags')
    },
    rename: () => {
      setGroupNamePositions({ left: menu.x, top: menu.y })
      setChangeGroupNameInput(true)
      setMenu({ visible: false, x: 0, y: 0 })
    },
  }

  const zoom = (inOrOut) => {
    if (chartRef.current !== null) {
      chartRef.current.zoom(inOrOut)
    }
  }

  const toggleHandMode = () => {
    setHandMode(!handMode)
  }

  const handleDegrees = async () => {
    setAnimationTime(750)
    try {
      let result = await degrees(
        { ...linkAnalysisState.currentChart.items },
        { val: 'count' },
      )

      const newValues = () => {
        Object.entries(result)?.forEach((item) => {
          let key = item[0]
          let value = item[1]
          if (value === 0 || value === 1) {
            result[key] = 0.5
          } else if (value === 2) {
            result[key] = 0.75
          } else if (value >= 3 && value <= 6) {
            result[key] = 1
          } else if (value >= 7 && value <= 11) {
            result[key] = 2
          } else if (value >= 12 && value <= 16) {
            result[key] = 3
          } else if (value >= 17 && value <= 26) {
            result[key] = 4
          } else if (value > 26) {
            result[key] = 6
          }
        })
        return result
      }

      newValues()

      setScores({ items: result, betweenness: false, reset: false })
      setLayout(linkAnalysisState.layout)
      setTimeout(() => chartRef.current.fit('all'), 150)
    } catch {}
  }

  const handleBetweenness = async () => {
    setAnimationTime(750)
    try {
      const result = await betweenness(
        { ...linkAnalysisState.currentChart.items },
        { value: 'count' },
      )

      const newValues = () => {
        Object.entries(result)?.forEach((item) => {
          let key = item[0]
          let value = item[1]
          if (value * 1000 > 21) {
            result[key] = 7
          } else if (value * 1000 > 10 && value * 1000 < 20) {
            result[key] = 4
          } else if (value * 1000 < 1 || value === 0) {
            result[key] = 1
          } else {
            result[key] = value * 1000
          }
        })
        return result
      }

      newValues()

      setScores({ items: result, betweenness: true, reset: false })
      setLayout(linkAnalysisState.layout)
      setTimeout(() => {
        chartRef.current.fit('all')
      }, 150)
    } catch {}
  }

  const handleResetFilter = () => {
    setAnimationTime(750)
    setScores({ items: linkAnalysisState.currentChart.items, reset: true })
    setLayout(linkAnalysisState.layout)
    setTimeout(() => {
      chartRef.current.fit('all')
    }, 150)
  }

  const handleDoubleClick = ({ id }) => {
    if (id === undefined || id === null) return false
    if (id.startsWith('_combolink')) return false

    if (comboLookup.current[id]) {
      setAnimationTime(750)
      setCombos((current) => {
        const { combine, openCombos } = current
        return {
          ...current,
          combine: { ...combine },
          openCombos: { ...openCombos, [id]: !openCombos[id] },
        }
      })

      return false
    }

    if (
      Object.keys(selection).length !== 1 ||
      Object.values(selection)[0].type === 'link'
    )
      return false

    const fetchNode = async (currentSelection) => {
      setLoading(true)

      try {
        const node = Object.keys(currentSelection)?.toString()?.split('_')
        const response = await nodeContent(getAccessToken(), +node[1], node[0]) //node_id, node_type
        const numberOfResults = Object.keys(response).length

        if (numberOfResults === 0) {
          setApiMessage('No data found')
          setAnimationTime(0)
        } else {
          addMultipleNodes(response)
        }
        setLoading(false)
        setTimeoutMessage('Loading data. Please wait.')
      } catch (error) {
        // console.log(error)
        // if (error?.response?.status === 524) {
        //   setTimeoutMessage('Things are taking longer than usual. Please retry.')
        //   setIsTimedOut(true);
        // }
      }
    }

    fetchNode(selection)
  }

  const setTimeRange = (change) => {
    if (change?.items) {
      Object.values(linkAnalysisState?.currentChart?.items)?.forEach((item) => {
        if (item?.type === 'link' || item?.isInRange !== true) return false
        item.isInRange = false
      })

      Object.values(change?.items)?.forEach((item) => {
        item.isInRange = true
      })
      setLinkAnalysisState((current) => {
        return {
          ...current,
          currentChart: {
            items: { ...linkAnalysisState.currentChart.items, ...change.items },
            positions: { ...linkAnalysisState.currentChart.positions },
          },
          extras: {
            ...current.extras,
            analysisName: analysisName,
            comboLookup: comboLookup,
            combos: combos,
            currentLayout: currentLayout,
            selection: selection,
          },
        }
      })
    }
  }

  const saveGroupName = () => {
    let id = Object.keys(selection)[0]

    setCombos((current) => {
      const { combine, openCombos } = current
      return {
        ...current,
        combine: { ...combine },
        openCombos: { ...openCombos, [id]: openCombos[id] },
      }
    })
  }

  const handleTimedOut = () => {
    setLoading(false)
    setTimeoutMessage('Loading data. Please wait.')
    setIsTimedOut(false)
  }

  let openItems = combos?.openCombos && Object.values(combos.openCombos)
  let isComboClosed = openItems?.every((v) => v === false)

  // const searchResult = async () => {
  //   setIsLoadingList(true)

  //   const payload = {
  //     keywords: searchPhrase,
  //     page,
  //   }

  //   if (payload.page === 1) delete payload.page
  //   if (!searchPhrase.length) delete payload.keywords

  //   const query = new URLSearchParams(payload).toString()
  //   const response = await getListAnalysis(getAccessToken(), query)

  //   setData(response.list)
  //   response?.list?.data?.length === 0 &&
  //     warningToast({ message: 'No result found' })
  //   setIsLoadingList(false)
  // }

  const [deleteModal, setDeleteModal] = useState(undefined)
  const [isDeleting, setDeleting] = useState(false)

  // const fetchAnalysisList = async () => {
  //   setIsLoadingList(true)

  //   const payload = {
  //     page,
  //     // keywords: keyword,
  //     // status: status,
  //   }
  //   const query = new URLSearchParams(payload).toString()
  //   const response = await getListAnalysis(getAccessToken(), query)
  //   setData(response.list)
  //   setIsLoadingList(false)
  // }

  const handleDeleteDraft = (id) => {
    try {
      deleteSpecificAnalysis({ token: getAccessToken(), id })
    } catch {}
  }

  // useEffect(() => {
  //   const ignore = fetchAnalysisList()
  // }, [page])

  const handlePageChange = (numberPage) => {
    setPage(numberPage)
  }

  useEffect(() => {
    const currentWorkspace = workspaces.find(
      (workspace) => workspace.state === 'analysis',
    )

    currentWorkspace.analysisId = null
    currentWorkspace.edit = false
    setWorkspaces(workspaces)
    localStorage.setItem('workspaces', JSON.stringify(workspaces))
  }, [])

  const generateImage = () => {
    chartRef.current.export({ type: 'png' }).then((exportedImage) => {
      exportedImage.download('regraph_image')
    })
  }

  return {
    analysisList,
    analysisName,
    animationTime,
    apiMessage,
    centerCanvas,
    chartRef,
    changeGroupNameInput,
    combineSelection,
    combos,
    combineNodesHandler,
    contextMenuEvents,
    doRedo,
    doUndo,
    detailsShown,
    deleteModal,
    fetchList,
    generateImage,
    groupNamePositions,
    groupInputRef,
    handMode,
    handleAddNode,
    handleBetweenness,
    handleBlur,
    handleClick,
    handleChartChange,
    handleDegrees,
    handleDeleteDraft,
    handleDoubleClick,
    handleDragEnd,
    handleDragOver,
    handleDragStart,
    handleSnackbarClose,
    handleTimedOut,
    handlePageChange,
    handleResetFilter,
    isComboClosed,
    isDeleting,
    isFetching,
    isLinkAnalysisEqual,
    isModalOpen,
    isLoading,
    isLoadingList,
    isTimedOut,
    isView,
    menu,
    page,
    saveGroupName,
    searchOpen,
    searchPhrase,
    selection,
    setAnalysisName,
    setAnimationTime,
    setDeleteModal,
    setDetailsShown,
    setLayout,
    setLoading,
    setIsView,
    setIsModalOpen,
    setSearchOpen,
    setSearchPhrase,
    setTimebarOpen,
    setTimeRange,
    suppressBrowserContextMenu,
    styledItems,
    nodesToAutocomplete,
    timebarOpen,
    timeoutMessage,
    timebarRef,
    toggleHandMode,
    openContextMenu,
    uncombineSelection,
    zoom,
    mapViewItems,
    setNodesToAutocomplete,
  }
}

export default useRegraph
