/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiUrl } from '../../../config'
import client from '../../http/client'

const token = () => {
  return localStorage.getItem('access_token') || ''
}

export const getSingleThreadData = (
  token: string,
  forumId: number,
  categoryId: number,
  threadId: number,
  page: number,
) =>
  client.get(
    apiUrl +
      `/api/forum/${forumId}/categories/${categoryId}/threads/${threadId}/posts?page=${page}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const addNewThreadPost = (
  token: string,
  forumId: number,
  categoryId: number,
  threadId: number,
  data: any,
) =>
  client.post(
    apiUrl +
      `/api/forum/${forumId}/categories/${categoryId}/threads/${threadId}/posts`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const editThreadPost = (
  token: string,
  forumId: number,
  categoryId: number,
  threadId: number,
  postId: number,
  data: any,
) =>
  client.put(
    apiUrl +
      `/api/forum/${forumId}/categories/${categoryId}/threads/${threadId}/posts/${postId}`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const followThread = ({
  forumId,
  categoryId,
  threadId,
}: {
  forumId: number
  categoryId: number
  threadId: number
}) =>
  client.post(
    apiUrl +
      `/api/forum/${forumId}/categories/${categoryId}/threads/${threadId}/follow`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token(),
      },
    },
  )

export const unfollowThread = ({
  forumId,
  categoryId,
  threadId,
}: {
  forumId: number
  categoryId: number
  threadId: number
}) =>
  client.remove(
    apiUrl +
      `/api/forum/${forumId}/categories/${categoryId}/threads/${threadId}/follow`,
    {
      headers: {
        Authorization: 'Bearer ' + token(),
      },
    },
  )

export const reportPost = (
  token: string,
  data: {
    reason: string
    content_type: string
    content_id: number
    forum_id: number
  },
) =>
  client.post(apiUrl + `/api/report-content/forum`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const deletePost = ({
  token,
  data,
}: {
  token: string
  data: {
    forumId: number
    categoryId: number
    threadId: number
    postId: number
    is_main_post: boolean
  }
}) =>
  client.remove(
    apiUrl +
      `/api/forum/${data?.forumId}/categories/${data?.categoryId}/threads/${data?.threadId}/posts/${data?.postId}?is_main_post=${data?.is_main_post}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

export const pinThread = ({
  forumId,
  categoryId,
  threadId,
}: {
  forumId: number
  categoryId: number
  threadId: number
}) =>
  client.post(
    apiUrl +
      `/api/forum/${forumId}/categories/${categoryId}/threads/${threadId}/pin`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token(),
      },
    },
  )

export const unpinThread = ({
  forumId,
  categoryId,
  threadId,
}: {
  forumId: number
  categoryId: number
  threadId: number
}) =>
  client.remove(
    apiUrl +
      `/api/forum/${forumId}/categories/${categoryId}/threads/${threadId}/pin`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token(),
      },
    },
  )
