/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Avatar, Button, Divider, IconButton } from '@material-ui/core'
import parse from 'html-react-parser'
import { formatDate } from '../../../shared/helpers/formatDate'
import { Delete, Reply, Warning } from '@mui/icons-material'
import { apiUrl } from '../../../shared/config'
import { useUserContext } from '../../../context/userContext'
import { useState } from 'react'
import ReportModal from '../../Chat/components/SingleConversation/components/ReportModal'
import useNotification from '../../../hooks/useNotification'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ThreadWarningModal from '../../ForumConfig/forumTabs/ThreadWarningModal'
import he from 'he'
import PreviewItem from '../../Chat/components/SingleConversation/components/PreviewItem'
import { deepSearch } from '../../../shared/helpers/deepSearch'
import UserLeaving from '../../SinglePiece/PieceContent/PieceTabs/TabContent/Content/UserLeaving'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  deletePost,
  reportPost,
} from '../../../shared/services/api/forum/singleThread'
interface AuthorInfo {
  centralised_id: number
  deleted_at: string
  display_name: string
  forum_posts_count: number
  id: number
  is_chat_enabled: boolean
  is_moderator: boolean | number
  avatar?: string | null
  organisation_name: string
}
interface Props {
  data: {
    id: number
    content: string
    created_at: string
    updated_at: string
    quoted_post: any | null
    is_reported: boolean
    is_main_post: boolean
    deleted_by: null | number
    author: AuthorInfo
    eu_links?: any
    uk_links?: any
  }
  page: number
  setOpenReply: any
  setEditId: any
  cachedPiecesOfContent: any
  isBeingCached: boolean
  threadAuthorId: any
}
const SinglePost = ({
  page,
  data,
  setOpenReply,
  setEditId,
  cachedPiecesOfContent,
  isBeingCached,
  threadAuthorId,
}: Props) => {
  const {
    id,
    content,
    created_at,
    quoted_post,
    is_reported,
    is_main_post,
    deleted_by,
    author,
    eu_links,
    uk_links,
  } = data
  const { user } = useUserContext()
  const { forumId, categoryId, threadId }: any = useParams()
  const currentInstance = process.env.REACT_APP_CURRENT_INSTANCE
  const [threadWarningOpen, setThreadWarningOpen] = useState<boolean | number>(
    false,
  )
  const [reportDialog, setReportDialog] = useState<undefined | number>(
    undefined,
  )
  const { successToast, errorToast } = useNotification()
  const history = useHistory()
  const { pathname } = useLocation()
  const [showPreview, setShowPreview] = useState<any | null>(null)
  const [isUserLeaving, setIsUserLeaving] = useState<undefined | string>(
    undefined,
  )
  const token = () => {
    return localStorage.getItem('access_token') || ''
  }
  const queryClient = useQueryClient()

  const { mutate: handleDeletePost } = useMutation({
    mutationFn: deletePost,
    onSuccess: () => {
      successToast({
        message: 'Thread deleted successfully',
      })
      setThreadWarningOpen(false)

      is_main_post && history.push(pathname.split('/').slice(0, -1).join('/'))
      const data: any = queryClient.getQueryData([
        'thread',
        forumId,
        categoryId,
        threadId,
        page,
      ])
      data.data.posts.items.find((i: any) => i.id === id).deleted_by = 1
      queryClient.setQueryData(
        ['thread', forumId, categoryId, threadId, page],
        data,
      )
    },
    onError: (error) => {
      errorToast({ message: error })
    },
    onSettled: () => {
      queryClient.invalidateQueries(['categories', +forumId])
    },
  })

  const { mutate: reportMessageCallback } = useMutation({
    mutationFn: async (reportReason: string) => {
      const payload = {
        reason: reportReason,
        content_type: 'FORUM POST',
        content_id: id,
        forum_id: +forumId,
      }
      const response = (await reportPost(token(), payload)) as unknown as {
        error: boolean
        message: string
      }

      if (response.error) {
        errorToast({ message: response.message })
        return false
      }

      successToast({ message: response.message })
      const data: any = queryClient.getQueryData([
        'thread',
        forumId,
        categoryId,
        threadId,
        page,
      ])
      const reportedPost = data.data.posts.items.find(
        (post: any) => post.id === id,
      )
      reportedPost.is_reported = true
      reportedPost.content = 'This message is hidden'
      queryClient.setQueryData(
        ['thread', forumId, categoryId, threadId, page],
        data,
      )
      setReportDialog(undefined)
    },
    onSuccess: () => {
      successToast({
        message: 'Thread deleted successfully',
      })
      setThreadWarningOpen(false)

      is_main_post && history.push(pathname.split('/').slice(0, -1).join('/'))
      const data: any = queryClient.getQueryData([
        'thread',
        forumId,
        categoryId,
        threadId,
        page,
      ])
      data.data.posts.items.find((i: any) => i.id === id).deleted_by = 1
      queryClient.setQueryData(
        ['thread', forumId, categoryId, threadId, page],
        data,
      )
    },
    onError: (error) => {
      errorToast({ message: error })
    },
    onSettled: () => {
      queryClient.invalidateQueries(['categories', +forumId])
    },
  })

  const getInitails = (name: string) => {
    if (!name) return null

    const initials = []
    const fullDisplayName = name.split(' ')
    initials[0] = fullDisplayName[0].at(0)
    initials[1] = fullDisplayName[1].at(0)

    return initials.join('')
  }

  const isValidURL = (url: string) => {
    const regex =
      /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
    return regex?.test(url)
  }

  const convertPostWithLink = (content: any) => {
    let finalContent: any[] = []
    const currentContent = content
    currentContent
      ?.replaceAll(
        /(<(\w+)(?:[^>]*class="content-link")[^>]*>)([^<]*)<\/\2>/gim,
        (x: string, y: string) => (y ? x.replaceAll(/<[^>]*>/g, '') : ' '),
      )
      ?.split(' ')
      ?.forEach((msg: string) => {
        if (isValidURL(msg)) {
          const url = parse(msg?.replace(/<[^>]*>?/gm, '')) as string
          const query = new URL(he.unescape(url))
          const contentId = query?.searchParams?.get('id')
          let type = query?.searchParams?.get('type')?.toLowerCase() || ''
          const ukAppUrl = process.env.REACT_APP_UK_APP_URL
          const euAppUrl = process.env.REACT_APP_EU_APP_URL
          const isLinkOutsideInternal =
            query?.origin !== ukAppUrl &&
            query?.origin !== euAppUrl &&
            query.origin !== 'http://localhost:3000'

          const possibleTypes = [
            'alert',
            'profile',
            'threat-actor',
            'operation',
            'malware-tools',
            'incident',
            'scenario',
            'report',
          ]
          const profileTypes = [
            'threat-actor',
            'operation',
            'malware-tools',
            'incident',
          ]
          if (profileTypes.includes(type)) {
            type = 'profile'
          }
          if (!contentId || !type) {
            finalContent = [
              ...finalContent,
              `<span class='content-link external'>${parse(query.href)}</span>`,
            ]
          } else {
            if (isLinkOutsideInternal) {
              return false
            }
            const originInstance =
              query.origin === 'http://localhost:3000'
                ? 'eu'
                : query?.origin == ukAppUrl //if query origin is UK instance
                ? 'uk'
                : query?.origin == euAppUrl && 'eu' //if query origin is EU/AMS instance
            const altContent: any =
              currentInstance !== originInstance
                ? Object.values(cachedPiecesOfContent[type])?.find(
                    (item: any) => {
                      return (
                        +contentId ===
                        (originInstance === 'uk' ? item?.uk_id : item?.eu_id)
                      )
                    },
                  )
                : null
            let altId = null

            if (altContent) {
              if (currentInstance === 'uk') {
                altId = cachedPiecesOfContent[type][altContent.uk_id]?.title
              } else {
                altId = cachedPiecesOfContent[type][altContent.eu_id]?.title
              }
            }

            const cachedTitle =
              (cachedPiecesOfContent && contentId && type && altContent
                ? altId
                : cachedPiecesOfContent[type][contentId]?.title) ||
              'Content restricted'
            let title = ''
            if (cachedTitle) {
              title = cachedTitle
            } else {
              title =
                (isBeingCached && 'Loading content') ||
                !possibleTypes.includes(type)
                  ? 'link error'
                  : ''
            }
            finalContent = [
              ...finalContent,
              `<span class='content-link'>${title}</span>`,
            ]
          }
        } else {
          finalContent = [...finalContent, msg]
        }
      })

    const joinedContent = finalContent.join(' ')
    return parse(joinedContent)
  }

  return (
    <div className='forum-single-thread__single-post'>
      {isUserLeaving ? (
        <UserLeaving
          isUserLeaving={he.unescape(isUserLeaving)}
          setIsUserLeaving={setIsUserLeaving}
        />
      ) : null}

      {reportDialog ? (
        <ReportModal
          reportMessageCallback={reportMessageCallback}
          reportDialog={reportDialog}
          setReportDialog={setReportDialog}
        />
      ) : null}

      {threadWarningOpen !== false ? (
        <ThreadWarningModal
          confirmDeleteOpen={threadWarningOpen}
          setConfirmDeleteOpen={setThreadWarningOpen}
          handleDelete={() =>
            handleDeletePost({
              token: token(),
              data: {
                forumId: +forumId,
                categoryId: categoryId,
                threadId: threadId,
                postId: id,
                is_main_post: is_main_post,
              },
            })
          }
          text={
            is_main_post
              ? 'Confirm delete thread. This will remove the entire thread with all posts in.'
              : 'Confirm delete post'
          }
        />
      ) : null}

      <div
        style={{
          position: 'absolute',
          right: '-10px',
          top: '-10px',
          display: 'flex',
          gap: 4,
        }}
      >
        {!is_reported &&
        !deleted_by &&
        author?.centralised_id === user?.user_info?.central_user_id ? (
          <IconButton
            onClick={() => {
              setEditId(id)
              quoted_post !== null && setOpenReply(quoted_post?.id)
            }}
            style={{
              background: '#191f29',
              padding: 3,
              border: '1px solid #353e4d',
              borderRadius: 6,
            }}
          >
            <span
              className='icon-edit'
              style={{ color: '#fff', fontSize: 20 }}
            />
          </IconButton>
        ) : null}

        {!is_reported &&
        !deleted_by &&
        (author?.centralised_id === user?.user_info?.central_user_id ||
          user?.user_info?.is_comments_moderator) ? (
          <IconButton
            onClick={() => setThreadWarningOpen(id)}
            style={{
              background: '#191f29',
              padding: 3,
              border: '1px solid #353e4d',
              borderRadius: 6,
            }}
          >
            <Delete style={{ color: '#fff', fontSize: 20 }} />
          </IconButton>
        ) : null}
      </div>

      <div className='forum-single-thread__single-post-user'>
        <Avatar className='chat-avatar' style={{ height: 90, width: 90 }}>
          {author?.avatar ? (
            <img
              src={`${apiUrl}/storage/module/user/avatar/${author?.avatar}?access_token=${token()}`}
              alt={author?.avatar || ''}
              style={{
                width: 90,
                height: 90,
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />
          ) : author?.display_name ? (
            <span>{getInitails(author?.display_name)}</span>
          ) : (
            <span
              style={{
                paddingLeft: '12px',
              }}
            >
              User deleted
            </span>
          )}
        </Avatar>

        <span style={{ fontSize: 13 }}>{author?.display_name}</span>

        <span style={{ fontSize: 12, color: '#cacaca' }}>
          {author?.organisation_name}
        </span>

        <span style={{ fontSize: 12 }}>Posts: {author?.forum_posts_count}</span>

        {author?.centralised_id === threadAuthorId ? (
          <span
            style={{
              marginBottom: 10,
              background: '#6180a8',
              borderRadius: 6,
              padding: '3px 9px',
              fontSize: 11,
              width: 'max-content',
            }}
          >
            Thread Author
          </span>
        ) : null}
      </div>

      <div className='forum-single-thread__single-post-content'>
        <div>
          {is_main_post ? (
            <span
              style={{
                background: '#1a222e',
                borderRadius: 6,
                padding: '3px 9px',
                fontSize: 11,
              }}
            >
              Thread Starter
            </span>
          ) : null}

          <div
            style={{
              marginTop: is_main_post ? 10 : 0,
              color: is_reported || deleted_by ? '#ccc' : '#fff',
              fontStyle: is_reported || deleted_by ? 'italic' : 'unset',
              overflow: 'auto',
            }}
            onClick={(event) => {
              const target = event.target as Element
              if (
                target?.classList?.value === 'content-link external' &&
                isValidURL(target.innerHTML)
              ) {
                setIsUserLeaving(target.innerHTML)
              }
              if (target.classList.value !== 'content-link') return false
              const content =
                currentInstance &&
                deepSearch(
                  cachedPiecesOfContent,
                  'title',
                  (k: any, v: string) => v === target?.innerHTML,
                )

              if (!content) return false
              const contentId =
                currentInstance === 'uk' ? content?.uk_id : content?.eu_id
              if (showPreview && showPreview[1] === contentId) {
                setShowPreview(null)
                return false
              }
              const type =
                currentInstance &&
                Object.keys(cachedPiecesOfContent).find(
                  (key) => cachedPiecesOfContent[key][contentId],
                )
              setShowPreview([type, contentId, id])
            }}
          >
            {is_reported || deleted_by
              ? 'This content is hidden'
              : uk_links || eu_links
              ? convertPostWithLink(content)
              : parse(content)}
          </div>

          {showPreview !== null && id === showPreview[2] ? (
            <PreviewItem
              previewModule={showPreview[0]}
              previewId={showPreview[1]}
              cachedItems={cachedPiecesOfContent}
            />
          ) : null}

          {quoted_post ? (
            <div
              style={{
                marginTop: is_main_post || quoted_post ? 10 : 0,
                borderLeft: '4px solid #bec2c6',
                paddingLeft: 10,
                color: '#bec2c6',
              }}
            >
              {convertPostWithLink(quoted_post?.content)}
            </div>
          ) : null}
        </div>

        <div>
          <Divider
            style={{
              margin: '15px 0 5px 0',
              backgroundColor: '#1c222f',
              height: 2,
            }}
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span style={{ fontSize: 11 }}>
              Posted: {formatDate(created_at)}
            </span>

            {!is_reported && !deleted_by ? (
              <div style={{ display: 'flex', gap: 8 }}>
                <Button
                  startIcon={<Reply />}
                  onClick={() => setOpenReply(id)}
                  style={{
                    background: '#191f29',
                    textTransform: 'capitalize',
                    fontSize: 13,
                    color: '#fff',
                    padding: '3px 8px',
                  }}
                >
                  Reply
                </Button>
                {author?.centralised_id !== user?.user_info?.central_user_id ? (
                  <Button
                    onClick={() => setReportDialog(id)}
                    startIcon={<Warning />}
                    style={{
                      background: '#191f29',
                      textTransform: 'capitalize',
                      fontSize: 13,
                      color: '#fff',
                      padding: '3px 8px',
                    }}
                  >
                    Report
                  </Button>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SinglePost
