/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { IconButton, Tooltip } from '@material-ui/core'

import { useGlobalContext } from '../../../context/globalContext'
import { LiveHelp } from '@material-ui/icons'
import { useUserContext } from '../../../context/userContext'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  globalSearchAllContent,
  searchAllCollection,
  searchMyCollection,
} from '../../../shared/services/api/search/search'
import { useViewStateContext } from '../../../context/viewStateContext'
import { formatCreateListingDate } from '../../../shared/helpers/formatDate'

const userRoutes = [
  // {
  //   path: '/app/chat',
  //   navigate: 'chat',
  //   muiIcon: <Chat />,
  //   name: 'Chat',
  // },
  { path: '/app/search', navigate: 'search', icon: 'search', name: 'Search' },
  {
    path: '/app/content-management',
    isContentManageButton: true,
    name: 'Content Management',
  },
  {
    path: '/app/support',
    navigate: 'support',
    muiIcon: <LiveHelp />,
    name: 'Support',
  },
  // {
  //   path: '/app/knowledge-base',
  //   navigate: 'knowledge-base',
  //   muiIcon: <EmojiObjectsIcon />,
  //   name: 'Knowledge Base',
  // },
]

const ContentManageButton = ({ name, path }) => {
  const location = useLocation()
  const history = useHistory()

  const { isContentBeingEdited } = useGlobalContext()
  const {
    user: { is_management_mode_possible },
  } = useUserContext()

  const { globalContentFilters } = useGlobalContext()
  const { setGlobalContentResult } = useViewStateContext()

  const getGlobalContentResult = async () => {
    const token = () => localStorage.getItem('access_token')
    setGlobalContentResult({})
    const payload = {}

    if (globalContentFilters.status) {
      payload.status = globalContentFilters.status
        .map((item) => item.slug)
        .join()
    }

    if (globalContentFilters.date_range) {
      let dates = []
      globalContentFilters.date_range.forEach(
        (item) =>
          (dates = [...dates, formatCreateListingDate(item || Date.now())]),
      )
      payload.date_range = dates.join('-')
    }

    if (globalContentFilters.content_type) {
      payload.content_type = globalContentFilters.content_type
        .map((item) => item.slug)
        .join()
    }

    if (globalContentFilters.keyword) {
      payload.keyword = globalContentFilters.keyword
    }

    if (
      globalContentFilters.orderBy &&
      globalContentFilters.orderBy !== 'default'
    ) {
      payload.orderBy = globalContentFilters.orderBy
    } else {
      delete payload.orderBy
    }

    if (globalContentFilters.page) {
      payload.page = globalContentFilters.page
    } else {
      delete payload.page
    }

    const query = new URLSearchParams({ ...payload })
      .toString()
      .replace(/%2C/g, ',')
      .replace(/%3A/g, ':')
    let response

    if (globalContentFilters.currentTab === 'all') {
      response = await searchAllCollection(token(), query)
    } else {
      response = await searchMyCollection(token(), query)
    }
    setGlobalContentResult(response)

    return response
  }

  const queryClient = useQueryClient()
  const onContentMouseEnter = async () => {
    queryClient.prefetchQuery({
      queryKey: ['globalContentResult'],
      queryFn: getGlobalContentResult,
      staleTime: 10 * 60 * 1000,
    })
  }

  const handleNavigateTo = (route) => history.push(`/app/${route}`)

  if (is_management_mode_possible && !isMobile) {
    return (
      <Tooltip
        arrow
        style={{ fontSize: '20px' }}
        title={
          location.pathname.startsWith(path) || isContentBeingEdited ? '' : name
        }
        placement='top'
      >
        <IconButton
          className={
            location.pathname.startsWith('/app/content-management')
              ? 'create active'
              : 'create'
          }
          disableRipple
          style={{
            padding: isContentBeingEdited ? 20 : 23,
            backgroundColor: isContentBeingEdited && '#006fff',
            color:
              isContentBeingEdited &&
              location.pathname.startsWith('/app/content-create/') &&
              '#fff',
            width: isContentBeingEdited && '150px',
          }}
          onMouseEnter={onContentMouseEnter}
          onClick={() => {
            handleNavigateTo(
              isContentBeingEdited
                ? `content-create/${isContentBeingEdited[0]}/${isContentBeingEdited[1]}`
                : 'content-management',
            )
          }}
          size='medium'
        >
          <span className='icon-edit' style={{ fontSize: '28px' }} />

          {location.pathname.startsWith(path) && (
            <span
              className='tab active-tab'
              style={{ fontSize: '16px', marginLeft: '4px' }}
            >
              {name}
            </span>
          )}

          {isContentBeingEdited && (
            <span
              className='tab active-tab'
              style={{ fontSize: '16px', marginLeft: '4px' }}
            >
              Editing
            </span>
          )}
        </IconButton>
      </Tooltip>
    )
  }

  return null
}

ContentManageButton.displayName = 'ContentManageButton'
const UserLayout = () => {
  const location = useLocation()
  const history = useHistory()
  const {
    user: { is_admin, is_chat_enabled },
  } = useUserContext()
  const { setGlobalSearchResult } = useViewStateContext()

  const getSearchedResult = async () => {
    const token = () => localStorage.getItem('access_token')
    const payload = {
      filters: {},
      global: true,
      pagination: {
        page: 1,
        per_page: 40,
      },
      sorting: {
        field: 'relevance',
        direction: 'desc',
      },
    }

    const response = await globalSearchAllContent(token(), payload)

    setGlobalSearchResult(response)
    return response
  }

  const queryClient = useQueryClient()

  const globalSearchedResultQuery = useQuery({
    queryKey: ['globalSearchResult'],
    queryFn: getSearchedResult,
    enabled: false,
  })

  const onSearchMouseEnter = async () => {
    queryClient.prefetchQuery({
      queryKey: ['globalSearchResult'],
      queryFn: getSearchedResult,
      staleTime: 10 * 60 * 1000,
    })
  }

  const handleNavigateTo = (route) => history.push(`/app/${route}`)

  if (!is_admin) {
    return (
      <>
        {userRoutes.map(
          ({ path, navigate, muiIcon, icon, isContentManageButton, name }) => {
            const MuiIcon = muiIcon

            if (navigate === 'chat' && !is_chat_enabled) {
              return null
            }

            if (isContentManageButton)
              return <ContentManageButton key={path} name={name} path={path} />

            return (
              <Tooltip
                key={path}
                arrow
                title={location.pathname.startsWith(path) ? '' : name}
                placement='top'
                className='tooltip'
              >
                <IconButton
                  className={
                    location.pathname.startsWith(path)
                      ? 'create active'
                      : 'create'
                  }
                  disableRipple
                  onClick={() => handleNavigateTo(navigate)}
                  onMouseEnter={
                    name === 'Search' ? onSearchMouseEnter : undefined
                  }
                  size='medium'
                >
                  {MuiIcon || (
                    <span
                      className={`icon-${icon}`}
                      style={{ fontSize: '28px' }}
                    />
                  )}

                  {location.pathname.startsWith(path) && (
                    <span
                      className='tab active-tab'
                      style={{ fontSize: '16px', marginLeft: '4px' }}
                    >
                      {name}
                    </span>
                  )}
                </IconButton>
              </Tooltip>
            )
          },
        )}
      </>
    )
  }

  return null
}

UserLayout.displayName = 'UserLayout'

export default UserLayout
