/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-autofocus */
import { CircularProgress, MenuItem, TextField } from '@material-ui/core'
import { GridSearchIcon } from '@mui/x-data-grid'
import { useEffect, useRef, useState } from 'react'
import { Fragment } from 'react/jsx-runtime'

const SelectAuto = ({
  nodesToAutocomplete,
  fetchList,
  handleAddNode,
  isFetching,
  inside,
  setNodesToAutocomplete,
}) => {
  const [page, setPage] = useState(2)
  const ref = useRef(null)
  const [stopScroll, setStopScroll] = useState(false)
  const handleScroll = async (e) => {
    if (stopScroll) return false
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      setStopScroll(true)
      const shouldMergeResults = true
      await fetchList(ref?.current?.value, page + 1, shouldMergeResults)
      setPage(page + 1)
      setTimeout(() => {
        setStopScroll(false)
      }, 1000)
    }
  }

  return (
    <>
      <TextField
        id='search-link-analysis'
        onChange={async (e) => {
          try {
            setStopScroll(true)
            setPage(1)
            await fetchList(e.target.value, 1)
            setTimeout(() => {
              setStopScroll(false)
            }, 1000)
          } catch (err) {}
        }}
        label='Search'
        autoFocus
        fullWidth
        inputRef={ref}
        variant='outlined'
        inputProps={{ style: { padding: '16px 14px' } }}
        InputProps={{
          endAdornment: (
            <Fragment>
              {isFetching ? (
                <CircularProgress
                  style={{ position: 'absolute', right: '20px' }}
                  color='primary'
                  size={20}
                />
              ) : (
                <GridSearchIcon />
              )}
            </Fragment>
          ),
        }}
      />

      {document?.activeElement === ref?.current && (
        <div
          style={{ top: inside ? 55 : null, bottom: inside ? null : 80 }}
          className='search-autocomplete'
          onScroll={handleScroll}
        >
          {nodesToAutocomplete?.length === 0 && <MenuItem>No options</MenuItem>}
          {nodesToAutocomplete?.length > 0 &&
            nodesToAutocomplete?.map((option, index) => {
              return (
                <Fragment key={option.id}>
                  <MenuItem
                    onClick={() => {
                      handleAddNode(null, option)
                      setNodesToAutocomplete([])
                    }}
                    key={option.id}
                  >
                    {option.title}

                    <div className='autocomplete-item'>
                      {option.content_type}
                    </div>
                  </MenuItem>
                </Fragment>
              )
            })}
        </div>
      )}
    </>
  )
}

export default SelectAuto
