/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import './GlobalSearch.scss'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core'
import 'flatpickr/dist/themes/material_green.css'
import { Avatar, Divider } from '@mui/material'
import GlobalAutocomplete from './GlobalAutocomplete'
import OrderBy from '../Profiles/Filters/OrderBy'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import DateRangePickerComponent from '../../shared/DateRangePicker/DateRangePicker'
import ContentMultiselect from '../Profiles/Filters/ContentMultiselect'
import { useUserContext } from '../../context/userContext'

/**
 * GlobalFilters
 * @param {object} filters - current filters applied
 * @param {function} handleFilters - handling the state of filters
 * @param {string} currentTab - current module tab
 * @param {function} handleResetFilters - reseting filters
 * @param {object} filterOptions - filter autocomplete options
 * @param {string} orderBy - orderBy filter
 * @param {function} orderChange - handling orderBy state
 * @param {boolean} searchFilterOpen - are filters open
 * @param {function} setSearchFilterOpen - setting filters open state
 */

const modifiedNames = {
  alert: 'alert',
  scenario: 'scenario',
  'threat-actor': { subtype: 'threat-actor', type: 'profile' },
  incident: { subtype: 'incident', type: 'profile' },
  operation: { subtype: 'operation', type: 'profile' },
  'malware-tools': { subtype: 'malware-tools', type: 'profile' },
  report: 'report',
  ioc: 'ioc',
  forum: 'forum',
}
const statusCheckboxes = [
  { name: 'Read', slug: 'read' },
  { name: 'Unread', slug: 'unread' },
  { name: 'Followed', slug: 'flagged' },
]
const relevanceCheckboxes = ['organisation', 'sector', 'global']
const levels = [1, 2, 3, 4, 5]
const profileTags = [
  {
    title: 'Target Geography',
    slug: 'tags_target_geography',
    categorySlug: 'target-geography',
  },
  { title: 'Language', slug: 'tags_language', categorySlug: 'language' },
  { title: 'Motivation', slug: 'tags_motivation', categorySlug: 'motivation' },
  {
    title: 'Intended Effect',
    slug: 'tags_intended_effect',
    categorySlug: 'intended-effect',
  },
]

const GlobalFilters = ({
  filters,
  handleFilters,
  handleResetFilters,
  filterOptions,
  orderBy,
  orderChange,
  searchFilterOpen,
  setSearchFilterOpen,
}) => {
  const {
    user: { user_access_groups_per_content },
  } = useUserContext()
  const returnClass = (level) => {
    if (
      filters.currentTab === 'alert' ||
      filters.currentTab === 'threat-actor'
    ) {
      return filters?.priority?.includes(level)
        ? `level-${level}`
        : `level-${level} inactive`
    }
  }

  const moduleName =
    modifiedNames[filters?.currentTab]?.type ||
    modifiedNames[filters?.currentTab]

  const moduleGroupOptions = () => {
    if (!filters?.currentTab || filters?.currentTab === 'all') {
      let data = []
      Object.values(user_access_groups_per_content)?.forEach((i) => {
        i?.forEach((g) => {
          const groupExists = data?.find((c) => c.id === g.id)
          if (!groupExists) {
            data = [...data, g]
          }
        })
      })
      return data
    } else {
      const data =
        {
          ...filterOptions,
          groups: user_access_groups_per_content[moduleName],
        } || []
      return data
    }
  }

  return (
    <>
      <div
        style={{
          height: 84,
          display: isMobile ? 'flex' : null,
          justifyContent: isMobile ? 'space-between' : null,
        }}
      >
        <h3 style={{ padding: '30px 0' }}>Search all content</h3>

        {isMobile ? (
          <Button
            onClick={() => setSearchFilterOpen(!searchFilterOpen)}
            style={{ textTransform: 'capitalize', color: '#fff' }}
            endIcon={
              <span
                style={{ transform: 'rotate(270deg)' }}
                className='icon-expand-content'
              />
            }
          >
            Hide filters
          </Button>
        ) : null}
      </div>

      <Divider
        style={{
          margin: '0 -30px',
          backgroundColor: '#0000004D',
          border: 'none',
        }}
      />

      <div
        style={{
          margin: '12px 0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span
          onClick={handleResetFilters}
          style={{
            color:
              Object.keys(filters).length > 1 ||
              (Object.keys(filters).length === 1 && !filters.currentTab)
                ? '#fff'
                : '#8d9197',
            cursor: 'pointer',
          }}
        >
          Reset filters
        </span>

        <OrderBy orderBy={orderBy} orderChange={orderChange} />
      </div>

      <Divider
        style={{
          margin: '0 -30px',
          backgroundColor: '#0000004D',
          border: 'none',
        }}
      />

      {(filters.currentTab === 'alert' ||
        filters.currentTab === 'threat-actor') && (
        <div className='threat-level'>
          <h4>
            {filters.currentTab === 'alerts' ? 'Threat severity' : 'Capability'}
          </h4>

          <div className='priority'>
            {levels.map((level) => (
              <Avatar
                key={level}
                onClick={() => handleFilters('priority', level)}
                className={returnClass(level)}
              >
                {level}
              </Avatar>
            ))}
          </div>
        </div>
      )}

      {filters.currentTab !== 'forum' && (
        <div className='status'>
          <h4>Status</h4>

          <FormGroup row className='status-checkboxes'>
            {statusCheckboxes.map((item) => (
              <FormControlLabel
                key={item.slug}
                control={
                  <Checkbox
                    checked={
                      filters.status
                        ? filters.status.includes(item.slug)
                        : false
                    }
                    onChange={() => handleFilters('status', item.slug)}
                    color='default'
                    icon={<span className='icon-checkbox-empty' />}
                    checkedIcon={<span className='icon-checkbox-checked' />}
                  />
                }
                label={item.name}
              />
            ))}
          </FormGroup>
        </div>
      )}

      {filters.currentTab !== 'forum' && (
        <div className='relevance'>
          <h4>Relevance</h4>

          <FormGroup row className='relevance-checkboxes'>
            {relevanceCheckboxes.map((item) => (
              <FormControlLabel
                key={item}
                onChange={(event) => {
                  const { name } = event.target
                  handleFilters('relevance', name)
                }}
                control={
                  <Checkbox
                    checked={
                      filters.relevance
                        ? filters.relevance.includes(item)
                        : false
                    }
                    name={item}
                    color='default'
                    value={item}
                    icon={<span className='icon-checkbox-empty' />}
                    checkedIcon={<span className='icon-checkbox-checked' />}
                  />
                }
                label={item}
              />
            ))}
          </FormGroup>
        </div>
      )}

      {filters.currentTab === 'threat-actor' &&
        profileTags.map(({ slug, title, categorySlug }) => (
          <div key={slug} className='sector'>
            <ContentMultiselect
              title={title}
              handleFilters={handleFilters}
              slug={slug}
              category={categorySlug}
              data={filters[slug] || []}
            />
          </div>
        ))}

      {(filters.currentTab === 'malware-tools' ||
        filters.currentTab === 'operation') &&
        profileTags
          .filter((i) => i.categorySlug === 'target-geography')
          .map(({ slug, title, categorySlug }) => (
            <div key={slug} className='sector'>
              <ContentMultiselect
                title={title}
                handleFilters={handleFilters}
                slug={slug}
                category={categorySlug}
                data={filters[slug] || []}
              />
            </div>
          ))}

      {(filters.currentTab === 'alert' ||
        filters.currentTab === 'report' ||
        filters.currentTab === 'scenario') && (
        <div className='alert-types'>
          <h4>Types</h4>

          <GlobalAutocomplete
            filters={filters}
            handleFilters={handleFilters}
            filterOptions={filterOptions}
            type='types'
          />
        </div>
      )}

      <div>
        <DateRangePickerComponent
          selectedDateRange={filters?.dateRange || []}
          handleFilters={handleFilters}
        />
      </div>

      {filters.currentTab !== 'ioc' && filters.currentTab !== 'forum' && (
        <div className='sector'>
          <h4>Groups</h4>

          <GlobalAutocomplete
            filters={filters}
            handleFilters={handleFilters}
            filterOptions={moduleGroupOptions()}
            type='groups'
          />
        </div>
      )}

      {filters.currentTab !== 'all' &&
        filters.currentTab !== undefined &&
        filters.currentTab !== 'ioc' &&
        filters.currentTab !== 'forum' && (
          <div className='sector'>
            <h4>Sectors</h4>

            <GlobalAutocomplete
              filters={filters}
              handleFilters={handleFilters}
              filterOptions={filterOptions}
              type='sectors'
            />
          </div>
        )}

      {filters.currentTab === 'scenario' && (
        <div className='sector'>
          <h4>Frameworks</h4>

          <GlobalAutocomplete
            filters={filters}
            handleFilters={handleFilters}
            filterOptions={filterOptions}
            type='frameworks'
          />
        </div>
      )}
    </>
  )
}

GlobalFilters.displayName = 'GlobalFilters'

/**
 * The properties.
 * @type {Object}
 */

GlobalFilters.propTypes = {
  filters: PropTypes.instanceOf(Object).isRequired,
  handleFilters: PropTypes.func.isRequired,
  handleResetFilters: PropTypes.func.isRequired,
  filterOptions: PropTypes.instanceOf(Object).isRequired,
  // orderBy: PropTypes.string.isRequired,
  orderChange: PropTypes.func.isRequired,
  searchFilterOpen: PropTypes.bool.isRequired,
  setSearchFilterOpen: PropTypes.func.isRequired,
}

export default GlobalFilters
