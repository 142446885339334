/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IconButton, Tab, Tabs, TextField, Tooltip } from '@material-ui/core'
import {
  ArrowDownward,
  Fullscreen,
  FullscreenExit,
  Info,
} from '@material-ui/icons'
import React, { useState, useEffect } from 'react'
import DescriptionInfo from './DescriptionInfo'
import './MitreTags.scss'
import { handleMitreTags } from '../../helpers/modifyMitreTags'
import { getMitreData } from '../../../../../shared/services/api/mitre/mitre'
import Spinner from '../../../../../shared/Spinner'
import { Download } from '@mui/icons-material'
import { exportMitre } from '../../../../../shared/services/api/alerts/alerts'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

const MitreTags = ({
  tagTactics,
  devContent,
  devTags,
  tags,
  view,
  type,
  contentId,
}) => {
  const { handleCreateState, mitreConfig, setMitreConfig } =
    useCreateManagementContext()
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [tab, setTab] = useState(2)
  const [descriptionInfo, setDescriptionInfo] = useState(undefined)
  const [searchText, setSearchText] = useState('')
  const [fullscreenMode, setFullscreenMode] = useState(false)
  const [isLoading, setLoading] = useState(!Object.keys(mitreConfig)?.length)

  const loadMitreData = async () => {
    setLoading(true)
    try {
      const response = await getMitreData(token(), type, !!view)

      if (view) {
        const setInitialExpanded = () => {
          const currentMitre = { ...response }
          currentMitre.configuration.forEach((con) => {
            if (con?.techniques?.length > 0) {
              con.techniques.forEach((tec) => {
                if (tec?.sub_techniques?.length > 0) {
                  tec.sub_techniques.forEach((sub) => {
                    if (tagTactics[sub.tag_id]) {
                      tec.isExpanded = true
                    }
                  })
                }
              })
            }
          })
          return currentMitre
        }

        setMitreConfig(setInitialExpanded())
      } else {
        setMitreConfig(response)
      }
      setLoading(false)
    } catch (err) {}
  }

  useEffect(() => {
    !Object.keys(mitreConfig)?.length && loadMitreData()
  }, [])

  const handleExpanded = (categoryId, techniqueId) => {
    const currentState = { ...mitreConfig }
    const currentCategory = currentState?.configuration?.find(
      (cat) => cat.id === categoryId,
    )
    const currentTechnique = currentCategory?.techniques?.find(
      (tec) => tec.id === techniqueId,
    )

    currentTechnique.isExpanded = currentTechnique.isExpanded !== true

    setMitreConfig(currentState)
  }

  const handleAddTag = (technique, tactic, techniqueData, tacticName) => {
    let newMitreData = []
    const techniqueState = { ...techniqueData }
    techniqueState.name = `${techniqueState.name} (${tacticName})`

    if (type === 'report' || type === 'alert') {
      newMitreData = handleMitreTags(
        mitreConfig?.configuration,
        technique,
        tactic,
        techniqueState,
        tagTactics,
        undefined,
        tags,
        devContent,
      )
    } else {
      newMitreData = handleMitreTags(
        mitreConfig?.configuration,
        technique,
        tactic,
        techniqueState,
        tagTactics,
        devTags,
        tags,
        devContent,
      )
    }

    handleCreateState('dev_content_mitre', newMitreData, true)
  }

  const handleShowInfo = (event, description) => {
    event.stopPropagation()
    setDescriptionInfo(description)
  }

  const filteredResults = (data) => {
    return data.filter((item) => {
      let id = []
      item.sub_techniques.forEach((subitem) => {
        if (subitem?.name?.toLowerCase()?.includes(searchText)) {
          id = [subitem.parent_external_id]
        }
      })
      if (
        item?.external_id === id[0] ||
        item?.name?.toLowerCase()?.includes(searchText) ||
        item?.description?.toLowerCase()?.includes(searchText)
      ) {
        return item
      } else return false
    })
  }

  const handleExportMitre = async () => {
    try {
      const response = await exportMitre(token(), type, contentId)

      if (response.error) return false
      const url = `${response}?access_token=${token()}`
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    } catch {}
  }

  return isLoading ? (
    <Spinner centerHorizontally size={30} />
  ) : (
    <div
      style={{ width: view ? '100%' : 'unset' }}
      className={`mitre-tags${fullscreenMode ? ' fullscreen' : ''}`}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tabs
          value={tab}
          indicatorColor='primary'
          onChange={(event, newValue) => setTab(newValue)}
        >
          {Object.values(mitreConfig?.matrices || {})?.map((tab) => (
            <Tab key={tab.id} value={tab.id} label={tab.name} />
          ))}
        </Tabs>

        <div
          style={{
            width: '40%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginRight: fullscreenMode ? 6 : 0,
          }}
        >
          <TextField
            variant='outlined'
            // defaultValue={data.search ? data.search : ''}
            style={{ width: 'calc(100% - 50px)' }}
            inputProps={{ style: { padding: '13px 14px' } }}
            onChange={(event) =>
              setSearchText(event.target.value.toLowerCase())
            }
            placeholder='Search'
          />

          {view && (
            <Tooltip title='Export MITRE to xls'>
              <IconButton
                onClick={() => handleExportMitre()}
                style={{ padding: 10, margin: '0 2px' }}
              >
                <Download style={{ color: '#006fff' }} />
              </IconButton>
            </Tooltip>
          )}

          {!fullscreenMode && (
            <Tooltip title='Enter fullscreen mode'>
              <IconButton
                style={{ padding: 5 }}
                onClick={() => setFullscreenMode(true)}
                color='primary'
              >
                <Fullscreen style={{ fontSize: 34 }} />
              </IconButton>
            </Tooltip>
          )}

          {fullscreenMode && (
            <Tooltip title='Exit fullscreen mode'>
              <IconButton
                style={{ padding: 5 }}
                onClick={() => setFullscreenMode(false)}
                color='primary'
                size='medium'
              >
                <FullscreenExit style={{ fontSize: 34 }} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>

      {descriptionInfo !== undefined && (
        <DescriptionInfo
          description={descriptionInfo}
          setDescriptionInfo={setDescriptionInfo}
        />
      )}

      <div
        style={{
          overflow: 'auto',
          maxHeight: fullscreenMode ? '100vh' : '50vh',
          border: '1px solid #999',
        }}
      >
        <div style={{ display: 'flex' }}>
          {mitreConfig?.configuration?.map(
            (category) =>
              category.category_id === tab && (
                <div
                  key={category.id}
                  style={{
                    borderRight: '1px solid #999',
                    flex: '0 0 180px',
                    width: 180,
                    padding: '0 10px 10px 10px',
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      position: 'sticky',
                      top: 0,
                      backgroundColor: '#fff',
                      padding: '20px 8px 8px 8px',
                      zIndex: 9,
                    }}
                  >
                    <h5 style={{ marginBottom: 10 }}>{category.name}</h5>
                  </div>

                  <div>
                    {filteredResults(category?.techniques)?.map((tec) => (
                      <div
                        key={tec.id}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            width: 'calc(100% - 18px)',
                            fontSize: 14,
                            padding: 8,
                            border: '1px solid #ededed',
                            marginBottom:
                              tec.sub_techniques.length === 0 ? 8 : 0,
                            backgroundColor: tagTactics[tec.tag_id]?.includes(
                              tec.pivot.tactic_id,
                            )
                              ? '#4073ff'
                              : 'unset',
                            borderStyle: tagTactics[tec.tag_id]?.includes(
                              tec.pivot.tactic_id,
                            )
                              ? 'dotted'
                              : 'solid',
                            display: 'flex',
                            flexDirection: 'column',
                            cursor: 'pointer',
                            textAlign: 'left',
                          }}
                          onClick={() =>
                            !view &&
                            handleAddTag(
                              tec.tag_id,
                              tec.pivot.tactic_id,
                              tec,
                              category.name,
                            )
                          }
                        >
                          <span>{tec.name}</span>

                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              marginTop: 5,
                            }}
                            className='technique-footer'
                          >
                            <Info
                              onClick={(event) =>
                                handleShowInfo(event, [
                                  tec.name,
                                  tec.description,
                                ])
                              }
                              style={{ color: '#0000008a' }}
                            />
                          </div>
                        </div>

                        {tec.sub_techniques.length > 0 && (
                          <IconButton
                            className='technique-expand'
                            onClick={() => handleExpanded(category.id, tec.id)}
                            disableRipple
                            style={{
                              width: '100%',
                              borderRadius: 0,
                              backgroundColor: '#b1b1b1',
                              padding: 5,
                              marginBottom: 8,
                              marginTop: '-1px',
                              transform: tec.isExpanded ? 'rotate(180deg)' : '',
                            }}
                          >
                            <ArrowDownward
                              style={{ color: '#fff', fontSize: 14 }}
                            />
                          </IconButton>
                        )}

                        {tec.isExpanded && (
                          <div>
                            {tec.sub_techniques.map((subtec) => (
                              <div
                                key={subtec.id}
                                style={{
                                  cursor: 'pointer',
                                  padding: 8,
                                  border: '1px solid #ededed',
                                  marginRight: 30,
                                  marginBottom: 8,
                                  backgroundColor: tagTactics[
                                    subtec.tag_id
                                  ]?.includes(tec.pivot.tactic_id)
                                    ? '#4073ff'
                                    : 'unset',
                                  borderStyle: tagTactics[
                                    subtec.tag_id
                                  ]?.includes(tec.pivot.tactic_id)
                                    ? 'dotted'
                                    : 'solid',
                                  textAlign: 'left',
                                }}
                              >
                                <div
                                  style={{ fontSize: 14 }}
                                  onClick={() => {
                                    !view &&
                                      handleAddTag(
                                        subtec.tag_id,
                                        tec.pivot.tactic_id,
                                        {
                                          ...subtec,
                                          tag_categories: tec.tag_categories,
                                        },
                                        category.name,
                                      )
                                  }}
                                >
                                  <span>{subtec.name}</span>

                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      marginTop: 5,
                                    }}
                                    className='technique-footer'
                                  >
                                    <Info
                                      onClick={(event) =>
                                        handleShowInfo(event, [
                                          subtec.name,
                                          subtec.description,
                                        ])
                                      }
                                      style={{ color: '#0000008a' }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ),
          )}
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <span style={{ fontSize: 11 }}>
          © 2024 The MITRE Corporation. This work is reproduced and distributed
          with the permission of The MITRE Corporation.
        </span>
      </div>
    </div>
  )
}

export default MitreTags
