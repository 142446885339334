import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import './Profiles.scss'
import Pagination from '@material-ui/lab/Pagination'
import SinglePiece from '../SinglePiece'
import { profilesCollection } from './../../shared/services/api/profiles/profiles'
import {
  Divider,
  Button,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
} from '@material-ui/core'
import Filters from './Filters'
import MuiAlert from '@material-ui/lab/Alert'
import ModuleListing from '../ModuleListing'
import OrderBy from './Filters/OrderBy'
import Spinner from '../../shared/Spinner'
import { useGlobalContext } from '../../context/globalContext'
import { isMobile } from 'react-device-detect'
import BaseButton from '../../shared/components/Button'
import { useUserContext } from '../../context/userContext'
import { useViewStateContext } from '../../context/viewStateContext'
import { useChat } from '../../context/chatContext'
import debounce from 'lodash/debounce'

const profileTypes = [
  { name: 'Threat Actor', slug: 'threat-actor' },
  { name: 'Incident', slug: 'incident' },
  { name: 'Operation', slug: 'operation' },
  { name: 'Malware & Tool', slug: 'malware-tools' },
]

const Profiles = ({
  currentModule,
  configuration,
  type,
  typeName,
  modifyWorkspaceName,
}) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const {
    isContentBeingEdited,
    globalState: {
      [typeName]: { readItems },
    },
  } = useGlobalContext()
  const {
    user: {
      is_management_mode_possible,
      modules: { create_mode },
    },
  } = useUserContext()
  const { activePiece, setActivePiece, workspaces, setWorkspaces } =
    useViewStateContext()
  const history = useHistory()
  const keywordsInput = useRef(null)
  const activeItemRef = useRef(null)
  const wrapperRef = useRef(null)
  const [orderBy, setOrderBy] = useState('default')
  const [isLoading, setLoading] = useState(true)
  const [profilesData, setProfilesData] = useState(null)
  const [filtersOpen, setFiltersOpen] = useState(
    isMobile ? false : currentModule.filtersOpen || window.innerWidth > 1200,
  )
  const [anchorEl, setAnchorEl] = useState(null)
  const [toastMessage, setToastMessage] = useState(undefined)
  const [lastFilteredData, setLastFilteredData] = useState({})
  const { chatOpen } = useChat()
  const [pageCount, setPageCount] = useState(
    currentModule?.list?.last_page || 0,
  )
  const [total, setTotal] = useState(currentModule?.list?.total || 0)

  useEffect(() => {
    chatOpen && setFiltersOpen(false)
    const handleWindowResize = debounce(() => {
      !chatOpen && setFiltersOpen(window.innerWidth >= 1200)
    }, 25)

    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [chatOpen])

  const getFilteredProfiles = async (data, page) => {
    // cancelRequests !== undefined && cancelRequests();
    try {
      setLoading(true)

      const payload = {
        filters: {
          published_data_range: currentModule?.filters?.dateRange,
          content_type: 'profile',
          content_subtype: [currentModule?.state],
          priorities: currentModule?.filters?.priority,
          sectors: currentModule?.filters?.sectors?.map((i) => i?.id),
          is_read: currentModule?.filters?.status?.includes('unread')
            ? false
            : currentModule?.filters?.status?.includes('read'),
          is_follow: currentModule?.filters?.status?.includes('flagged'),
          keyword: currentModule?.filters?.keywords,
          relevance: currentModule?.filters?.relevance,
          group_id: currentModule?.filters?.groups?.map(({ id }) => id),
        },
        sorting: currentModule?.filters?.orderBy,
        pagination: {
          page: page || 1,
          per_page: 40,
        },
      }

      if (activePiece) {
        payload.visible_content = {}
        payload.visible_content.filters = {
          keyword: currentModule?.filters?.keywords,
          content_type: 'profile',
          content_id: activePiece,
        }
      }
      const tags = [
        'tags_target_geography',
        'tags_motivation',
        'tags_intended_effect',
        'tags_language',
      ]
      tags.forEach((tag) => {
        if (currentModule?.filters[tag]) {
          let tags = []
          currentModule?.filters[tag]?.forEach(({ id }) => {
            tags = [...tags, id]
          })
          payload.filters[tag] = tags
        }
      })
      if (
        (currentModule?.filters?.status?.includes('read') &&
          currentModule?.filters?.status?.includes('unread')) ||
        (!currentModule?.filters?.status?.includes('read') &&
          !currentModule?.filters?.status?.includes('unread'))
      ) {
        delete payload.filters.is_read
      }

      const profiles = await profilesCollection(token(), payload)

      if (profiles.error) {
        setLoading(false)
        setProfilesData([])
        setToastMessage(['error', profiles.message])
        return false
      }

      if (profiles?.data?.length === 0) {
        setToastMessage(['warning', 'No results found'])
      }

      setPageCount(profiles?.last_page)
      setTotal(profiles?.total)

      setWorkspaces((currentState) => {
        const currentWorkspace = currentState?.find(
          (workspace) => workspace.id === currentModule.id,
        )

        if (!currentWorkspace) return currentState
        currentWorkspace.list = profiles
        return currentState
      })

      if (profiles?.visible_content?.id) {
        profiles.visible_content.isExtra = true
      }
      const isContentOnList = profiles?.data?.find(
        (i) => i.id === profiles?.visible_content?.id,
      )
      await handleProfilesList(
        !isContentOnList && profiles?.visible_content?.id
          ? [...profiles?.data, profiles?.visible_content]
          : profiles?.data,
      )
      setLastFilteredData(payload)
    } catch (err) {}
  }

  const handleProfilesList = async (list) => {
    setProfilesData(list)
    setLoading(false)

    setTimeout(() => {
      if (activeItemRef.current) {
        activeItemRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        })
      } else if (wrapperRef.current) {
        wrapperRef.current.scrollIntoView({ behavior: 'auto', block: 'center' })
      }
    })

    if (currentModule.isNew) {
      const firstItemId = list[0]?.id && {
        id: list[0].id,
      }
      firstItemId && handleSetPiece(firstItemId, undefined, list)
    }
  }

  useEffect(() => {
    setActivePiece(currentModule?.currentPiece)
    currentModule?.list?.data === undefined
      ? getFilteredProfiles(currentModule?.filters)
      : handleProfilesList(currentModule?.list?.data)

    return () => {
      // cancelRequests()
    }
  }, [currentModule.id])

  const pageChange = (event, value) => {
    handleFilters('page', value === 1 ? [] : value)
    getFilteredProfiles(lastFilteredData, value)
  }

  const handleSetPiece = (piece, fromSinglePiece, listOnInitialLoad) => {
    if (fromSinglePiece) {
      if (profilesData.find((item) => item.id === piece)) {
        profilesData.find((item) => item.id === piece).read = true
        setProfilesData(profilesData)
      }
    } else {
      const list = listOnInitialLoad || profilesData

      list?.forEach((profile) => {
        if (piece.id === profile.id) {
          profile.read = true
        }
      })

      setProfilesData(list)
      setActivePiece(piece.id)
      setWorkspaces((currentState) => {
        const currentWorkspace = currentState.find(
          (workspace) => workspace.id === currentModule.id,
        )

        if (!currentWorkspace) return currentState
        currentWorkspace.currentPiece = piece.id
        currentWorkspace.scrollPosition = 0
        currentWorkspace.currentTab = 'content'
        listOnInitialLoad && delete currentWorkspace.isNew
        return currentState
      })
    }
  }

  const orderChange = (value) => {
    setOrderBy(value)
    handleFilters('orderBy', value)
    setTimeout(() => handleSubmit())
  }

  const handleFilters = (key, value) => {
    const singlePickValues = [
      'keywords',
      'types',
      'page',
      'dateRange',
      'sectors',
      'orderBy',
      'groups',
    ]
    const multipickSections = [
      'tags_target_geography',
      'tags_language',
      'tags_motivation',
      'tags_intended_effect',
    ]
    const filterKey = currentModule?.filters[key]
    const currentState = [...workspaces]
    const currentWorkspace = currentState.find(
      (workspace) => workspace.id === currentModule.id,
    )
    const isSingleValueFilter = singlePickValues.includes(key)
    if (multipickSections.includes(key)) {
      currentWorkspace.filters[key] = value
      setWorkspaces(currentState)
      return false
    }

    if (!filterKey) {
      currentWorkspace.filters[key] = isSingleValueFilter ? value : [value]
      setWorkspaces(currentState)
      return false
    }

    currentWorkspace.filters[key] = isSingleValueFilter
      ? value
      : filterKey?.includes(value)
      ? filterKey?.filter((val) => val !== value)
      : [...filterKey, value]
    currentWorkspace.filters[key]?.length === 0 &&
      delete currentWorkspace.filters[key]
    setWorkspaces(currentState)
  }

  const handleSubmit = () => {
    const data = currentModule.filters

    handleFilters('page', 1)
    getFilteredProfiles(data)
  }

  const changeStatus = (id, type) => {
    const currentState = [...profilesData]
    const filtered = currentState?.find((item) => item?.id === id)

    if (type === 'flagged') {
      filtered.flagged = true
    }
    if (type === 'unflagged') {
      filtered.flagged = false
    }
    if (type === 'unread') {
      if (filtered) {
        filtered.read = false
      }

      setActivePiece(null)
      setWorkspaces((currentState) => {
        const currentWorkspace = currentState.find(
          (workspace) => workspace.id === currentModule.id,
        )

        if (!currentWorkspace) return currentState
        currentWorkspace.currentPiece = null
        currentWorkspace.scrollPosition = 0
        currentWorkspace.currentTab = 'content'
        return currentState
      })
    }
    setProfilesData(currentState)
  }

  const resetPiece = () => {
    setActivePiece(null)
    setWorkspaces((currentState) => {
      const currentWorkspace = currentState.find(
        (workspace) => workspace.id === currentModule.id,
      )

      if (!currentWorkspace) return currentState
      currentWorkspace.currentPiece = null
      return currentState
    })
  }

  return (
    <div className='profiles'>
      <div className={filtersOpen ? 'filters expanded' : 'filters'}>
        <div className='heading'>
          {filtersOpen && <h2>Filter & Search</h2>}

          {filtersOpen ? (
            <Tooltip title='Close filters'>
              <IconButton
                disableRipple
                onClick={() => {
                  setFiltersOpen(!filtersOpen)
                  setWorkspaces((currentState) => {
                    const currentWorkspace = currentState.find(
                      (workspace) => workspace.id === currentModule.id,
                    )

                    currentWorkspace.filtersOpen = !filtersOpen
                    return currentState
                  })
                }}
              >
                <span
                  style={{ transform: 'rotate(180deg)' }}
                  className='icon-expand-content'
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Button
              style={{
                transform: 'rotate(270deg)',
                textTransform: 'capitalize',
                background: 'transparent',
                border: 'none',
                minWidth: 150,
              }}
              variant='outlined'
              disableRipple
              onClick={() => {
                setFiltersOpen(!filtersOpen)
                setWorkspaces((currentState) => {
                  const currentWorkspace = currentState.find(
                    (workspace) => workspace.id === currentModule.id,
                  )

                  currentWorkspace.filtersOpen = !filtersOpen
                  return currentState
                })
              }}
              endIcon={
                <span
                  style={{ transform: 'rotate(90deg)' }}
                  className='icon-expand-content'
                />
              }
            >
              Filters & Search
            </Button>
          )}
        </div>

        {toastMessage && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={3000}
            onClose={() => setToastMessage(undefined)}
            open={toastMessage !== undefined}
          >
            <MuiAlert elevation={6} variant='filled' severity={toastMessage[0]}>
              {toastMessage[1]}
            </MuiAlert>
          </Snackbar>
        )}

        {filtersOpen && <Divider style={{ margin: '20px 0' }} />}

        {filtersOpen && (
          <Filters
            keywordsInput={keywordsInput}
            handleFilters={handleFilters}
            handleSubmit={handleSubmit}
            typeId={type}
            setOrderBy={setOrderBy}
            setFiltersOpen={setFiltersOpen}
          />
        )}
      </div>

      {(!isMobile || (!activePiece && isMobile)) && (
        <div
          style={{ width: isMobile ? '100%' : undefined }}
          className='list-container'
        >
          {profilesData && (
            <div ref={wrapperRef} className='list-header'>
              <div className='list-header__top'>
                <span style={{ fontWeight: 100, fontSize: 16 }}>
                  Total results: <b>{total}</b>
                </span>

                {!isMobile &&
                  is_management_mode_possible &&
                  create_mode.find(
                    (module) => module.slug === 'threat-actor',
                  ) && ( //threat-actor, as profiles come as a package, so presence of one means presence of all
                    <BaseButton
                      onClick={(event) =>
                        isContentBeingEdited
                          ? setToastMessage([
                              'warning',
                              'A content is currently being edited. Exit Create Content Mode.',
                            ])
                          : setAnchorEl(event.currentTarget)
                      }
                    >
                      <span
                        className='icon-edit'
                        style={{ fontSize: 16, marginRight: '6px' }}
                      />

                      <span>Create new profile</span>
                    </BaseButton>
                  )}

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  {profileTypes?.map((profile) => (
                    <MenuItem
                      key={profile.slug}
                      onClick={() =>
                        history.push(`/app/content-create/${profile.slug}/new`)
                      }
                    >
                      {profile.name}
                    </MenuItem>
                  ))}
                </Menu>
              </div>

              <div className='list-header__nav'>
                <Pagination
                  style={{ marginTop: 1 }}
                  onChange={pageChange}
                  page={currentModule?.filters?.page || 1}
                  count={pageCount}
                  color='primary'
                  disabled={isLoading}
                  shape='rounded'
                  size='small'
                  hideNextButton
                  hidePrevButton
                />

                <OrderBy orderBy={orderBy} orderChange={orderChange} />
              </div>
            </div>
          )}

          {isLoading && <Spinner center size={30} border={1} />}

          {!isLoading && profilesData?.length === 0 && (
            <div>No results Found</div>
          )}

          {!isLoading && profilesData !== null && (
            <>
              <ModuleListing
                items={profilesData || []}
                activeItemRef={activeItemRef}
                handleSetPiece={handleSetPiece}
                type='profile'
                noType
                filters={currentModule.filters}
              />

              <Pagination
                onChange={pageChange}
                page={currentModule?.filters?.page || 1}
                count={pageCount}
                color='primary'
                disabled={isLoading}
                shape='rounded'
                size='small'
              />
            </>
          )}
        </div>
      )}

      {(!isMobile || (activePiece && isMobile)) && (
        <SinglePiece
          items={readItems}
          currentModule={currentModule}
          filtersOpen={filtersOpen}
          listLoading={isLoading}
          setToastMessage={setToastMessage}
          configuration={
            configuration?.types?.filter((item) => item.id === type)[0]?.schema
          }
          type='profile'
          modifyWorkspaceName={modifyWorkspaceName}
          changeStatus={changeStatus}
          handleSetPiece={handleSetPiece}
          resetPiece={resetPiece}
          highlightPhrase={
            profilesData?.find((i) => activePiece === i?.id)?.highlight
              ?.cleared_content
          }
        />
      )}
    </div>
  )
}

export default Profiles
