import { FormControl, MenuItem, Select } from '@material-ui/core'
import React from 'react'

const OrderBy = ({ orderBy, orderChange, noRelevance, handleFilters }) => {
  const orderByValue =
    orderBy === 'default' && noRelevance
      ? {
          field: 'updated_at',
          direction: 'desc',
        }
      : orderBy === 'default' && !noRelevance
      ? {
          field: 'relevance',
          direction: 'desc',
        }
      : orderBy
  return (
    <FormControl>
      <Select
        className='order-by-select'
        id='order-by'
        value={JSON.stringify(orderByValue)}
        onClose={() => {
          setTimeout(() => {
            document.activeElement.blur()
          }, 0)
        }}
        onChange={(event) =>
          handleFilters
            ? handleFilters('orderBy', event.target.value)
            : orderChange(JSON.parse(event.target.value))
        }
        variant='outlined'
      >
        <MenuItem
          style={{ fontSize: 13 }}
          value={JSON.stringify({
            field: noRelevance ? 'updated_at' : 'published_at',
            direction: 'desc',
          })}
        >
          Newest first
        </MenuItem>

        <MenuItem
          style={{ fontSize: 13 }}
          value={JSON.stringify({
            field: noRelevance ? 'updated_at' : 'published_at',
            direction: 'asc',
          })}
        >
          Oldest first
        </MenuItem>

        {!noRelevance && (
          <MenuItem
            style={{ fontSize: 13 }}
            value={JSON.stringify({
              field: 'relevance',
              direction: 'desc',
            })}
          >
            Relevance
          </MenuItem>
        )}

        <MenuItem
          style={{ fontSize: 13 }}
          value={JSON.stringify({
            field: 'title',
            direction: 'asc',
          })}
        >
          A-Z
        </MenuItem>

        <MenuItem
          style={{ fontSize: 13 }}
          value={JSON.stringify({
            field: 'title',
            direction: 'desc',
          })}
        >
          Z-A
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default OrderBy
