import client from './../../http/client'

import { apiUrl } from '../../../config/index'

export const reportsCollection = (token, data) =>
  client.post(apiUrl + `/api/search`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const singleReport = (token, id) =>
  client.get(apiUrl + `/api/reports/${id}/view`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const filterSettings = (token) =>
  client.get(apiUrl + `/api/reports/refine-sidebar-options`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const uploadReportPdf = (token, data) =>
  client.post(apiUrl + `/api/files/report-pdf`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const deleteReportPdf = (token, id) =>
  client.remove(apiUrl + `/api/files/report-pdf/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const previewReportPdf = (token, id) =>
  client.post(
    apiUrl + `/api/pdf/report/${id}/preview`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
