/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable'
import { MenuItem, Select } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { SortableImage } from './SortableImage'
import UploadImage from '../UploadImage'
import './Gallery.scss'
import { overviewImage } from '../../../../../shared/services/api/uploadFile/uploadFile'
import { useCreateManagementContext } from '../../../../../context/createManagementState'

const Gallery = ({
  data,
  setLoading,
  subSectionIndex,
  reportsGallery,
  handleGallery,
  setToastMessage,
  internal_key,
}) => {
  const { createContentState, handleCreateState } = useCreateManagementContext()

  const token = () => {
    return localStorage.getItem('access_token')
  }
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))
  const [activeId, setActiveId] = useState(undefined)
  const [state, setState] = useState()

  const uploadImage = async (imageData) => {
    setLoading(true)
    const formData = new FormData()

    formData.append('file', imageData)
    formData.append('module', 'content')
    formData.append('directory', 'pages')

    try {
      const response = await overviewImage(token(), formData)

      if (response.error) {
        setLoading(false)
        setToastMessage(['error', response.message])

        return false
      } else if (response?.filename) {
        if (reportsGallery) {
          const currentState = { ...data }
          if (currentState.gallery) {
            currentState.gallery = [
              ...currentState.gallery,
              { url: response.filename },
            ]
          } else {
            currentState.gallery = [{ url: response.filename }]
          }

          handleGallery('gallery', currentState.gallery, subSectionIndex)
        } else {
          if (state.content.gallery) {
            state.content.gallery = [
              ...state.content.gallery,
              { url: response.filename },
            ]
          } else {
            state.content.gallery = [{ url: response.filename }]
          }
          handleCreateState(internal_key, state)
        }

        setLoading(false)
      }
    } catch (err) {
      setToastMessage([
        'error',
        'Error occured during image upload. Please check images and try again.',
      ])
      setLoading(false)
    }
  }

  useEffect(() => {
    setState(data)
  }, [])

  const handleSetOverviewState = (prop, newValue, forceState) => {
    const currentState = { ...data }
    if (reportsGallery) {
      handleGallery(prop, newValue, subSectionIndex)
    } else {
      currentState.content[prop] = newValue
      data.content = currentState.content
      forceState && setState(currentState)
    }
  }

  const returnDataPerModule = () => {
    if (reportsGallery) {
      return data.gallery
    } else {
      return data.content.gallery
    }
  }

  const returnImagesPerRowPerModule = () => {
    if (reportsGallery) {
      return data['gallery-imagesPerRow'] || ''
    } else {
      return data.content['gallery-imagesPerRow']
    }
  }

  const handleCaption = (imageIndex, value) => {
    const currentState = returnDataPerModule()

    currentState[imageIndex].caption = value
    setState(currentState)
  }

  const handleDeleteImage = (deleteIndex) =>
    handleSetOverviewState(
      'gallery',
      returnDataPerModule().filter((image, index) => index !== deleteIndex),
      true,
    )
  const handleDragEnd = (event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = returnDataPerModule().findIndex(
        (image) => image.url === active.id,
      )
      const newIndex = returnDataPerModule().findIndex(
        (image) => image.url === over.id,
      )
      handleSetOverviewState(
        'gallery',
        arrayMove(returnDataPerModule(), oldIndex, newIndex),
        true,
      )
    }

    setActiveId(null)
  }

  const returnContextItems = () => {
    return [...returnDataPerModule()].map((item) => item.url)
  }

  return (
    <div className='gallery'>
      {data && returnDataPerModule() && returnDataPerModule().length > 0 && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={(event) => setActiveId(event.active.id)}
          onDragCancel={() => setActiveId(null)}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={returnContextItems()}
            strategy={rectSortingStrategy}
          >
            <div className='gallery-images'>
              {state &&
                returnDataPerModule().map((item, index) => (
                  <div
                    key={item.url}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <SortableImage
                      url={item.url}
                      index={index}
                      caption={item.caption}
                      handleDeleteImage={handleDeleteImage}
                      handleCaption={handleCaption}
                    />
                    <p
                      style={{
                        fontSize: '12px',
                        paddingRight: '12px',
                        paddingBottom: '8px',
                      }}
                    >
                      <span style={{ fontWeight: 'bold' }}>OCR:</span>{' '}
                      {createContentState.ocr_results[item.url]}
                    </p>
                  </div>
                ))}
            </div>
          </SortableContext>
        </DndContext>
      )}

      <div className='gallery-handlers'>
        <div className='gallery-left'>
          <div>
            <h5 style={{ marginBottom: 10 }}>Images</h5>

            <span style={{ fontSize: 13 }}>
              Select how many images show in a row
            </span>
          </div>

          <div>
            <h5 style={{ fontWeight: 400 }}>Select row</h5>

            <Select
              value={returnImagesPerRowPerModule() || ''}
              variant='outlined'
              style={{ width: 200, height: 40 }}
              displayEmpty
              renderValue={
                returnImagesPerRowPerModule() === '' ||
                returnImagesPerRowPerModule() === undefined
                  ? () => <div style={{ color: '#aaa' }}>Images per row</div>
                  : undefined
              }
              onChange={(event) => {
                event.preventDefault()
                event.stopPropagation()
                handleSetOverviewState(
                  'gallery-imagesPerRow',
                  event.target.value,
                  true,
                )
              }}
            >
              <MenuItem value={1}>1 item per row</MenuItem>

              <MenuItem value={2}>2 items per row</MenuItem>

              <MenuItem value={3}>3 items per row</MenuItem>

              <MenuItem value={4}>4 items per row</MenuItem>
            </Select>
          </div>
        </div>

        <div className='gallery-right'>
          <span style={{ marginRight: 20, fontSize: 13 }}>
            Add images to the section
          </span>

          <UploadImage
            subSectionIndex={subSectionIndex}
            reportsGallery={reportsGallery}
            uploadImage={uploadImage}
          />
        </div>
      </div>
    </div>
  )
}

export default Gallery
