/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useGlobalContext } from '../../../context/globalContext'
import { useUserContext } from '../../../context/userContext'
import { formatGlobalSearch } from '../../../shared/helpers/formatDate'

/* eslint-disable @typescript-eslint/no-unused-vars */
const HistorySearchModalItem = ({ data, type, isExpanded }: any) => {
  const { filterOptions } = useGlobalContext()
  const { user } = useUserContext()
  const filters: Record<string, any> = {
    keyword: { name: 'Keyword', type: 'string' },
    content_type: { name: 'Type', type: 'string' },
    content_subtype: { name: 'Subtype', type: 'array' },
    priorities: { name: 'Priority', type: 'array' },
    sectors: { name: 'Sectors', type: 'array' },
    is_read: { name: 'Read', type: 'boolean' },
    is_follow: { name: 'Followed', type: 'boolean' },
    relevance: { name: 'Relevance', type: 'array' },
    published_data_range: { type: 'string', name: 'Date Range' },
    scenario_framework_id: { name: 'Frameworks', type: 'array' },
    tags_target_geography: { name: 'Target Geography', type: 'array' },
    tags_motivation: { name: 'Motivation', type: 'array' },
    tags_intended_effect: { name: 'Intended Effect', type: 'array' },
    tags_language: { name: 'Language', type: 'array' },
    group_id: { name: 'Groups', type: 'array' },
    has_ioc: { name: 'IOC', type: 'boolean' },
  }

  const filterKeys = [
    'sectors',
    'intended-effect',
    'target-geography',
    'motivation',
    'language',
    'frameworks',
  ]

  const profileTagsSlugs: any = {
    'intended-effect': 'tags_intended_effect',
    'target-geography': 'tags_target_geography',
    motivation: 'tags_motivation',
    language: 'tags_language',
  }

  const arrayTypeSlug = type

  const arraySlug: any = {
    'threat-actor': 'Threat Actor',
    operation: 'Operation',
    incident: 'Incident',
    'malware-tools': 'Malware Tools',
    types:
      filterOptions &&
      filterOptions[arrayTypeSlug] &&
      filterOptions[arrayTypeSlug]['types'],
    priorities: [
      { name: 'Very High', id: 5 },
      { name: 'High', id: 4 },
      { name: 'Medium', id: 3 },
      { name: 'Low', id: 2 },
      { name: 'Very Low', id: 1 },
    ],
    group_id: user?.user_access_groups_per_content[type as any],
  }

  filterKeys?.forEach((i: any) => {
    const values = Object.values(filterOptions as any)
    values?.forEach((v: any) => {
      const key = i
      const value = v[key]
      if (value) {
        const profileKey = profileTagsSlugs[key as any] || key
        arraySlug[profileKey] = value
      }
    })
  })

  const modifiedNames: any = {
    alert: 'Alert',
    scenario: 'Scenario',
    report: 'Report',
  }

  const name = data[0]
  let value = data[1]
  let filterName = filters[name]?.name
  const filterType = filters[name]?.type

  if (filterName === 'Type' && value === 'profile') {
    return null
  }

  if (filterType === 'boolean' && value !== true) {
    // return null
  }

  if (filterName === 'Type') {
    value = modifiedNames[value]
  }

  if (filterName === 'Subtype' && arraySlug[value[0]]) {
    filterName = 'Type'
  }

  if (filterName === 'Date Range') {
    value = Object.values(value)?.map((i: any) => formatGlobalSearch(i))
    value = value?.join(' - ')
  }

  const isTypeSlug = type !== 'profile' && name === 'content_subtype'

  return filterName && filterType ? (
    <span style={{ padding: '0 0 0 4px', display: isExpanded ? 'block' : '' }}>
      {filterType !== 'boolean' && filterName !== 'Keyword' ? (
        <span style={{ fontWeight: 600 }}>{filterName}: </span>
      ) : null}
      {filterType === 'string' ? (
        <span
          style={{
            textDecoration: filterName === 'Keyword' ? 'underline' : '',
          }}
        >
          {value}
        </span>
      ) : null}
      {filterType === 'boolean' ? (
        <span>
          {value === false ? 'Not' : ''} {filterName}
        </span>
      ) : null}
      {filterType === 'array'
        ? value?.map(
            (i: any, index: any) =>
              i && (
                <span key={i}>
                  {isTypeSlug
                    ? arraySlug?.types?.find(
                        (e: any) =>
                          i === (type === 'scenario' ? e?.id : e?.slug),
                      )?.name //subtype, but not profile, id for scenario types, slug for alert and
                    : arraySlug[
                        name === 'scenario_framework_id' ? 'frameworks' : name
                      ]?.find((e: any) => e?.id === i)?.name || //other filter array types
                      arraySlug[i] || //profile subtype
                      i}
                  {index + 1 !== value?.length ? ', ' : ''}
                </span>
              ),
          )
        : null}
      ,
    </span>
  ) : null
}

export default HistorySearchModalItem
