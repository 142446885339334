/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, DialogContent, Divider } from '@material-ui/core'
import { Dialog } from '@mui/material'
import { Dispatch, useEffect, useState } from 'react'
import { lastSearchedQueries } from '../../../shared/services/api/search/search'
import HistorySearchItemWrapper from './HistorySearchItemWrapper'
import { reverse } from 'lodash'

interface Filters {
  published_data_range: any
  content_type: any
  content_subtype: any
  priorities: any
  sectors: any
  is_read: any
  is_follow: any
  relevance: any
  scenario_framework_id: any
  tags_target_geography: any
  tags_motivation: any
  tags_intended_effect: any
  tags_language: any
}

interface Props {
  setHistoryOpen: Dispatch<React.SetStateAction<boolean>>
  setGlobalSearchFilters: any
  currentTab: any
  getSearchedResult: any
}
const HistorySearchModal = ({
  setHistoryOpen,
  setGlobalSearchFilters,
  currentTab,
  getSearchedResult,
}: Props) => {
  const token = () => {
    return localStorage.getItem('access_token')
  }
  const [data, setData] = useState<any>([])
  const getLastSearchedQueries = async () => {
    try {
      const response = await lastSearchedQueries(token())
      response.data = response?.data?.map((i: any) =>
        i?.keyword ? { keyword: i.keyword, ...i } : i,
      )
      setData(response?.data)
    } catch {}
  }

  useEffect(() => {
    getLastSearchedQueries()
  }, [])

  return (
    <Dialog maxWidth='md' onClose={() => setHistoryOpen(false)} open>
      <div style={{ width: 800 }}>
        <DialogContent style={{ padding: '20px 10px 20px 20px' }}>
          <h1>Search history</h1>
          <div
            style={{
              margin: '30px 0 10px 20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div>
              {reverse(data)?.map((item: any, index: any) => (
                <HistorySearchItemWrapper
                  key={index}
                  item={item}
                  index={index}
                  setGlobalSearchFilters={setGlobalSearchFilters}
                  setHistoryOpen={setHistoryOpen}
                  currentTab={currentTab}
                  getSearchedResult={getSearchedResult}
                />
              ))}
            </div>

            <Button onClick={() => setHistoryOpen(false)}>Close</Button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default HistorySearchModal
